import { combineReducers } from 'redux';
import {
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_STATUS_SET,
    CLEAR_USER_NFT_S_SET,
    ELIGIBLE_CHECK_ERROR,
    ELIGIBLE_CHECK_IN_PROGRESS,
    ELIGIBLE_CHECK_SUCCESS,
    FETCH_USER_NFT_S_ERROR,
    FETCH_USER_NFT_S_IN_PROGRESS,
    FETCH_USER_NFT_S_SUCCESS,
    IV_PROJECTS_LIST_FETCH_ERROR,
    IV_PROJECTS_LIST_FETCH_IN_PROGRESS,
    IV_PROJECTS_LIST_FETCH_SUCCESS,
    MINT_NFT_ERROR,
    MINT_NFT_IN_PROGRESS,
    MINT_NFT_SUCCESS,
    MINT_QUEUE_FETCH_ERROR,
    MINT_QUEUE_FETCH_IN_PROGRESS,
    MINT_QUEUE_FETCH_SUCCESS,
    MINT_REQUEST_FETCH_ERROR,
    MINT_REQUEST_FETCH_IN_PROGRESS,
    MINT_REQUEST_FETCH_SUCCESS,
    MY_ACCOUNT_TAB_SET,
    NFT_CLAIM_STATUS_CHECK_ERROR,
    NFT_CLAIM_STATUS_CHECK_IN_PROGRESS,
    NFT_CLAIM_STATUS_CHECK_SUCCESS,
    NFT_FETCH_ERROR,
    NFT_FETCH_IN_PROGRESS,
    NFT_FETCH_SUCCESS,
    PROJECTS_LIST_FETCH_ERROR,
    PROJECTS_LIST_FETCH_IN_PROGRESS,
    PROJECTS_LIST_FETCH_SUCCESS,
    USER_NAME_VALUE_SET,
} from '../constants/myAccount';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';
import { DISCONNECT_SET } from '../constants/account';

const myAccountTab = (state = 'my_nfts', action) => {
    if (action.type === MY_ACCOUNT_TAB_SET) {
        return action.value;
    }

    return state;
};

const claimDialog = (state = {
    value: {},
    open: false,
    projectID: '',
    success: false,
    fail: false,
    nftID: '',
}, action) => {
    switch (action.type) {
    case CLAIM_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
            projectID: action.projectID,
        };
    case CLAIM_STATUS_SET:
        return {
            ...state,
            success: action.success,
            fail: action.fail,
            nftID: action.nftID,
        };
    case CLAIM_DIALOG_HIDE:
    case DISCONNECT_SET:
        return {
            ...state,
            open: false,
            projectID: '',
        };

    default:
        return state;
    }
};

const userName = (state = '', action) => {
    if (action.type === USER_NAME_VALUE_SET) {
        return action.value;
    }

    return state;
};

const userNFTs = (state = {
    inProgress: false,
    result: {},
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_USER_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_NFT_S_SUCCESS: {
        const obj = state.result;
        if (action.result && action.result.length) {
            obj[action.denom] = action.result;
        }

        return {
            ...state,
            inProgress: false,
            result: { ...obj },
            count: action.count,
            skip: action.skip,
            limit: action.limit,
        };
    }
    case FETCH_USER_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_USER_NFT_S_SET:
        return {
            ...state,
            inProgress: false,
            result: [],
        };

    default:
        return state;
    }
};

const projectsList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case PROJECTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROJECTS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROJECTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ivProjectsList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case IV_PROJECTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IV_PROJECTS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case IV_PROJECTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nftMint = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_NFT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MINT_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLAIM_DIALOG_HIDE:
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const mintRequests = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_REQUEST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_REQUEST_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.projectID] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case MINT_REQUEST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const mintQueue = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_QUEUE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_QUEUE_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.projectID] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case MINT_QUEUE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const nfts = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case NFT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.nftID] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case NFT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const eligibility = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ELIGIBLE_CHECK_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ELIGIBLE_CHECK_SUCCESS: {
        const value = { ...state.value };
        value[action.interactiveVideoId] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case ELIGIBLE_CHECK_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const claimStatus = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case NFT_CLAIM_STATUS_CHECK_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_CLAIM_STATUS_CHECK_SUCCESS: {
        const value = { ...state.value };
        value[action.denom] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case NFT_CLAIM_STATUS_CHECK_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    userNFTs,
    myAccountTab,
    claimDialog,
    userName,
    projectsList,
    ivProjectsList,
    nftMint,
    mintRequests,
    mintQueue,
    nfts,
    eligibility,
    claimStatus,
});
