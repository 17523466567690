import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Button, Tooltip } from '@mui/material';
import { hideShareDialog } from '../../../actions/home';
import variables from '../../../utils/variables';
import TwitterIcon from '../../../assets/social/new_x.png';
import TelegramIcon from '../../../assets/social/new_telegram.png';
import RedditIcon from './../../../assets/social/new_reddit.png';
import CloseIcon from '../../../assets/closeIcon.svg';
import LinkIcon from '../../../assets/home/linkIcon.svg';

const ShareDialog = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = (value) => {
        const pageUrl = encodeURIComponent('https://akashedu.omniflix.tv');
        let userName;
        if (value === 'X') {
            userName = '@akashnet_';
        } else if (value === 'Telegram') {
            userName = '@AkashNW';
        } else {
            userName = '@akashnetwork';
        }
        const shareText = encodeURIComponent(`Explore Decentralized Cloud Computing with ${userName} is on akashedu.omniflix.tv 🌐. Access exclusive content & get Certificate NFTs upon course completion.\n` +
            'Start your journey now!');
        const targetUrl = value === 'X'
            ? `https://twitter.com/intent/tweet?text=${shareText}&url=${pageUrl}`
            : value === 'Telegram'
                ? `https://t.me/share/url?url=${pageUrl}&text=${shareText}&url=${pageUrl}`
                : value === 'Reddit'
                    ? `https://www.reddit.com/submit?url=${pageUrl}&title=${shareText}&url=${pageUrl}`
                    : undefined;

        window.open(targetUrl, '_blank');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = (e) => {
        navigator.clipboard.writeText('https://akashedu.omniflix.tv');

        e.stopPropagation();
        setOpen(true);
        setTimeout(handleClose, 1000);
    };

    return (
        <Dialog
            aria-describedby="connect"
            aria-labelledby="share dialog"
            className="dialog share_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="share_dialog_content">
                <div className="section1">
                    {variables[props.lang].share}
                    <Button onClick={props.handleClose}><img alt="cancel" src={CloseIcon}/></Button>
                </div>
                <div className="section2">
                    <div className="share">
                        <div className="logo_buttons">
                            <Button onClick={() => handleClick('X')}><img alt="twitter" src={TwitterIcon}/></Button>
                            <Button onClick={() => handleClick('Telegram')}><img alt="telegram" src={TelegramIcon}/></Button>
                            <Button onClick={() => handleClick('Reddit')}><img alt="reddit" src={RedditIcon}/></Button>
                        </div>
                    </div>
                    <div className="copy">
                        <p>{variables[props.lang].or_copy_link}</p>
                        <div className="copy_link">
                            <img alt={'copy'} src={LinkIcon} />
                            <div className="hash_text" title={window.location.href}>
                                <p className="name">{'https://akashedu.omniflix.tv'}</p>
                            </div>
                            <Tooltip arrow open={open} title="Copied!">
                                <Button variant="contained" onClick={handleCopy}>
                                    Copy
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

ShareDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.home.shareDialog.open,
        lang: state.language,
        value: state.home.shareDialog.value,
    };
};

const actionToProps = {
    handleClose: hideShareDialog,
};

export default connect(stateToProps, actionToProps)(ShareDialog);
