export const TO_ADDRESS_SET = 'TO_ADDRESS_SET';
export const TOKENS_TO_SEND_SET = 'TOKENS_TO_SEND_SET';
export const WEB3_INSTANCE_SET = 'WEB3_INSTANCE_SET';
export const CONTRACT_INSTANCE_SET = 'CONTRACT_INSTANCE_SET';

export const PUBLIC_KEY_SET = 'PUBLIC_KEY_SET';

export const CONNECT_BC_ACCOUNT_IN_PROGRESS = 'CONNECT_BC_ACCOUNT_IN_PROGRESS';
export const CONNECT_BC_ACCOUNT_SUCCESS = 'CONNECT_BC_ACCOUNT_SUCCESS';
export const CONNECT_BC_ACCOUNT_ERROR = 'CONNECT_BC_ACCOUNT_ERROR';

export const KEPLR_ACCOUNT_KEYS_SET = 'KEPLR_ACCOUNT_KEYS_SET';

export const VERIFY_ACCOUNT_IN_PROGRESS = 'VERIFY_ACCOUNT_IN_PROGRESS';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';

export const DISCONNECT_SET = 'DISCONNECT_SET';

export const CHAIN_ID_SET = 'CHAIN_ID_SET';
