import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import thumbnail from '../../assets/dummy/course_thumb.png';
import variables from '../../utils/variables';
import { Button } from '@mui/material';
import {
    checkNFTClaimStatus,
    eligibleCheck,
    fetchMintQueue,
    fetchMintRequest,
    fetchNFT,
    fetchProjectsList,
    mintNFT,
    setClaimStatus,
    showClaimDialog,
} from '../../actions/myAccount';
import ClaimDialog from './ClaimDialog';
import ImageOnLoad from '../../components/ImageOnLoad';
import { fetchParticipant } from '../../actions/participant';
import NoData from '../../components/NoData';
import { MARKETPLACE_URL } from '../../config';
import { showMessage } from '../../actions/snackbar';
import { mediaReference } from '../../utils/ipfs';
import CircularProgress from '../../components/CircularProgress';

const timerObj = {};

class Claimable extends Component {
    constructor (props) {
        super(props);

        this.state = {
            inProgress: false,
            id: null,
        };
    }

    componentDidMount () {
        if (this.props.address) {
            this.props.fetchProjectsList(this.props.address, (result) => {
                if (result && result.length) {
                    result.map((value) => {
                        if (value && value.nfts && value.nfts.length) {
                            this.props.fetchNFT(value.nfts);
                            if (this.props.address && value && value.denom && value.denom.id) {
                                this.props.checkNFTClaimStatus(value.denom.id, this.props.address);
                            }
                        }

                        this.props.fetchParticipant(value.interactive_video_id, this.props.address, (val) => {
                            if (val && val.length && val[0] && val[0]._id) {
                                this.props.eligibleCheck(value.interactive_video_id, val[0]._id);
                            }
                        });

                        return null;
                    });
                }
            });

            return;
        }

        this.props.fetchProjectsList();
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.address !== this.props.address) {
            this.props.fetchProjectsList(this.props.address, (result) => {
                if (result && result.length) {
                    result.map((value) => {
                        if (value && value.nfts && value.nfts.length && value.nfts) {
                            this.props.fetchNFT(value.nfts);
                            if (this.props.address && value && value.denom && value.denom.id) {
                                this.props.checkNFTClaimStatus(value.denom.id, this.props.address);
                            }
                        }
                        this.props.fetchParticipant(value.interactive_video_id, this.props.address, (val) => {
                            if (val && val.length && val && val._id) {
                                this.props.eligibleCheck(value.interactive_video_id, val._id);
                            }
                        });

                        return null;
                    });
                }
            });
        }
    }

    componentWillUnmount () {
        clearInterval(timerObj.queueTimer);
    }

    handleViewNFT (id) {
        window.open(MARKETPLACE_URL + '/nft/' + id);
    }

    handleClaim (value, projectId) {
        this.setState({
            inProgress: true,
            id: projectId,
        });

        const projectID = projectId;
        const address = this.props.address;
        const interactiveVideoId = value && value.interactive_video_id;
        let participant = this.props.participant && this.props.participant[interactiveVideoId];
        participant = participant && participant.length && participant.find((val) => val && val.interactive_video &&
            val.interactive_video._id && (val.interactive_video._id === interactiveVideoId));

        const data = {
            address: address,
            quantity: 1,
            participantId: participant && participant._id,
        };

        this.props.mintNFT(projectID, data, (result) => {
            if (result) {
                setTimeout(() => {
                    if (timerObj.queueTimer) {
                        clearInterval(this.state.timerObj.queueTimer);
                    }

                    timerObj.queueTimer = setInterval(() => {
                        this.props.fetchMintQueue(address, projectID, (result) => {
                            if (result && result.length === 0) {
                                clearInterval(timerObj.queueTimer);
                                const mintRequestTime = setInterval(() => {
                                    this.props.fetchMintRequest(address, projectID, (result) => {
                                        if (result && result.length && result[0] && result[0].status &&
                                            result[0].status === 'COMPLETED') {
                                            clearInterval(mintRequestTime);
                                            this.props.fetchProjectsList(address);
                                            this.props.showMessage('Minted Successfully', 'success');
                                            this.setState({
                                                inProgress: false,
                                            });
                                            this.props.showClaimDialog({}, '');
                                            this.props.setClaimStatus(true, false, '');
                                            const value = result;
                                            if (value && value.nfts && value.nfts.length && value.nfts) {
                                                this.props.setClaimStatus(true, false, value.nfts);
                                                this.props.fetchNFT(value.nfts);
                                                if (this.props.address && value && value.denom && value.denom.id) {
                                                    this.props.checkNFTClaimStatus(value.denom.id, this.props.address);
                                                }
                                            }
                                        }
                                    });
                                }, 2000);
                            }
                        });
                    }, 2000);
                }, 2000);
            } else {
                this.props.showClaimDialog({}, '');
                this.setState({
                    inProgress: false,
                });
                this.props.setClaimStatus(false, true, '');
            }
        });
    }

    render () {
        let noData = true;
        if (this.props.list && this.props.list.length) {
            this.props.list.map((item, index) => {
                const denomID = item && item.denom && item.denom.id;
                const nftID = item && item.nfts && item.nfts.length && item.nfts;
                const interactiveVideoId = item && item.interactive_video_id;

                let nftDetails = null;
                if (nftID) {
                    nftDetails = this.props.nfts && this.props.nfts[nftID];
                }

                if ((this.props.address && nftDetails &&
                        nftDetails.owner && (nftDetails.owner !== this.props.address)) ||
                    (nftDetails && nftDetails.status && nftDetails.status === 'BURNED')) {
                    return;
                }

                if (this.props.eligibility && this.props.eligibility[interactiveVideoId] &&
                    !this.props.eligibility[interactiveVideoId].isEligible) {
                    return;
                }

                if (denomID && this.props.claimStatus && this.props.claimStatus[denomID] &&
                    this.props.claimStatus[denomID].length && this.props.claimStatus[denomID]) {
                    return;
                }

                if (item && item.interactive_video_id && this.props.participant &&
                    this.props.participant[item.interactive_video_id] && !this.props.participant[item.interactive_video_id].length) {
                    return;
                }

                noData = false;
            });
        }

        const inProgress = this.props.inProgress || this.props.participantInProgress || this.props.eligibilityInProgress;

        return (
            <div className="claimable lists">
                {inProgress
                    ? <CircularProgress/>
                    : noData
                        ? <NoData text="No NFTs found"/>
                        : this.props.list && this.props.list.length && this.props.participant && Object.keys(this.props.participant).length
                            ? this.props.list.map((item, index) => {
                                const nft = (item && item.nft_meta) || (item && item.denom);
                                const denomID = item && item.denom && item.denom.id;
                                const src = nft && nft.media_uri;
                                const preview = nft && nft.preview_uri;
                                const nftID = item && item.nfts && item.nfts.length && item.nfts;
                                const interactiveVideoId = item && item.interactive_video_id;

                                let nftDetails = null;
                                if (nftID) {
                                    nftDetails = this.props.nfts && this.props.nfts[nftID];
                                }

                                if ((this.props.address && nftDetails &&
                                    nftDetails.owner && (nftDetails.owner !== this.props.address)) ||
                                (nftDetails && nftDetails.status && nftDetails.status === 'BURNED')) {
                                    // return <NoData key={index}/>;
                                    return;
                                }

                                if (this.props.eligibility && this.props.eligibility[interactiveVideoId] &&
                                !this.props.eligibility[interactiveVideoId].isEligible) {
                                    // return <NoData key={index}/>;
                                    return;
                                }

                                if (denomID && this.props.claimStatus && this.props.claimStatus[denomID] &&
                                this.props.claimStatus[denomID].length && this.props.claimStatus[denomID]) {
                                    // return <NoData key={index}/>;
                                    return;
                                }

                                if (item && item.interactive_video_id && this.props.participant &&
                                    this.props.participant[item.interactive_video_id] && !this.props.participant[item.interactive_video_id].length) {
                                    // return <NoData key={index}/>;
                                    return;
                                }

                                return (
                                    <div key={index} className="list_page">
                                        <ImageOnLoad
                                            alt="thumbnail"
                                            className="course_thumbnail list_section2"
                                            preview={mediaReference(preview) || thumbnail}
                                            src={mediaReference(src)}/>
                                        <div className="course_details">
                                            <p className="course_module">{nft && nft.name}</p>
                                            <p className="course_name">{nft && nft.description}</p>
                                        </div>
                                        <div className="actions">
                                            {denomID && this.props.claimStatus && this.props.claimStatus[denomID] &&
                                    this.props.claimStatus[denomID].length
                                                ? <Button
                                                    onClick={() => this.handleViewNFT(this.props.claimStatus[denomID].id)}>
                                                    <p>{variables[this.props.lang].view}</p>
                                                </Button>
                                                : item.nfts && item.nfts.length
                                                    ? <Button
                                                        onClick={() => this.handleViewNFT(item.nfts)}>
                                                        <p>{variables[this.props.lang].view}</p>
                                                    </Button>
                                                    : <Button
                                                        disabled={(this.props.nftMintInProgress || this.state.inProgress) &&
                                                    (this.state.id === item._id)}
                                                        onClick={() => this.handleClaim(item, item._id)}>
                                                        {(this.props.nftMintInProgress || this.state.inProgress) &&
                                                (this.state.id === item._id)
                                                            ? <p>{variables[this.props.lang].processing + '...'}</p>
                                                            : variables[this.props.lang].claim_now}
                                                    </Button>}
                                        </div>
                                    </div>
                                );
                            }) : <NoData text="No NFTs found"/>}
                <ClaimDialog/>
            </div>
        );
    }
}

Claimable.propTypes = {
    checkNFTClaimStatus: PropTypes.func.isRequired,
    claimStatus: PropTypes.object.isRequired,
    eligibility: PropTypes.object.isRequired,
    eligibilityInProgress: PropTypes.bool.isRequired,
    eligibleCheck: PropTypes.func.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchMintRequest: PropTypes.func.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    fetchParticipant: PropTypes.func.isRequired,
    fetchProjectsList: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    mintNFT: PropTypes.func.isRequired,
    nftMintInProgress: PropTypes.bool.isRequired,
    nfts: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    participantInProgress: PropTypes.bool.isRequired,
    setClaimStatus: PropTypes.func.isRequired,
    showClaimDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    address: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        eligibility: state.myAccount.eligibility.value,
        eligibilityInProgress: state.myAccount.eligibility.inProgress,
        lang: state.language,
        inProgress: state.myAccount.projectsList.inProgress,
        list: state.myAccount.projectsList.value,
        nfts: state.myAccount.nfts.value,
        claimStatus: state.myAccount.claimStatus.value,
        participant: state.participant.participant.value,
        participantInProgress: state.participant.participant.inProgress,
        nftMintInProgress: state.myAccount.nftMint.inProgress,
    };
};

const actionsToProps = {
    checkNFTClaimStatus,
    eligibleCheck,
    fetchNFT,
    fetchMintQueue,
    fetchMintRequest,
    fetchParticipant,
    fetchProjectsList,
    mintNFT,
    showMessage,
    showClaimDialog,
    setClaimStatus,
};

export default connect(stateToProps, actionsToProps)(Claimable);
