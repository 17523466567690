export const removeInteraction = (interaction) => {
    const element = document.getElementById(`interaction_buttons-${interaction}`);

    if (element) {
        element.classList.remove('show_preview');
        return true;
    } else {
        return false;
    }
};

export const showInteraction = (interaction) => {
    const element = document.getElementById(`interaction_buttons-${interaction}`);

    if (element) {
        element.classList.add('show_preview');
        return true;
    } else {
        return false;
    }
};
