import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { getConnectedAccounts } from '../../helper';
import { showMessage } from '../../actions/snackbar';

const Accounts = () => {
    const [accounts, setAccounts] = useState([]);

    const handleConnect = () => {
        getConnectedAccounts((error, addresses) => {
            if (error) {
                showMessage(error);
                return;
            }

            setAccounts(addresses);
        });
    };

    return (
        <div>{accounts.length
            ? <div><h2>Connected Accounts</h2>
                <div>
                    {accounts.map((v) => <p key={v} style={{ margin: 10 }}>{v}</p>)}
                </div>
            </div>
            : <Button onClick={handleConnect}>Connect to Keplr</Button>}
        </div>
    );
};

export default Accounts;
