import React from 'react';
import logo from '../../assets/nav_logo.svg';
import './index.css';
import Tabs from './Tabs';
import ConnectButton from './ConnectButton';
import * as PropTypes from 'prop-types';
import ConnectDialog from './ConnectDialog';
import NetworkDialog from './NetworkDialog';
import MetaMaskNetworks from './NetworkDialog/MetaMaskNetworks';
import { connect } from 'react-redux';
import { hideSideBar, showConnectDialog, showSideBar } from '../../actions/navbar';
import ClassNames from 'classnames';
import ExpansionButton from './ExpansionButton';
import { matchPath, withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import ConnectAccountDialog from './ConnectAccountDialog';
import { ReactComponent as UserIcon } from '../../assets/user_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeIcon.svg';
import { getWrapAddress } from '../../utils/strings';
import CopyButton from '../../components/CopyButton';
import DisconnectButton from './DisconnectButton';
import variables from '../../utils/variables';

const NavBar = (props) => {
    const match = matchPath(props.location.pathname, {
        path: '/interactive-videos/:id',
        exact: true,
        strict: false,
    });

    const handleShow = () => {
        if (props.address) {
            return;
        }

        props.showConnectDialog(false);
    };

    const handleRedirect = (value) => {
        if (value === 'home') {
            props.history.push('/');
        } else {
            props.history.push(`/${value}`);
        }
        props.handleClose();
    };

    const videoId = match && match.params && match.params.id;

    return (
        <div className="nav_bar">
            <div className="inner_nav">
                <img alt="omniflix" src={logo} onClick={() => props.history.push('/')}/>
                {props.address
                    ? <Button className="user_icon" onClick={() => handleRedirect('account')}><UserIcon/></Button>
                    : <Button className="toggle_connect" onClick={handleShow}>{variables[props.lang].connect}</Button>}
                <ExpansionButton/>
                {props.show &&
                    <span
                        className="layer"
                        onClick={props.handleClose}/>}
                <div className={ClassNames('nav_content', props.show ? 'show' : '')}>
                    <Tabs/>
                    <ConnectButton/>
                    <div className="close_section">
                        <Button onClick={props.handleClose}> <CloseIcon/> </Button>
                    </div>
                    {props.address
                        ? <>
                            <div className="mobile_account">
                                <p>{variables[props.lang].address}</p>
                                <div className="hash_text" title={props.address}>
                                    {getWrapAddress(props.address, 10)}
                                    <CopyButton data={props.address}/>
                                </div>
                            </div>
                            <span/>
                        </>
                        : null}
                    <div className="mobile_tabs">
                        <p onClick={() => handleRedirect('home')}>
                            {variables[props.lang].home}
                        </p>
                        <p onClick={() => handleRedirect('courses')}>
                            {variables[props.lang].courses}
                        </p>
                        {props.address
                            ? <p onClick={() => handleRedirect('account')}>
                                {variables[props.lang].my_account}
                            </p>
                            : null}
                    </div>
                    {props.address
                        ? <div className="mobile_disconnect" onClick={props.handleClose}>
                            <DisconnectButton/>
                        </div>
                        : null}
                </div>
            </div>
            <ConnectDialog videoId={videoId}/>
            <NetworkDialog videoId={videoId}/>
            <MetaMaskNetworks videoId={videoId}/>
            <ConnectAccountDialog videoId={videoId}/>
        </div>
    );
};

NavBar.propTypes = {
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    show: PropTypes.bool.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showSideBar: PropTypes.func.isRequired,
    address: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        show: state.navbar.show,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideSideBar,
    showConnectDialog,
    showSideBar,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
