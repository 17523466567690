import React from 'react';
import {
    clearData,
    fetchDeviceID,
    fetchWatchURL,
    getInteractiveVideo,
    hideInteractionTime,
    setAccountAddress,
    setCurrentInteraction,
    setPlayer,
    showInteractionTime,
    showClaimCertificateDialog,
} from '../../actions/interactiveVideo';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Player from '../../components/Player';
import { CircularProgress } from '@material-ui/core';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import InteractiveLayer from './InteractiveLayer';
import './index.css';
import Header from './Header';
import Controls from './Controls';
import { showMessage } from '../../actions/snackbar';
import LeaderBoard from './LeaderBoard';
import { showConnectAccountDialog } from '../../actions/navbar';
import { removeInteraction, showInteraction } from '../../utils/interaction';
import InteractionMarks from './InteractionMarks';
// import { videosList } from '../../utils/videosList';
import ClaimNFTButton from './ClaimNFTButton';
import { checkNFTClaimStatus, eligibleCheck, fetchIVProjectsList, fetchNFT } from '../../actions/myAccount';
import { fetchParticipant } from '../../actions/participant';
import ClaimCertificateDialog from './ClaimCertificateDialog';
import TokenGatedContentDialog from '../VideosList/TokenGatedContentDialog';
// import InteractiveCard from './InteractiveCard';

const videoJSOptions = {
    autoplay: false,
    loop: false,
    width: 500,
    height: 600,
    controls: true,
    controlBar: {
        children: [
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
        ],
    },
    userActions: {
        doubleClick: false,
    },
};

class InteractiveVideo extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.setConfig = this.setConfig.bind(this);
        this.displayInteractionAndTime = this.displayInteractionAndTime.bind(this);
        this.state = {
            config: null,
            inProgress: false,
            sessionInProgress: false,
            duration: null,
        };
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);
        this.props.setPlayer(this.player);

        if (this.player) {
            // Disable the controlBar, not to change seek.
            this.player.controlBar.progressControl.disable();
            // ReactDOM.render(<div>Hello</div>, document.getElementById('video-player'));

            this.player.on('seeked', () => {
                const current = this.player.currentTime();
                const activeInteractionIndex = this.props.interactions.findIndex((item) =>
                    item.start_at >= current);

                this.props.setCurrentInteraction(activeInteractionIndex !== -1
                    ? activeInteractionIndex : 0);
            });

            this.player.on('timeupdate', () => {
                if (this.state.duration === null) {
                    this.setState({
                        duration: this.player.duration(),
                    });
                }

                if (!this.player.currentTime() || !this.player.duration()) {
                    return;
                }

                const current = this.player.currentTime();
                const activeInteraction = this.props.currentInteraction;
                const interactionData = this.props.interactions[activeInteraction];

                if (current && interactionData) {
                    // Note: timeupdate fn will trigger anywhere between 50ms to 250ms or even a bit more.
                    // Here 0.3 = 300ms

                    if (current > interactionData.start_at) {
                        this.player.pause();
                        if (this.props.session && this.props.session._id) {
                            this.displayInteractionAndTime();

                            return;
                        }

                        // if (activeInteraction === 0 && !this.props.address) {
                        //     this.props.show(true);
                        //
                        //     return;
                        // }
                    }

                    if (current < interactionData.start_at &&
                        this.props.session && this.props.session._id) {
                        // this is for seek value, where in case seekback happens,
                        // so we remove the interaction only if there is a timer,
                        // if no timer means it is already removed using timer component.

                        if (this.props.interactionTime) {
                            this.props.hideInteractionTime();
                            if (!removeInteraction(activeInteraction)) {
                                this.props.showMessage('Failed to remove interaction');
                            }
                        }
                    }
                }
            });
        }
    }

    setConfig (url) {
        const config = {
            sources: [{
                type: 'application/x-mpegURL',
                src: url,
            }],
        };
        this.setState({
            config: JSON.stringify(config),
        });
    }

    displayInteractionAndTime () {
        this.props.showInteractionTime();
        if (!showInteraction(this.props.currentInteraction)) {
            this.props.showMessage('Failed to show interaction');
        }
    }

    componentDidMount () {
        const id = this.props.match && this.props.match.params.id;

        if (id) {
            this.props.getVideo(id);
            this.props.setCurrentInteraction(0);
            this.props.clearData();

            if (this.props.deviceID) {
                this.props.fetchWatchURL(id, this.props.deviceID, (error, url) => {
                    if (error) {
                        return;
                    }

                    this.setConfig(url);
                });
            } else {
                this.props.fetchDeviceID((error, deviceID) => {
                    if (error) {
                        return;
                    }

                    this.props.fetchWatchURL(id, deviceID, (error, url) => {
                        if (error) {
                            return;
                        }
                        this.setConfig(url);
                    });
                });
            }

            this.props.fetchIVProjectsList(this.props.address, id, (result) => {
                if (result && result.length) {
                    result.map((value) => {
                        if (value && value.nfts && value.nfts.length) {
                            this.props.fetchNFT(value.nfts);
                            if (this.props.address && value && value.denom && value.denom.id) {
                                this.props.checkNFTClaimStatus(value.denom.id, this.props.address);
                            }
                        }

                        this.props.fetchParticipant(value.interactive_video_id, this.props.address, (val) => {
                            if (val && val.length && val[0] && val[0]._id) {
                                this.props.eligibleCheck(value.interactive_video_id, val[0]._id);
                            }
                        });

                        return null;
                    });
                }
            });
        }
    }

    componentWillUnmount () {
        this.props.clearData();
        if (this.player) {
            this.player.dispose();
        }
    }

    render () {
        const {
            config,
            duration,
        } = this.state;
        const id = this.props.match && this.props.match.params.id;
        const video = this.props.list && this.props.list.length && this.props.list.find((val) => val._id === id);
        const videoTitle = video && video.asset && video.asset.name;

        return (
            <div className="interactive_video_background">
                <div className="interactive_video padding interactive_video_main">
                    <>{config
                        ? <>
                            <Header interactive={'true'} value={videoTitle} video={video}/>
                            <div className="player">
                                <Player
                                    initializePlayer={this.initializePlayer}
                                    setup={config}>
                                    <InteractionMarks
                                        duration={
                                            this.player && this.player.duration()
                                                ? this.player.duration()
                                                : duration} interactions={this.props.interactions}/>
                                    <Controls hideSeek={true} interactive={true}/>
                                </Player>
                                {this.props.interactions && this.props.interactions.length > 0 &&
                                    this.props.interactions.map((value, index) =>
                                        <InteractiveLayer
                                            key={value.start_at}
                                            currentInteraction={this.props.currentInteraction}
                                            index={index} value={value}/>)}
                                {/* <InteractiveCard/> */}
                            </div>
                        </>
                        : <div className="player">
                            <CircularProgress/>
                        </div>}
                    <div className="video_info">
                        <div className="top">
                            <div className="details">
                                <p className="module">{video && video.asset && video.asset.module}</p>
                                <h2>{video && video.asset && video.asset.name}</h2>
                            </div>
                            <ClaimNFTButton video={video} videoId={this.props.match && this.props.match.params.id}/>
                        </div>
                        <p className="description"> {video && video.asset && video.asset.description} </p>
                    </div>
                    <LeaderBoard videoId={this.props.match && this.props.match.params.id}/>
                    </>
                    <ClaimCertificateDialog video={video} videoId={this.props.match && this.props.match.params.id}/>
                    <TokenGatedContentDialog/>
                </div>
            </div>
        );
    }
}

InteractiveVideo.propTypes = {
    checkNFTClaimStatus: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    currentInteraction: PropTypes.number.isRequired,
    deviceIDProgress: PropTypes.bool.isRequired,
    eligibleCheck: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchIVProjectsList: PropTypes.func.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    fetchParticipant: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getVideo: PropTypes.func.isRequired,
    hideInteractionTime: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    pubKey: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    setPlayer: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    showClaimCertificateDialog: PropTypes.func.isRequired,
    showInteractionTime: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    interactionTime: PropTypes.bool,
    interactions: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.number.isRequired,
            time_limit: PropTypes.number.isRequired,
            answers: PropTypes.array,
        }),
    ),
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        deviceID: state.interactiveVideo.deviceID.id,
        deviceIDProgress: state.interactiveVideo.deviceID.inProgress,
        interactions: state.interactiveVideo._.interactions,
        currentInteraction: state.interactiveVideo.currentInteraction,
        session: state.interactiveVideo.session.data,
        address: state.account.address,
        pubKey: state.account.pubKey,
        interactionTime: state.interactiveVideo.interactionTime,
        list: state.navbar.videosList.value,
    };
};

const actionsToProps = {
    fetchWatchURL,
    fetchDeviceID,
    getVideo: getInteractiveVideo,
    setPlayer,
    showMessage,
    setCurrentInteraction,
    setAccountAddress,
    show: showConnectAccountDialog,
    showInteractionTime,
    clearData,
    hideInteractionTime,
    fetchNFT,
    fetchParticipant,
    fetchIVProjectsList,
    checkNFTClaimStatus,
    eligibleCheck,
    showClaimCertificateDialog,
};

export default connect(stateToProps, actionsToProps)(InteractiveVideo);
