import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';

const Header = (props) => {
    return (
        <div className="header">
            <div>
                <p>{variables[props.lang].courses}</p>
                <span>{variables[props.lang]['courses_sub_text']}</span>
            </div>
        </div>
    );
};

Header.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Header);
