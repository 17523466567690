import React from 'react';
import './index.css';
import PlayButton from './PlayButton2';
import * as PropTypes from 'prop-types';
import VolumeButton from '../Header/VolumeButton';
import BackButton from './BackButton';
import ForwardButton from './ForwardButton';

const Controls = (props) => {
    const player = props.vjsBridgeComponent.player();

    return (
        <div className="player_controls video_full_screen">
            <div className="left_content">
                <BackButton player={player}/>
                <PlayButton options={props.options} player={player}/>
                <ForwardButton player={player}/>
            </div>
            <VolumeButton player={player}/>
        </div>
    );
};

Controls.propTypes = {
    options: PropTypes.object.isRequired,
    vjsBridgeComponent: PropTypes.shape({
        player: PropTypes.func.isRequired,
    }),
};

export default Controls;
