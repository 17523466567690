import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import variables from '../../utils/variables';
import MyAccountTab from './Tabs';
import FlixIcon from '../../assets/logos/logo.svg';
import { getWrapAddress } from '../../utils/strings';
import CopyButton from '../../components/CopyButton';

const Header = (props) => {
    return (
        <div className="header">
            <div className="content1">
                <span>{variables[props.lang]['my_account']}</span>
                <div className="address_section">
                    <img alt="Flix Icon" src={FlixIcon}/>
                    <span>{getWrapAddress(props.address, 10)}</span>
                    <CopyButton data={props.address}/>
                </div>
                <MyAccountTab />
            </div>
        </div>
    );
};

Header.propTypes = {
    lang: PropTypes.string.isRequired,
    address: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        address: state.account.address,
    };
};

export default connect(stateToProps)(Header);
