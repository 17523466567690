import React from 'react';
import {
    addInteractiveVideoSession,
    clearData,
    fetchDeviceID,
    fetchWatchURL, hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    setPlayer, showIMPInformationDialog,
    showTokenGatedContentDialog,
    showQuizDialog,
} from '../../actions/interactiveVideo';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Player from '../../components/Player';
import { CircularProgress } from '@material-ui/core';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import './index.css';
import { showMessage } from '../../actions/snackbar';
import BackButton from '../../components/BackButton';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import controlsVjs from '../InteractiveVideo/Controls/controlsVjs';
import { Button } from '@mui/material';
import { ReactComponent as InteractIcon } from '../../assets/home/interact.svg';
import Certificate from '../../assets/interactiveVideo/certificate.png';
import { fetchCollectionMyNFTs } from '../../actions/gatedAccess';
import { walletConnectSign, aminoSignTxMetaMask } from '../../actions/account/wallet';
import TokenGatedContentDialog from '../VideosList/TokenGatedContentDialog';
import variables from '../../utils/variables';
import { config } from '../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign, metaMaskSign } from '../../helper';
import { showConnectAccountDialog, showConnectDialog } from '../../actions/navbar';
import QuizDialog from './QuizDialog';
import IMPInformationDialog from '../VideosList/IMPInformationDialog';

const videoJSOptions = {
    autoplay: false,
    loop: false,
    width: 500,
    height: 600,
    controls: true,
    controlBar: {
        children: [
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
            'FullscreenToggle',
        ],
    },
    userActions: {
        doubleClick: false,
    },
};

class VideoPlayer extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.setConfig = this.setConfig.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.addSession = this.addSession.bind(this);
        this.handleCosmostation = this.handleCosmostation.bind(this);
        this.handleLeap = this.handleLeap.bind(this);
        this.signWithCosmosAccount = this.signWithCosmosAccount.bind(this);
        this.walletConnectSign = this.walletConnectSign.bind(this);
        this.handleMetaMaskLeap = this.handleMetaMaskLeap.bind(this);
        this.handleMetaMaskCosmos = this.handleMetaMaskCosmos.bind(this);

        this.state = {
            config: null,
            inProgress: false,
            sessionInProgress: false,
            duration: null,
        };
    }

    componentDidMount () {
        const id = this.props.match && this.props.match.params.id;
        // const video = videosList && videosList.find((val) => val._id === id);
        //
        // if (video && video.asset && video.asset.url) {
        //     this.setConfig(video.asset.url);
        // }
        //
        if (id) {
            this.props.clearData();

            if (this.props.deviceID) {
                this.props.fetchWatchURL(id, this.props.deviceID, (error, url) => {
                    if (error) {
                        return;
                    }

                    this.setConfig(url);
                });
            } else {
                this.props.fetchDeviceID((error, deviceID) => {
                    if (error) {
                        return;
                    }

                    this.props.fetchWatchURL(id, deviceID, (error, url) => {
                        if (error) {
                            return;
                        }
                        this.setConfig(url);
                    });
                });
            }
        }
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);

        if (this.player) {
            this.player.addChild('controlsVjs', { ...this.props });
            this.props.setPlayer(this.player);
            this.player.on('ended', () => {
                this.props.showQuizDialog();
            });
        }
    }

    setConfig (url) {
        const config = {
            sources: [{
                type: 'application/x-mpegURL',
                src: url,
            }],

        };
        this.setState({
            config: JSON.stringify(config),
        });
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.removeChild('controlsVjs', {});
            this.player.dispose();
        }
    }

    handleOpen (video) {
        if (!this.props.address) {
            // props.showConnectAccountDialog(true);
            this.props.showMessage('Connect Account');
            return;
        }

        this.props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            this.props.fetch(video.akash_edu_config.denom_id, this.props.address, (result) => {
                if (result && result.length) {
                    this.props.hideTokenGatedContentDialog();
                    this.addSession(video);
                }
            });
        }
    }

    addSession (video) {
        if (!this.props.deviceID) {
            this.props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    this.signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    this.walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    this.handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    this.handleLeap(deviceID, video);
                } else if (lockType === 'mml') {
                    this.handleMetaMaskLeap(deviceID, video);
                } else if (lockType === 'mmc') {
                    this.handleMetaMaskCosmos(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                this.signWithCosmosAccount(this.props.deviceID, video);
            } else if (lockType === 'wc') {
                this.walletConnectSign(this.props.deviceID, video);
            } else if (lockType === 'cs') {
                this.handleCosmostation(this.props.deviceID, video);
            } else if (lockType === 'leap') {
                this.handleLeap(this.props.deviceID, video);
            } else if (lockType === 'mml') {
                this.handleMetaMaskLeap(this.props.deviceID, video);
            } else if (lockType === 'mmc') {
                this.handleMetaMaskCosmos(this.props.deviceID, video);
            }
        }
    }

    handleCosmostation (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, this.props.address, (error, result) => {
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // this.props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    handleLeap (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, this.props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    signWithCosmosAccount (deviceID, video) {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, this.props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    walletConnectSign (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        this.props.walletConnectSign(this.props.walletConnector, tx, this.props.address, (result) => {
            if (result) {
                this.props.addInteractiveVideoSession(video._id, {
                    accountAddress: this.props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // props.showMessage('Error while adding session');
                        return;
                    }

                    this.props.showIMPInformationDialog(video);
                });
            }
        });
    }

    handleMetaMaskLeap (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        this.props.aminoSignTxMetaMask(tx, this.props.address, (result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    handleMetaMaskCosmos (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        metaMaskSign(tx, this.props.address, (error, result) => {
            if (error) {
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    render () {
        const {
            config,
        } = this.state;

        const id = this.props.match && this.props.match.params.id;
        const video = this.props.list && this.props.list.length && this.props.list.find((val) => val._id === id);
        const videoTitle = video && video.asset && video.asset.name;
        const courseId = video && video.akash_edu_config && video.akash_edu_config.course_id;
        const interactiveVideosList = this.props.list && this.props.list.length && this.props.list.filter((val) => val && val.akash_edu_config && val.akash_edu_config.is_interactive === true);
        const quizDetails = interactiveVideosList && interactiveVideosList.length && interactiveVideosList.find((val) => val && val.akash_edu_config && val.akash_edu_config.course_id === courseId);

        return (
            <div className="interactive_video_background">
                <div className="interactive_video padding">
                    <>{config
                        ? <>
                            <div className="player_header">
                                <BackButton value={videoTitle}/>
                            </div>
                            <div className="player">
                                <Player
                                    initializePlayer={this.initializePlayer}
                                    setup={config}/>
                            </div>
                            <div className="video_info">
                                <p className="module">{video && video.asset && video.asset.module}</p>
                                <h2>{video && video.asset && video.asset.name}</h2>
                                <p className="description">{video && video.asset && video.asset.description}</p>
                            </div>
                            {quizDetails
                                ? <div className="quiz_info_section">
                                    <img alt="Certificate_image" src={Certificate}/>
                                    <div className="top_section">
                                        <p>{variables[this.props.lang].lets_take_quiz}</p>
                                        <span>{variables[this.props.lang].text_your_knowledge}</span>
                                    </div>
                                    <div className="bottom_section">
                                        <span>{quizDetails && quizDetails.asset && quizDetails.asset.module}</span>
                                        <p>{quizDetails && quizDetails.asset && quizDetails.asset.name}</p>
                                        <Button onClick={() => this.handleOpen(quizDetails)}>
                                            <InteractIcon/>
                                            {variables[this.props.lang].interact_and_earn}
                                        </Button>
                                    </div>
                                </div> : null}
                        </>
                        : <div className="player">
                            <CircularProgress/>
                        </div>}
                    </>
                    <TokenGatedContentDialog/>
                    <IMPInformationDialog/>
                    { quizDetails && <QuizDialog handleOpen={this.handleOpen} quizDetails={quizDetails}/> }
                </div>
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    aminoSignTxMetaMask: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    collectionMyNFTs: PropTypes.array.isRequired,
    deviceIDProgress: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    hideIMPInformationDialog: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    setPlayer: PropTypes.func.isRequired,
    showConnectAccountDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showIMPInformationDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showQuizDialog: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    interactive: PropTypes.bool,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        deviceID: state.interactiveVideo.deviceID.id,
        deviceIDProgress: state.interactiveVideo.deviceID.inProgress,
        list: state.navbar.videosList.value,
        collectionMyNFTs: state.gatedAccess.collectionMyNFTs.result,
        walletConnector: state.account.wallet.connection.walletConnector,
        session: state.interactiveVideo.session.data,
        lang: state.language,
    };
};

const actionsToProps = {
    clearData,
    fetchWatchURL,
    fetchDeviceID,
    setPlayer,
    showMessage,
    showIMPInformationDialog,
    hideIMPInformationDialog,
    addInteractiveVideoSession,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
    showConnectAccountDialog,
    fetch: fetchCollectionMyNFTs,
    walletConnectSign,
    showConnectDialog,
    showQuizDialog,
    aminoSignTxMetaMask,
};

export default withRouter(connect(stateToProps, actionsToProps)(VideoPlayer));
