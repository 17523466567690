import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getInteractiveVideoParticipants } from '../../../actions/participant';
import DataTable from '../../../components/DataTable';
import { networkImages } from '../../../config/network';
import cosmos from '../../../assets/networks/cosmos_mini_light.svg';
import sentinel from '../../../assets/networks/sentinel.png';
import akash from '../../../assets/networks/akash.png';
import omniflix from '../../../assets/networks/omniflix.png';
import binance from '../../../assets/networks/binance.svg';
import polygon from '../../../assets/networks/polygon.svg';
import ethereum from '../../../assets/networks/ethereum_light.svg';
import iris from '../../../assets/networks/iris.png';
import osmosis from '../../../assets/networks/osmosis.png';
import juno from '../../../assets/networks/juno.svg';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../../constants/url';
import variables from '../../../utils/variables';
import SearchTextField from './SearchTextField';

class LeaderboardTable extends React.Component {
    constructor (props) {
        super(props);
        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        this.props.getParticipants(this.props.videoId, DEFAULT_SKIP, DEFAULT_LIMIT, '', DEFAULT_SORT_BY, null);
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        this.props.getParticipants(this.props.videoId, skip, limit, searchText, sortBy, order);
    }

    render () {
        const options = {
            responsive: 'standard',
            serverSide: true,
            count: this.props.count,
            download: false,
            print: false,
            selectableRows: 'none',
            viewColumns: false,
            filter: false,
            search: true,
            onSearchChange: (searchText) => {
                this.handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, searchText, DEFAULT_SORT_BY, null);
            },
            onChangePage: (currentPage) => {
                if (this.props.participants.data &&
                    this.props.participants.data.list &&
                    this.props.participants.data.list.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.participants.data &&
                    this.props.participants.data.list &&
                    this.props.participants.data.list.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.participants.data &&
                    this.props.participants.data.list &&
                    this.props.participants.data.list.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 1 : -1;
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            textLabels: {
                body: {
                    noMatch: <p className="no_data_table"> No data found </p>,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => column.label === 'Interactions'
                        ? 'Interactions represent total questions answered and NOT total accurate answers'
                        : `Sort for ${column.label}`,
                },
                pagination: {
                    next: 'Next Page',
                    previous: 'Previous Page',
                    rowsPerPage: 'Rows per page:',
                    displayRows: 'of',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'account_address',
            label: 'Account',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let network = networkImages.filter((val) => value && value.includes(val));
                    if (network && network.length && network[0]) {
                        network = network[0];
                    } else {
                        network = '';
                    }

                    return (
                        <div className="profile">
                            {network !== '' || value
                                ? <>
                                    <img
                                        alt={network}
                                        className="network_image"
                                        src={network === 'cosmos' ? cosmos
                                            : network === 'sent' ? sentinel
                                                : network === 'akash' ? akash
                                                    : network === 'omniflix' ? omniflix
                                                        : network === 'binance' ? binance
                                                            : network === 'polygon' ? polygon
                                                                : network === 'osmo' ? osmosis
                                                                    : network === 'juno' ? juno
                                                                        : network === 'iaa' ? iris
                                                                            : network === '0x' ? ethereum
                                                                                : ''}/>
                                    <div className="hash_text" title={value}>
                                        <p className="ellipses_text">{value}</p>
                                        <p>{value
                                            .slice(value.length - 8, value.length)}</p>
                                    </div>
                                </>
                                : <p className="image">PN</p>}
                        </div>
                    );
                },
            },
        }, {
            name: 'questions_answered',
            label: 'Interactions',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{value}</p>
                    );
                },
            },
        }];

        const tableData = this.props.participants.data &&
        this.props.participants.data.list &&
        this.props.participants.data.list.length
            ? this.props.participants.data && this.props.participants.data.list &&
            this.props.participants.data.list.map((item, index) => [
                item.account_address,
                item.questions_answered,
                // <p key={index} className="rank">{index + 1}<span>{' '}Rank</span></p>,
            ])
            : [];

        return (
            <div className="leader_board_table">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name={<div className="table_header">
                        <h2>{variables[this.props.lang].participants}</h2>
                        <SearchTextField videoId={this.props.videoId}/>
                    </div>}
                    options={options}/>
            </div>
        );
    }
}

LeaderboardTable.propTypes = {
    count: PropTypes.number.isRequired,
    getParticipants: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    order: PropTypes.number,
    participants: PropTypes.shape({
        inProgress: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            list: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    questions_answered: PropTypes.number,
                    total_questions: PropTypes.number,
                    account_address: PropTypes.string,
                    correct_answers: PropTypes.number,
                }),
            ),
        }),
    }),
};

const stateToProps = (state) => {
    return {
        participants: state.participant.list,
        skip: state.participant.list.skip,
        limit: state.participant.list.limit,
        lang: state.language,
        count: state.participant.list.count,
        searchKey: state.participant.list.searchKey,
        sortBy: state.participant.list.sortBy,
        order: state.participant.list.order,
    };
};
const actionToProps = {
    getParticipants: getInteractiveVideoParticipants,
};

export default withRouter(connect(stateToProps, actionToProps)(LeaderboardTable));
