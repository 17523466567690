import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import pageNotFound from '../../assets/not_found.png';
import variables from '../../utils/variables';
import { Button } from '@mui/material';
import { ReactComponent as GoBackIcon } from '../../assets/goBack.svg';

const PageNotFound = (props) => {
    const handleClick = (value) => {
        if (value === 'home') {
            props.history.push('/');
        } else {
            props.history.goBack();
        }
    };

    return (
        <div className="not_found">
            <>
                <img alt="pageNotFound" src={pageNotFound}/>
                <p>{variables[props.lang].page_not_found}</p>
                <div className="actions">
                    <Button onClick={() => handleClick()}><GoBackIcon/>{variables[props.lang].go_back}</Button>
                    <Button onClick={() => handleClick('home')}>{variables[props.lang].take_me_home}</Button>
                </div>
            </>
        </div>
    );
};

PageNotFound.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(PageNotFound);
