import React, { useState } from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetaMaskIcon from '../../../assets/logos/meta_cosmos.svg';
import { initializeMetaMaskCosmos } from '../../../helper';
import { setAccountAddress } from '../../../actions/interactiveVideo';
import { hideConnectDialog } from '../../../actions/navbar';

const MetaMaskCosmosSnaps = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeWallet = () => {
        setInProgress(true);
        initializeMetaMaskCosmos((error, result) => {
            if (error) {
                window.onload = () => initializeWallet();
                return;
            }

            props.setAccountAddress(result && result.address && result.address.address);
            localStorage.setItem('akt_edu_address', (result && result.address && result.address.address));
            localStorage.setItem('akt_edu_connector', 'mmc');
            setInProgress(false);
            props.hideConnectDialog();
        });
    };

    return (
        <Button
            className="wallet_button metamask_button"
            disabled={inProgress}
            variant="contained"
            onClick={initializeWallet}>
            <img alt="logo" src={MetaMaskIcon}/>
            {inProgress
                ? <p>Connecting...</p>
                : <p>Metamask <span>(Cosmos Snap by Mystique Labs)</span></p>}
        </Button>
    );
};

MetaMaskCosmosSnaps.propTypes = {
    hideConnectDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {
    setAccountAddress,
    hideConnectDialog,
};

export default connect(stateToProps, actionToProps)(MetaMaskCosmosSnaps);
