import React, { useState } from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeChainLeap } from '../../../actions/account/wallet';
import variables from '../../../utils/variables';
import { hideConnectDialog } from '../../../actions/navbar';
import leapIcon from '../../../assets/wallets/leap.svg';
import { addInteractiveVideoSession, setAccountAddress } from '../../../actions/interactiveVideo';

const LeapWalletButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeLeap = () => {
        setInProgress(true);
        props.initializeChain((address) => {
            setInProgress(false);
            if (!address) {
                window.onload = () => initializeLeap();
                return;
            }

            props.setAccountAddress(address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_address', address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_connector', 'leap');
            props.handleClose();
        });
    };

    const connectInProgress = props.inProgress && inProgress;
    return (
        <Button
            className="wallet_button keplr_button"
            disabled={connectInProgress}
            variant="contained"
            onClick={initializeLeap}>
            <img alt="logo" src={leapIcon}/>
            {connectInProgress ? variables[props.lang].connecting + '...' : variables[props.lang].leap}
        </Button>
    );
};

LeapWalletButton.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setPublicKey: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.bool.isRequired,
    deviceId: PropTypes.string,
    player: PropTypes.object,
    videoId: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        inProgress: state.account.wallet.connection.inProgress,
        deviceId: state.interactiveVideo.deviceID.id,
        lang: state.language,
        open: state.navbar.connectDialog,
        sign: state.account.connectDialog.sign,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    addInteractiveVideoSession,
    initializeChain: initializeChainLeap,
    handleClose: hideConnectDialog,
    setAccountAddress,
};

export default connect(stateToProps, actionToProps)(LeapWalletButton);
