import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import variables from '../../utils/variables';
import Header from './Header';
import List from './List';

class Courses extends React.Component {
    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render () {
        return (
            <div className="courses">
                <Header/>
                <List/>
            </div>
        );
    }
}

Courses.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionsToProps = {

};

export default withRouter(connect(stateToProps, actionsToProps)(Courses));
