import axios from 'axios';
import {
    ACCOUNT_ADDRESS_SET,
    CLEAR_DATA,
    CURRENT_INTERACTION_SET,
    DEVICE_ID_FETCH_ERROR,
    DEVICE_ID_FETCH_IN_PROGRESS,
    DEVICE_ID_FETCH_SUCCESS,
    IMP_INFORMATION_DIALOG_HIDE,
    IMP_INFORMATION_DIALOG_SHOW,
    INTERACTION_TIME_HIDE,
    INTERACTION_TIME_SHOW,
    INTERACTIVE_PLAYER_SET,
    INTERACTIVE_QUESTION_TYPE_SET,
    INTERACTIVE_SAVE_SET,
    INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR,
    INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS,
    INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS,
    INTERACTIVE_VIDEO_FETCH_ERROR,
    INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_FETCH_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_ADD_ERROR,
    INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS,
    INTERACTIVE_VIDEO_URL_FETCH_ERROR,
    INTERACTIVE_VIDEO_URL_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_URL_FETCH_SUCCESS,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    SEARCH_FOR_ASSET_SET,
    TOKEN_GATED_CONTENT_DIALOG_HIDE,
    TOKEN_GATED_CONTENT_DIALOG_SHOW,
    WINDOW_RESIZE_SET,
    CLAIM_CERTIFICATE_DIALOG_SHOW,
    CLAIM_CERTIFICATE_DIALOG_HIDE,
    SET_CLAIM_CERTIFICATE_LOADING,
    QUIZ_DIALOG_SHOW,
    QUIZ_DIALOG_HIDE,
} from '../constants/interactiveVideo';
import {
    addInteractiveVideoSessionURL,
    completeInteractiveVideoSessionURL,
    getInteractiveVideoShowURL,
    getInteractiveVideosURL,
    getInteractiveVideoURL,
    saveInteractiveVideoAnswerURL,
} from '../constants/url';
import calculateDeviceFingerprint from '../utils/fingerprint';

export const showIMPInformationDialog = (value) => {
    return {
        type: IMP_INFORMATION_DIALOG_SHOW,
        value,
    };
};

export const hideIMPInformationDialog = () => {
    return {
        type: IMP_INFORMATION_DIALOG_HIDE,
    };
};

export const showTokenGatedContentDialog = (value) => {
    return {
        type: TOKEN_GATED_CONTENT_DIALOG_SHOW,
        value,
    };
};

export const hideTokenGatedContentDialog = () => {
    return {
        type: TOKEN_GATED_CONTENT_DIALOG_HIDE,
    };
};

const fetchInteractiveVideosInProgress = () => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    };
};

const fetchInteractiveVideosSuccess = (value, skip, limit, search, total) => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        search,
        total,
    };
};

const fetchInteractiveVideosError = (message) => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_ERROR,
        message,
    };
};

export const getInteractiveVideos = (search, skip, limit, createdBy, organizationId) => (dispatch) => {
    dispatch(fetchInteractiveVideosInProgress());

    const URL = getInteractiveVideosURL(search, skip, limit, createdBy, organizationId);
    axios.get(URL, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchInteractiveVideosSuccess(res.data && res.data.result && res.data.result.list,
            skip, limit, search, res.data && res.data.result && res.data.result.count));
    }).catch((error) => {
        dispatch(fetchInteractiveVideosError(error.response ? error.response.data.message : error.message));
    });
};

const fetchDeviceIDInProgress = () => {
    return {
        type: DEVICE_ID_FETCH_IN_PROGRESS,
    };
};

const fetchDeviceIDSuccess = (value) => {
    return {
        type: DEVICE_ID_FETCH_SUCCESS,
        value,
    };
};

const fetchDeviceIDError = (message) => {
    return {
        type: DEVICE_ID_FETCH_ERROR,
        message,
    };
};

export const fetchDeviceID = (cb) => (dispatch) => {
    dispatch(fetchDeviceIDInProgress());

    calculateDeviceFingerprint((error, fingerprint) => {
        if (error) {
            dispatch(fetchDeviceIDError(error.response ? error.response.data.message : error.message));
            cb(error);
        } else {
            dispatch(fetchDeviceIDSuccess(fingerprint));
            cb(null, fingerprint);
        }
    });
};

const fetchInteractiveVideoURLInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_URL_FETCH_IN_PROGRESS,
    };
};

const fetchInteractiveVideoURLSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_URL_FETCH_SUCCESS,
        value,
    };
};

const fetchInteractiveVideoURLError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_URL_FETCH_ERROR,
        message,
    };
};

export const fetchWatchURL = (Id, deviceId, cb) => (dispatch) => {
    dispatch(fetchInteractiveVideoURLInProgress());
    const url = getInteractiveVideoShowURL(Id, deviceId);

    axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchInteractiveVideoURLSuccess(res.data && res.data.result && res.data.result.assetUrl));
        cb(null, res.data && res.data.result && res.data.result.assetUrl);
    }).catch((error) => {
        dispatch(fetchInteractiveVideoURLError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

const fetchInteractiveVideoInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    };
};

const fetchInteractiveVideoSuccess = (list) => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_SUCCESS,
        list,
    };
};

const fetchInteractiveVideoError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_ERROR,
        message,
    };
};

export const getInteractiveVideo = (id) => (dispatch) => {
    dispatch(fetchInteractiveVideoInProgress());
    const url = getInteractiveVideoURL(id);

    axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchInteractiveVideoSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchInteractiveVideoError(error.response ? error.response.data.message : error.message));
    });
};

export const setInteractiveSave = (value) => {
    return {
        type: INTERACTIVE_SAVE_SET,
        value,
    };
};

export const setQuestionType = (value) => {
    return {
        type: INTERACTIVE_QUESTION_TYPE_SET,
        value,
    };
};

export const setPlayer = (value) => {
    return {
        type: INTERACTIVE_PLAYER_SET,
        value,
    };
};

export const setCurrentInteraction = (value) => {
    return {
        type: CURRENT_INTERACTION_SET,
        value,
    };
};

export const setAccountAddress = (value) => {
    return {
        type: ACCOUNT_ADDRESS_SET,
        value,
    };
};

const addInteractiveVideoSessionInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS,
    };
};

const addInteractiveVideoSessionSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS,
        value,
    };
};

const addInteractiveVideoSessionError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_ADD_ERROR,
        message,
    };
};

export const addInteractiveVideoSession = (id, data, cb) => (dispatch) => {
    dispatch(addInteractiveVideoSessionInProgress());
    const url = addInteractiveVideoSessionURL(id);

    axios.post(url, data, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(addInteractiveVideoSessionSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(addInteractiveVideoSessionError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : error.message));
        cb(error);
    });
};

const saveInteractiveVideoAnswerInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS,
    };
};

const saveInteractiveVideoAnswerSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS,
        value,
    };
};

const saveInteractiveVideoAnswerError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR,
        message,
    };
};

export const saveInteractiveVideoAnswer = (interactiveVideoId, sessionId, data, cb) => (dispatch) => {
    dispatch(saveInteractiveVideoAnswerInProgress());
    const url = saveInteractiveVideoAnswerURL(interactiveVideoId, sessionId);

    axios.post(url, data, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(saveInteractiveVideoAnswerSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(saveInteractiveVideoAnswerError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

const completeInteractiveVideoSessionInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS,
    };
};

const completeInteractiveVideoSessionSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS,
        value,
    };
};

const completeInteractiveVideoSessionError = (message, code) => {
    return {
        type: INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR,
        message: code === 2 ? 'You have already participated!' : message,
    };
};

export const completeInteractiveVideoSession = (interactiveVideoId, sessionId, data, cb) => (dispatch) => {
    dispatch(completeInteractiveVideoSessionInProgress());
    const url = completeInteractiveVideoSessionURL(interactiveVideoId, sessionId);

    axios.post(url, data, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(completeInteractiveVideoSessionSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(completeInteractiveVideoSessionError(error.response ? error.response.data.message : error.message,
            error.response && error.response.data.error && error.response.data.error.code));
        cb(error);
    });
};

export const setSearchAsset = (value) => {
    return {
        type: SEARCH_FOR_ASSET_SET,
        value,
    };
};

export const showInteractionTime = () => {
    return {
        type: INTERACTION_TIME_SHOW,
    };
};

export const hideInteractionTime = () => {
    return {
        type: INTERACTION_TIME_HIDE,
    };
};

export const clearData = () => {
    return {
        type: CLEAR_DATA,
    };
};

export const setWindowResize = (value) => {
    return {
        type: WINDOW_RESIZE_SET,
        value,
    };
};

export const showClaimCertificateDialog = (percentage, requiredPercentage) => {
    return {
        type: CLAIM_CERTIFICATE_DIALOG_SHOW,
        percentage,
        requiredPercentage,
    };
};

export const hideClaimCertificateDialog = () => {
    return {
        type: CLAIM_CERTIFICATE_DIALOG_HIDE,
    };
};

export const setClaimCertificateLoading = (value, text) => {
    return {
        type: SET_CLAIM_CERTIFICATE_LOADING,
        value,
        text,
    };
};

export const showQuizDialog = () => {
    return {
        type: QUIZ_DIALOG_SHOW,
    };
};

export const hideQuizDialog = () => {
    return {
        type: QUIZ_DIALOG_HIDE,
    };
};
