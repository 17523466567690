import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../../utils/variables';

const Section1 = (props) => {
    return (
        <div className="section1">
            <div className="content1">
                <span>{variables[props.lang]['content1_p1']}</span>
                <span className="bold color">{variables[props.lang]['akash_edu']},</span>
                {/* <span className="bold">{variables[props.lang]['akash_network']}</span> */}
                <span>{variables[props.lang]['content1_p2']}</span>
            </div>
            <div className="content2">
                <span>{variables[props.lang]['content2_p1']}</span>
            </div>
            <div className="content3">
                <span>{variables[props.lang]['content3_p1']}</span>
                <span className="bold">{variables[props.lang]['omniflix_network']}</span>
                <span>{variables[props.lang]['content3_p2']}</span>
            </div>
        </div>
    );
};

Section1.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default withRouter(connect(stateToProps)(Section1));
