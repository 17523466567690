import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeInteraction } from '../../../utils/interaction';
import { showMessage } from '../../../actions/snackbar';
import {
    completeInteractiveVideoSession,
    hideInteractionTime,
    setCurrentInteraction,
} from '../../../actions/interactiveVideo';
import { fetchParticipant, getInteractiveVideoParticipants } from '../../../actions/participant';
import { checkNFTClaimStatus, eligibleCheck, fetchIVProjectsList, fetchNFT } from '../../../actions/myAccount';

const CountDownTimer = (props) => {
    const endCurrentSession = () => {
        props.endSession(props.session && props.session.interactive_video,
            props.session && props.session._id, {
                accountAddress: props.address,
                // signature,
            }, (error) => {
                if (error) {
                    props.showMessage('Error while ending session');
                    return;
                }
                props.getParticipants(props.session && props.session.interactive_video);

                props.fetchIVProjectsList(props.address, props.session && props.session.interactive_video, (result) => {
                    if (result && result.length) {
                        result.map((value) => {
                            if (value && value.nfts && value.nfts.length) {
                                props.fetchNFT(value.nfts);
                                if (props.address && value && value.denom && value.denom.id) {
                                    props.checkNFTClaimStatus(value.denom.id, props.address);
                                }
                            }

                            props.fetchParticipant(value.interactive_video_id, props.address, (val) => {
                                if (val && val.length && val[0] && val[0]._id) {
                                    props.eligibleCheck(value.interactive_video_id, val[0]._id);
                                }
                            });

                            return null;
                        });
                    }
                });
            });
    };

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            props.player.play();
            if (props.currentInteraction >= props.interactions.length - 1) {
                endCurrentSession();
            }

            if (removeInteraction(props.currentInteraction)) {
                props.setCurrentInteraction(props.currentInteraction + 1);
                props.hideInteractionTime();
            } else {
                props.showMessage('Failed to remove interaction');
            }
        }

        return (
            <div className="timer">
                {/* <div className="text">Remaining</div> */}
                <div className="value">{remainingTime}</div>
                {/* <div className="text">seconds</div> */}
            </div>
        );
    };

    return (
        <div className="timer-wrapper">
            <CountdownCircleTimer
                isPlaying
                colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]}
                // duration={props.interactions[props.currentInteraction] &&
                //         props.interactions[props.currentInteraction].time_limit ? props.interactions[props.currentInteraction] &&
                //         props.interactions[props.currentInteraction].time_limit : 20}
                duration={20}
                size={55}
                onComplete={() => [true, 1000]}>
                {renderTime}
            </CountdownCircleTimer>
        </div>
    );
};

CountDownTimer.propTypes = {
    checkNFTClaimStatus: PropTypes.func.isRequired,
    eligibleCheck: PropTypes.func.isRequired,
    endSession: PropTypes.func.isRequired,
    fetchIVProjectsList: PropTypes.func.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    fetchParticipant: PropTypes.func.isRequired,
    getParticipants: PropTypes.func.isRequired,
    hideInteractionTime: PropTypes.func.isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    address: PropTypes.string,
    currentInteraction: PropTypes.number,
    interactions: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.number.isRequired,
            time_limit: PropTypes.number.isRequired,
            answers: PropTypes.array,
        }),
    ),
    player: PropTypes.object,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        currentInteraction: state.interactiveVideo.currentInteraction,
        session: state.interactiveVideo.session.data,
        address: state.account.address,
        interactions: state.interactiveVideo._.interactions,
    };
};
const actionsToProps = {
    setCurrentInteraction,
    showMessage,
    hideInteractionTime,
    endSession: completeInteractiveVideoSession,
    getParticipants: getInteractiveVideoParticipants,
    fetchNFT,
    fetchParticipant,
    fetchIVProjectsList,
    checkNFTClaimStatus,
    eligibleCheck,
};

export default connect(stateToProps, actionsToProps)(CountDownTimer);
