import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Dialog, DialogContent } from '@material-ui/core';
import { hideMetaMaskNetworkDialog } from '../../../actions/navbar';
import variables from '../../../utils/variables';
import { showMessage } from '../../../actions/snackbar';
import { addInteractiveVideoSession, setAccountAddress } from '../../../actions/interactiveVideo';
import { getWeb3Instance } from '../../../contract/web3';
import { getContractInstance } from '../../../contract/contractInstance';
import { setContractInstance, setWeb3Instance } from '../../../actions/account';
import { networksList } from '../../../utils/networkList';

const NetworkDialog = (props) => {
    const [inProgress, setInProgress] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const updateWidthAndHeight = () => {
        if (window.innerWidth < 1224 && !isMobile) {
            setIsMobile(true);
        } else if (window.innerWidth > 1224 && isMobile) {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        if (window.innerWidth < 1224 && !isMobile) {
            setIsMobile(true);
        }

        window.addEventListener('resize', updateWidthAndHeight);

        return () => {
            window.removeEventListener('resize', updateWidthAndHeight);
        };
    }, []);

    const reDirect = (network) => {
        if (isMobile && window.ethereum) {
            initialize(network);

            return;
        } else if (isMobile) {
            const standalone = window && window.navigator && window.navigator.standalone;
            const userAgent = window && window.navigator &&
                window.navigator.userAgent && window.navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent);
            const ios = /iphone|ipod|ipad/.test(userAgent);

            let url = window.location.href;
            url = url.replace(/(^\w+:|^)\/\//, '');
            const subDomain = url.split('.')[0];

            if (ios && !standalone && !safari) {
                window.open(`https://metamask.app.link/dapp/${subDomain}.omniflix.tv`);
            } else {
                window.open(`https://metamask.app.link/dapp/${subDomain}.omniflix.tv`);
            }

            return;
        }

        initialize(network);
    };

    const initialize = (network) => {
        setInProgress(true);

        const isMetaMaskInstalled = () => {
            // Have to check the ethereum binding on the window object to see if it's installed
            const { ethereum } = window;
            return Boolean(ethereum && ethereum.isMetaMask);
        };

        const MetaMaskClientCheck = () => {
            // Now we check to see if MetaMask is installed
            if (!isMetaMaskInstalled()) {
                props.showMessage('Please install MetaMask!');
                handleClose();
            } else {
                handleConnect(network);
            }
        };

        MetaMaskClientCheck();
    };

    const handleConnect = (network) => {
        if (!props.web3Instance) {
            getWeb3Instance((error, instance) => {
                if (error) {
                    return;
                }

                props.setWeb3Instance(instance);
                connectToAccount(network);
            });
        }

        if (!props.contractInstance) {
            getContractInstance((error, instance) => {
                if (error) {
                    return;
                }

                props.setContractInstance(instance);
                connectToAccount(network);
            });
        } else {
            connectToAccount(network);
        }
    };

    const connectToAccount = (network) => {
        const { ethereum } = window;

        ethereum && ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
            localStorage.setItem('connector', 'm');
            localStorage.setItem('akt_edu_nc', network.code);
            if (network.params) {
                ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [network.params, res[0]],
                })
                    .then((result) => {
                    })
                    .catch(() => {
                    });
            }
            // setting redux after setting the local storage coz, code execution will be done line by line
            // if we set redux first then component won't recognize the changed value in LS, after the
            // dependent components mounts the LS will be set, which raises bug issue.
            props.setAccountAddress(res[0]);
            handleClose();

            if (props.videoId && props.sign) {
                signWithEthereumAccount(res[0]);
            }
        }).catch(() => {
            handleClose();
            showMessage('Unable to fetch account from MetaMask');
        });
    };

    const signWithEthereumAccount = (address) => {
        props.web3Instance && props.web3Instance.eth.personal.sign(
            `I am signing my one-time nonce: ${props.videoId}`,
            address,
        ).then((signature) => {
            props.addInteractiveVideoSession(props.videoId, {
                accountAddress: address,
                deviceFingerprint: props.deviceId,
                sign: {
                    signature: signature,
                },
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.player.play();
            });
        }).catch((error) => {
            props.showMessage(error.message);
        });
    };

    const handleClose = () => {
        setInProgress(false);
        if (props.videoId && props.sign) {
            return;
        }

        props.handleClose();
    };

    return (
        <Dialog
            aria-describedby="network-dialog-description"
            aria-labelledby="network-dialog-title"
            className="network_dialog"
            open={props.open}
            onClose={handleClose}>
            <DialogContent className="content">
                <p className="heading">
                    {variables[props.lang]['select_network']}
                </p>
                <div className="networks_list">
                    {inProgress
                        ? <div>Connecting..</div>
                        : networksList && networksList.length &&
                        networksList.map((network, index) => {
                            return (
                                <div
                                    key={index}
                                    className="network"
                                    onClick={() => reDirect(network)}>
                                    <div className="image">
                                        <img alt={network.name} src={network.img}/>
                                    </div>
                                    <p className="name">{network.name}</p>
                                </div>
                            );
                        })}
                </div>
            </DialogContent>
        </Dialog>
    );
};

NetworkDialog.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setContractInstance: PropTypes.func.isRequired,
    setWeb3Instance: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.bool.isRequired,
    contractInstance: PropTypes.any,
    deviceId: PropTypes.string,
    player: PropTypes.object,
    videoId: PropTypes.string,
    web3Instance: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        deviceId: state.interactiveVideo.deviceID.id,
        lang: state.language,
        open: state.navbar.metaMaskNetworkDialog,
        web3Instance: state.account.web3Instance,
        contractInstance: state.account.contractInstance,
        sign: state.account.connectDialog.sign,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    handleClose: hideMetaMaskNetworkDialog,
    showMessage,
    setAccountAddress,
    addInteractiveVideoSession,
    setWeb3Instance,
    setContractInstance,
};

export default connect(stateToProps, actionToProps)(NetworkDialog);
