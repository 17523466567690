export const INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const INTERACTIVE_VIDEOS_FETCH_ERROR = 'INTERACTIVE_VIDEOS_FETCH_ERROR';

export const DEVICE_ID_FETCH_IN_PROGRESS = 'DEVICE_ID_FETCH_IN_PROGRESS';
export const DEVICE_ID_FETCH_SUCCESS = 'DEVICE_ID_FETCH_SUCCESS';
export const DEVICE_ID_FETCH_ERROR = 'DEVICE_ID_FETCH_ERROR';

export const INTERACTIVE_VIDEO_URL_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEO_URL_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEO_URL_FETCH_SUCCESS = 'INTERACTIVE_VIDEO_URL_FETCH_SUCCESS';
export const INTERACTIVE_VIDEO_URL_FETCH_ERROR = 'INTERACTIVE_VIDEO_URL_FETCH_ERROR';

export const INTERACTIVE_VIDEO_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEO_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEO_FETCH_SUCCESS = 'INTERACTIVE_VIDEO_FETCH_SUCCESS';
export const INTERACTIVE_VIDEO_FETCH_ERROR = 'INTERACTIVE_VIDEO_FETCH_ERROR';

export const INTERACTIVE_SAVE_SET = 'INTERACTIVE_SAVE_SET';
export const INTERACTIVE_QUESTION_TYPE_SET = 'INTERACTIVE_QUESTION_TYPE_SET';
export const INTERACTIVE_PLAYER_SET = 'INTERACTIVE_PLAYER_SET';
export const CURRENT_INTERACTION_SET = 'CURRENT_INTERACTION_SET';

export const ACCOUNT_ADDRESS_SET = 'ACCOUNT_ADDRESS_SET';

export const INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS = 'INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS';
export const INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS = 'INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS';
export const INTERACTIVE_VIDEO_SESSION_ADD_ERROR = 'INTERACTIVE_VIDEO_SESSION_ADD_ERROR';

export const INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS = 'INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS = 'INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS';
export const INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR = 'INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR';

export const INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS';
export const INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR';

export const SEARCH_FOR_ASSET_SET = 'SEARCH_FOR_ASSET_SET';

export const INTERACTION_TIME_SHOW = 'INTERACTION_TIME_SHOW';
export const INTERACTION_TIME_HIDE = 'INTERACTION_TIME_HIDE';

export const CLEAR_DATA = 'CLEAR_DATA';

export const WINDOW_RESIZE_SET = 'WINDOW_RESIZE_SET';

export const IMP_INFORMATION_DIALOG_SHOW = 'IMP_INFORMATION_DIALOG_SHOW';
export const IMP_INFORMATION_DIALOG_HIDE = 'IMP_INFORMATION_DIALOG_HIDE';

export const TOKEN_GATED_CONTENT_DIALOG_SHOW = 'TOKEN_GATED_CONTENT_DIALOG_SHOW';
export const TOKEN_GATED_CONTENT_DIALOG_HIDE = 'TOKEN_GATED_CONTENT_DIALOG_HIDE';

export const CLAIM_CERTIFICATE_DIALOG_SHOW = 'CLAIM_CERTIFICATE_DIALOG_SHOW';
export const CLAIM_CERTIFICATE_DIALOG_HIDE = 'CLAIM_CERTIFICATE_DIALOG_HIDE';
export const SET_CLAIM_CERTIFICATE_LOADING = 'SET_CLAIM_CERTIFICATE_LOADING';
export const QUIZ_DIALOG_SHOW = 'QUIZ_DIALOG_SHOW';
export const QUIZ_DIALOG_HIDE = 'QUIZ_DIALOG_HIDE';
