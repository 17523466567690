import {
    SHARE_DIALOG_SHOW,
    SHARE_DIALOG_HIDE,
} from '../constants/home';

export const showShareDialog = (value) => {
    return {
        type: SHARE_DIALOG_SHOW,
        value,
    };
};

export const hideShareDialog = () => {
    return {
        type: SHARE_DIALOG_HIDE,
    };
};
