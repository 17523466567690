import React, { useState } from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetaMaskIcon from '../../../assets/logos/meta_leap.svg';
import { hideConnectDialog } from '../../../actions/navbar';
import { setAccountAddress } from '../../../actions/interactiveVideo';
import { initializeMetaMask } from '../../../actions/account/metaMask';

const MetaMaskLeapSnaps = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeWallet = () => {
        setInProgress(true);
        props.initializeMetaMask((address, retry) => {
            setInProgress(false);
            if (retry) {
                initializeWallet();
                return;
            }

            props.setAccountAddress(address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_address', address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_connector', 'mml');
            props.hideConnectDialog();
        });
    };

    const connectInProgress = inProgress;

    return (
        <Button
            className="wallet_button metamask_button"
            disabled={connectInProgress}
            variant="contained"
            onClick={initializeWallet}>
            <img alt="logo" src={MetaMaskIcon}/>
            {connectInProgress
                ? <p>Connecting...</p>
                : <p>Metamask <span>(Leap Snaps)</span></p>}
        </Button>
    );
};

MetaMaskLeapSnaps.propTypes = {
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeMetaMask: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {
    hideConnectDialog,
    setAccountAddress,
    initializeMetaMask,
};

export default connect(stateToProps, actionToProps)(MetaMaskLeapSnaps);
