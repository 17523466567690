import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import {
    CONTRACT_INSTANCE_SET,
    PUBLIC_KEY_SET,
    TO_ADDRESS_SET,
    TOKENS_TO_SEND_SET,
    WEB3_INSTANCE_SET,
} from '../../constants/account';
import IBCTokens from './IBCTokens';
import { ACCOUNT_ADDRESS_SET } from '../../constants/interactiveVideo';
import {
    CONNECT_ACCOUNT_DIALOG_HIDE,
    CONNECT_ACCOUNT_DIALOG_SHOW,
    NETWORKS_DIALOG_HIDE,
    NETWORKS_DIALOG_SHOW,
} from '../../constants/navbar';

const address = (state = '', action) => {
    if (action.type === ACCOUNT_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const pubKey = (state = '', action) => {
    if (action.type === PUBLIC_KEY_SET) {
        return action.value;
    }

    return state;
};

const connectDialog = (state = {
    open: false,
    sign: false,
}, action) => {
    switch (action.type) {
    case CONNECT_ACCOUNT_DIALOG_SHOW:
        return {
            open: true,
            sign: action.sign,
        };
    case CONNECT_ACCOUNT_DIALOG_HIDE:
    case NETWORKS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const networkDialog = (state = false, action) => {
    switch (action.type) {
    case NETWORKS_DIALOG_SHOW:
        return true;
    case NETWORKS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const toAddress = (state = '', action) => {
    if (action.type === TO_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const tokensToSend = (state = '', action) => {
    if (action.type === TOKENS_TO_SEND_SET) {
        return action.value;
    }

    return state;
};

const web3Instance = (state = '', action) => {
    if (action.type === WEB3_INSTANCE_SET) {
        return action.value;
    }

    return state;
};

const contractInstance = (state = '', action) => {
    if (action.type === CONTRACT_INSTANCE_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    wallet,
    bc: BCDetails,
    ibc: IBCTokens,
    address,
    pubKey,
    connectDialog,
    networkDialog,
    toAddress,
    tokensToSend,
    web3Instance,
    contractInstance,
});
