import { combineReducers } from 'redux';
import { BID_AMOUNT_SET, MEMO_SET } from '../constants/payment';

const memo = (state = '', action) => {
    if (action.type === MEMO_SET) {
        return action.value;
    }

    return state;
};

const bidAmount = (state = '', action) => {
    if (action.type === BID_AMOUNT_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    memo,
    bidAmount,
});
