import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Icon from '../../../components/Icon';
import * as PropTypes from 'prop-types';

const VolumeButton = (props) => {
    const [mute, setMute] = useState(false);
    const handleVolume = () => {
        props.player.muted(!mute);
        setMute(!mute);
    };

    return (
        <Button
            className="control_button volume_button"
            variant="outlined"
            onClick={handleVolume}>
            {mute
                ? <Icon className="mute" icon="mute"/>
                : <Icon className="volume" icon="volume"/>}
        </Button>
    );
};

VolumeButton.propTypes = {
    player: PropTypes.object,
};

export default VolumeButton;
