import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideClaimCertificateDialog } from '../../../actions/interactiveVideo';
import { ReactComponent as CloseIcon } from '../../../assets/closeIcon.svg';
import { Button, IconButton } from '@mui/material';
import ImageOnLoad from '../../../components/ImageOnLoad';
import { mediaReference } from '../../../utils/ipfs';
import thumbnail from '../../../assets/dummy/course_thumb.png';
import CheckedIcon from '../../../assets/checked.png';
import CircularProgress from '../../../components/CircularProgress/index';
import variables from '../../../utils/variables';

const DirectClaim = (props) => {
    const [minting, setMinting] = React.useState(false);
    const handleExplore = () => {
        props.history.push('/courses');
        props.handleClose();
        props.setShowCertificate(false);
    };

    const handleClaim = () => {
        props.handleClaim(props.videoId, props.projectId && props.projectId._id);
        setMinting(true);
        props.setShowCertificate(false);
    };

    return (
        <div className="direct_claim">
            {props.success
                ? <div className="success">
                    <img alt="success" src={CheckedIcon}/>
                    <p>{variables[props.lang].nft_claimed_successfully}</p>
                </div>
                : <div className="heading_title">
                    <p>{variables[props.lang].claim_nft}</p>
                    <IconButton onClick={props.handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>}
            <div className="image_section">
                {minting && !props.success
                    ? <CircularProgress />
                    : null}
                {props.projectId && Object.keys(props.projectId).length && props.projectId.nft_meta
                    ? <ImageOnLoad
                        alt="thumbnail"
                        className="course_thumbnail list_section2"
                        preview={mediaReference(props.projectId.nft_meta.preview_uri) || thumbnail}
                        src={mediaReference(props.projectId.nft_meta.media_uri)}/>
                    : null}
            </div>
            {props.success
                ? <Button onClick={handleExplore}>
                    {variables[props.lang].explore_courses}
                </Button>
                : <Button onClick={handleClaim}>
                    {minting && !props.success
                        ? <>{variables[props.lang].minting}</>
                        : <>{variables[props.lang].claim_now}</>}
                </Button>}
        </div>
    );
};

DirectClaim.propTypes = {
    address: PropTypes.string.isRequired,
    handleClaim: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.func.isRequired,
    mintInProgress: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
    videoId: PropTypes.string.isRequired,
    setShowCertificate: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        list: state.myAccount.ivProjectsList.value,
        mintInProgress: state.myAccount.nftMint.inProgress,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideClaimCertificateDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(DirectClaim));
