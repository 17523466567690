import {
    FETCH_COURSE_DETAILS_ERROR,
    FETCH_COURSE_DETAILS_IN_PROGRESS,
    FETCH_COURSE_DETAILS_SUCCESS,
} from '../constants/courses';
import { combineReducers } from 'redux';

const courseDetails = (state = {
    result: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_COURSE_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_COURSE_DETAILS_SUCCESS:
        return {
            result: action.value,
            inProgress: false,
        };
    case FETCH_COURSE_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    courseDetails,
});
