import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInteractiveVideoParticipants } from '../../../actions/participant';
import LeaderboardTable from './Table';

const LeaderBoard = (props) => {
    return (
        <div className="leader_board">
            <LeaderboardTable videoId={props.videoId}/>
        </div>
    );
};

LeaderBoard.propTypes = {
    getParticipants: PropTypes.func.isRequired,
    videoId: PropTypes.string.isRequired,
    participants: PropTypes.shape({
        inProgress: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            list: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    questions_answered: PropTypes.number,
                    total_questions: PropTypes.number,
                    account_address: PropTypes.string,
                    correct_answers: PropTypes.number,
                }),
            ),
        }),
    }),
};

const stateToProps = (state) => {
    return {
        participants: state.participant.list,
    };
};

const actionsToProps = {
    getParticipants: getInteractiveVideoParticipants,
};

export default connect(stateToProps, actionsToProps)(LeaderBoard);
