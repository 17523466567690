import { combineReducers } from 'redux';
import {
    SHARE_DIALOG_SHOW,
    SHARE_DIALOG_HIDE,
} from '../constants/home';

const shareDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SHARE_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
        };
    case SHARE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    shareDialog,
});
