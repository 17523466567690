import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { getInteractiveVideoParticipants, setParticipantsSearch } from '../../../actions/participant';
import './index.css';
import { Button, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/interactiveVideo/search_icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/closeIcon.svg';

const SearchTextField = (props) => {
    const [time, setTime] = useState(null);

    const handleChange = (e) => {
        const value = e.target.value;
        props.onChange(value);
        if (time) {
            clearInterval(time);
        }
        const interval = setTimeout(() => {
            props.getParticipants(props.videoId, props.skip, props.limit, value, props.sortBy, props.order);
        }, 1000);

        setTime(interval);
    };

    const handleClear = () => {
        props.onChange('');
    };

    return (
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Button className="clear_button" onClick={handleClear}>
                            <CloseIcon />
                        </Button>
                    </InputAdornment>
                ),
            }}
            className="text_field"
            id="search"
            name="search"
            placeholder="Search address"
            value={props.value}
            variant="outlined"
            onChange={handleChange}/>
    );
};

SearchTextField.propTypes = {
    getParticipants: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,

    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.participant.participantsSearch.value,

        skip: state.participant.list.skip,
        limit: state.participant.list.limit,
        count: state.participant.list.count,
        searchKey: state.participant.list.searchKey,
        order: state.participant.list.order,
    };
};

const actionsToProps = {
    onChange: setParticipantsSearch,
    getParticipants: getInteractiveVideoParticipants,
};

export default connect(stateToProps, actionsToProps)(SearchTextField);
