import { makeStyles, TextField as MaterialTextField } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const TextField = (props) => {
    const onChange = (e) => props.onChange(e.target.value);

    return (
        <MaterialTextField
            InputProps={props.inputProps ? props.inputProps : null}
            autoFocus={props.autoFocus ? props.autoFocus : false}
            className={ClassNames(useStyles().root, 'text_field', props.className ? props.className : '', props.margin ? 'field_margin'
                : '')}
            disabled={props.disable}
            // helperText={props.name === 'email' || props.name === 'signup_email'
            //     ? props.valid && props.value.length !== 0 ? '' : 'Please enter valid email address' : ''}
            id={props.id}
            inputRef={props.inputRef ? props.inputRef : null}
            label={props.label}
            margin="normal"
            multiline={props.multiline ? props.multiline : false}
            name={props.name}
            placeholder={props.placeholder ? props.placeholder : ''}
            required={props.required ? props.required : false}
            rows={props.multiline ? 3 : null}
            type={props.type ? props.type : 'text'}
            value={props.value}
            variant={props.variant}
            onBlur={props.onBlur}
            onChange={onChange}
            onFocus={props.onFocus}
            onKeyDown={props.onKeyDown}/>
    );
};

TextField.propTypes = {
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disable: PropTypes.bool,
    handleClickShowPassword: PropTypes.func,
    handleCloseSearch: PropTypes.func,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    inputRef: PropTypes.object,
    label: PropTypes.string,
    margin: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showPassword: PropTypes.bool,
    type: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.string,
    variant: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
};

export default TextField;
