import React, { useEffect } from 'react';
import { getInteractiveVideos } from '../../actions/interactiveVideo';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './index.css';
import viewIcon from '../../assets/view.png';
import Resize from './Resize';
import CircularProgress from '../../components/CircularProgress';
import { DEFAULT_LAZY_FETCH_HEIGHT, DEFAULT_LIMIT, DEFAULT_SEARCH_KEY, DEFAULT_SKIP } from '../../constants/url';

const InteractiveVideos = (props) => {
    const { fetchVideos } = props;

    useEffect(() => {
        fetchVideos(DEFAULT_SEARCH_KEY, DEFAULT_SKIP, DEFAULT_LIMIT);
        const elements = document.getElementById('scroll-bar');
        for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].style.overflow = 'hidden';
        }

        return () => {
            for (let i = 0, len = elements.length; i < len; i++) {
                elements[i].style.overflow = 'unset';
            }
        };
    }, [fetchVideos]);

    const handleScroll = (e) => {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((props.videos.length < props.total) &&
            ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
            !(props.inProgress)) {
            fetchVideos(props.search, props.skip + DEFAULT_LIMIT, props.limit);
        }
    };

    const handleClick = (id) => {
        props.history.push(`/interactive-videos/${id}`);
    };

    const length = props.videos && props.videos.length;
    const videos = props.videos && props.videos.filter((val) => val.asset);

    const a = [];
    const b = [];
    const c = [];

    if (length) {
        for (let i = 0; i < length; i = i + props.size) {
            if (videos[i]) {
                a.push(videos[i]);
            }
            if (videos[i + 1] && props.size > 1) {
                b.push(videos[i + 1]);
            }
            if (videos[i + 2] && props.size > 2) {
                c.push(videos[i + 2]);
            }
        }
    }

    return (
        <div className="videos_class scroll_bar" onScroll={handleScroll}>
            <Resize/>
            {props.inProgress && props.videos && props.videos.length === 0
                ? <CircularProgress/>
                : <div className="row">
                    <div className="videos_sections">
                        {a.map((item) =>
                            <div
                                key={item.asset && item.asset._id}
                                className="video_section"
                                onClick={() => handleClick(item._id)}>
                                <div className="view_hover">
                                    <img alt="view" src={viewIcon}/>
                                    <div>View</div>
                                </div>
                                <img
                                    alt="thumbnail"
                                    className="video_section_image"
                                    src={item.asset && item.asset.thumbnail && item.asset.thumbnail.horizontal
                                        ? item.asset.thumbnail.horizontal : logo}/>
                                <div className="video_name">
                                    {item.asset.name}
                                </div>
                            </div>)}
                    </div>
                    {b && b.length
                        ? <div className="videos_sections">
                            {b.map((item) =>
                                <div
                                    key={item.asset && item.asset._id}
                                    className="video_section"
                                    onClick={() => handleClick(item._id)}>
                                    <div className="view_hover">
                                        <img alt="view" src={viewIcon}/>
                                        <div>View</div>
                                    </div>
                                    <img
                                        alt="thumbnail"
                                        className="video_section_image"
                                        src={item.asset && item.asset.thumbnail && item.asset.thumbnail.horizontal
                                            ? item.asset.thumbnail.horizontal : logo}/>
                                    <div className="video_name">
                                        {item.asset.name}
                                    </div>
                                </div>)}
                        </div>
                        : null}
                    {c && c.length
                        ? <div className="videos_sections">
                            {c.map((item) =>
                                <div
                                    key={item.asset && item.asset._id}
                                    className="video_section"
                                    onClick={() => handleClick(item._id)}>
                                    <div className="view_hover">
                                        <img alt="view" src={viewIcon}/>
                                        <div>View</div>
                                    </div>
                                    <img
                                        alt="thumbnail"
                                        className="video_section_image"
                                        src={item.asset && item.asset.thumbnail && item.asset.thumbnail.horizontal
                                            ? item.asset.thumbnail.horizontal : logo}/>
                                    <div className="video_name">
                                        {item.asset.name}
                                    </div>
                                </div>)}
                        </div>
                        : null}
                </div>}
            {props.inProgress && props.videos && props.videos.length
                ? <CircularProgress/> : null}
        </div>
    );
};

InteractiveVideos.propTypes = {
    fetchVideos: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            asset: PropTypes.shape(
                {
                    _id: PropTypes.string.isRequired,
                    thumbnail: PropTypes.shape({
                        horizontal: PropTypes.string.isRequired,
                    }),
                },
            ).isRequired,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        size: state.interactiveVideo.windowSize,
        videos: state.interactiveVideo.list.value,
        inProgress: state.interactiveVideo.inProgress,
        limit: state.interactiveVideo.list.limit,
        search: state.interactiveVideo.list.search,
        skip: state.interactiveVideo.list.skip,
        total: state.interactiveVideo.list.total,
    };
};

const actionsToProps = {
    fetchVideos: getInteractiveVideos,
};

export default withRouter(connect(stateToProps, actionsToProps)(InteractiveVideos));
