import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';

const InteractionMarks = (props) => {
    return (
        <div className="interaction_marks">
            {props.interactions && props.interactions.length > 0 &&
                props.interactions.map((value, index) => {
                    if (!props.duration) {
                        return null;
                    }

                    return <span
                        key={index}
                        className="mark"
                        style={{ left: `${(value.start_at / props.duration) * 100}%` }}/>;
                })}
        </div>
    );
};

InteractionMarks.propTypes = {
    duration: PropTypes.number,
    interactions: PropTypes.array,
    // player: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
    };
};

export default connect(stateToProps)(InteractionMarks);
