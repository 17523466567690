import Web3 from 'web3';

export const getWeb3Instance = (cb) => {
    let web3;

    if (window.ethereum) {
        try {
            web3 = new Web3(window.ethereum);
            cb(null, web3);
        } catch (e) {
            const errorMessage = 'Please, allow MetaMask access to your account';
            cb(errorMessage);

            alert('Please, allow MetaMask access to your account');
        }
    } else if (typeof window !== 'undefined' && typeof window.web3 !== 'undefined') {
        web3 = new Web3(window.web3.currentProvider);
        cb(null, web3);
    } else {
        const errorMessage = 'Non-Ethereum browser detected. You should consider trying MetaMask!';
        cb(errorMessage);
        alert(
            'Non-Ethereum browser detected. You should consider trying MetaMask!',
        );
    }
};
