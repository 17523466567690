import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GaugeChart from 'react-gauge-chart';
import { Button } from '@mui/material';
import {
    addInteractiveVideoSession,
    clearData,
    fetchDeviceID,
    getInteractiveVideo,
    hideClaimCertificateDialog,
    setClaimCertificateLoading,
    setCurrentInteraction,
} from '../../../actions/interactiveVideo';
import { withRouter } from 'react-router';
import { floatDecimals } from '../../../utils/numbers';
import variables from '../../../utils/variables';
import { config } from '../../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign } from '../../../helper';
import { showMessage } from '../../../actions/snackbar';
import { fetchCollectionMyNFTs } from '../../../actions/gatedAccess';
import { walletConnectSign } from '../../../actions/account/wallet';

const Chart = (props) => {
    const handleClaim = () => {
        props.setClaimCertificateLoading(true, variables[props.lang].getting_certificate_ready);
        // props.setShowCertificate(true);
        props.handleClaim(props.videoId, props.projectId);
    };

    const handleRetry = () => {
        const id = props.match && props.match.params.id;
        props.getVideo(id);
        props.player.currentTime(0);
        addSession(props.video);
    };

    const addSession = (video) => {
        if (!props.deviceID) {
            props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    handleLeap(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                signWithCosmosAccount(props.deviceID, video);
            } else if (lockType === 'wc') {
                walletConnectSign(props.deviceID, video);
            } else if (lockType === 'cs') {
                handleCosmostation(props.deviceID, video);
            } else if (lockType === 'leap') {
                handleLeap(props.deviceID, video);
            }
        }
    };

    const handleCosmostation = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, props.address, (error, result) => {
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.setCurrentInteraction(0);
                props.handleClose();
                props.player.play();
            });
        });
    };

    const handleLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.setCurrentInteraction(0);
                props.handleClose();
                props.player.play();
            });
        });
    };

    const signWithCosmosAccount = (deviceID, video) => {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.setCurrentInteraction(0);
                props.handleClose();
                props.player.play();
            });
        });
    };

    const walletConnectSign = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        props.walletConnectSign(props.walletConnector, tx, props.address, (result) => {
            if (result) {
                props.addInteractiveVideoSession(video._id, {
                    accountAddress: props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // props.showMessage('Error while adding session');
                        return;
                    }

                    props.setCurrentInteraction(0);
                    props.handleClose();
                    props.player.play();
                });
            }
        });
    };

    let percentage = props.percentage / 100;
    percentage = floatDecimals(percentage);

    let percentage1 = props.percentage;
    percentage1 = floatDecimals(percentage1);

    return (
        <div className="chart">
            <GaugeChart
                hideText
                arcWidth={0.3}
                colors={['#FA5757']}
                id="gauge-chart1"
                needleBaseColor="#FEE"
                needleColor="#FEE"
                nrOfLevels={1}
                percent={percentage}
            />
            <p>{variables[props.lang].you_have_scored}</p>
            <p className="percentage">{percentage1}<span>%</span></p>
            {props.percentage && props.requiredPercentage && (props.percentage > props.requiredPercentage || props.percentage === props.requiredPercentage) &&
                props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_claimable
                ? <Button onClick={() => handleClaim()}>{variables[props.lang].claim_certificate}</Button>
                // : props.percentage && !props.requiredPercentage && props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_claimable
                //     ? <Button onClick={() => handleClaim()}>{variables[props.lang].claim_certificate}</Button>
                : <Button onClick={handleRetry}>{variables[props.lang].retry_quiz}</Button>}
        </div>
    );
};

Chart.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    collectionMyNFTs: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    getVideo: PropTypes.func.isRequired,
    handleClaim: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    percentage: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    requiredPercentage: PropTypes.string.isRequired,
    setClaimCertificateLoading: PropTypes.func.isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    setShowCertificate: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    videoId: PropTypes.string.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    player: PropTypes.object,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        percentage: state.interactiveVideo.claimCertificateDialog.percentage,
        requiredPercentage: state.interactiveVideo.claimCertificateDialog.requiredPercentage,
        player: state.interactiveVideo.player,
        lang: state.language,
        collectionMyNFTs: state.gatedAccess.collectionMyNFTs.result,
        deviceID: state.interactiveVideo.deviceID.id,
        walletConnector: state.account.wallet.connection.walletConnector,
        session: state.interactiveVideo.session.data,
    };
};

const actionToProps = {
    handleClose: hideClaimCertificateDialog,
    setClaimCertificateLoading,
    getVideo: getInteractiveVideo,
    setCurrentInteraction,
    clearData,
    addInteractiveVideoSession,
    showMessage,
    fetch: fetchCollectionMyNFTs,
    fetchDeviceID,
    walletConnectSign,
};

export default withRouter(connect(stateToProps, actionToProps)(Chart));
