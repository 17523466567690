import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import './index.css';
import Logo from '../../../assets/home/akash_logo.svg';
import { Button } from '@mui/material';
import { config } from '../../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign, metaMaskSign } from '../../../helper';
import {
    addInteractiveVideoSession,
    fetchDeviceID,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    showIMPInformationDialog,
    showTokenGatedContentDialog,
} from '../../../actions/interactiveVideo';
import { showMessage } from '../../../actions/snackbar';
import { showConnectAccountDialog, showConnectDialog } from '../../../actions/navbar';
import { fetchCollectionMyNFTs } from '../../../actions/gatedAccess';
import { walletConnectSign, aminoSignTxMetaMask } from '../../../actions/account/wallet';
import { withRouter } from 'react-router-dom';
import variables from '../../../utils/variables';
import SuccessIcon from '../../../assets/home/success.svg';
import CloseIcon from '../../../assets/home/close.svg';

const RegisterSection = (props) => {
    const [handleDialog, setHandleDialog] = useState(false);
    const [registerSuceessDialog, setRegisterSuccessDialog] = useState(true);

    const handleRegisterSuccessSection = () => {
        setRegisterSuccessDialog(false);
        localStorage.setItem(`register_success_section_${props.address}`, 'false');
    };

    useEffect(() => {
        const elements = document.getElementById('scroll-bar');
        for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].style.overflow = 'hidden';
        }

        return () => {
            for (let i = 0, len = elements.length; i < len; i++) {
                elements[i].style.overflow = 'unset';
            }
        };
    }, []);

    useEffect(() => {
        if (props.address && props.address !== '' && handleDialog) {
            const registration = props.list && props.list.length && props.list.find((val) => val && val.akash_edu_config && val.akash_edu_config.is_admission);
            handleVideo(registration);
        } else if (!props.address || props.address === '') {
            setHandleDialog(false);
        }
    }, [props.address]);

    const handleVideo = (video) => {
        if (!props.address) {
            props.showConnectDialog(false);
            setHandleDialog(true);
            return;
        }

        if (video && video.akash_edu_config && !video.akash_edu_config.denom_id) {
            addSession(video);
            return;
        }

        if (video.akash_edu_config && video.akash_edu_config.is_interactive) {
            handleInteractive(video);
        } else if (video.akash_edu_config) {
            handleVerify(video);
        }
    };

    const handleVerify = (video) => {
        if (!props.address) {
            props.showMessage('Connect Account');
            return;
        }

        props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            props.fetch(video.akash_edu_config.denom_id, props.address, (result) => {
                if (result && result.length) {
                    props.history.push(`/videos/${video._id}`);
                    props.hideTokenGatedContentDialog();
                }
            });
        }
    };

    const handleInteractive = (video) => {
        if (!props.address) {
            // props.showConnectAccountDialog(true);
            props.showMessage('Connect Account');
            return;
        }

        props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            props.fetch(video.akash_edu_config.denom_id, props.address, (result) => {
                if (result && result.length) {
                    props.hideTokenGatedContentDialog();
                    addSession(video);
                }
            });
        }
    };

    const addSession = (video) => {
        if (!props.deviceID) {
            props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    handleLeap(deviceID, video);
                } else if (lockType === 'mml') {
                    handleMetaMaskLeap(deviceID, video);
                } else if (lockType === 'mmc') {
                    handleMetaMaskCosmos(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                signWithCosmosAccount(props.deviceID, video);
            } else if (lockType === 'wc') {
                walletConnectSign(props.deviceID, video);
            } else if (lockType === 'cs') {
                handleCosmostation(props.deviceID, video);
            } else if (lockType === 'leap') {
                handleLeap(props.deviceID, video);
            } else if (lockType === 'mml') {
                handleMetaMaskLeap(props.deviceID, video);
            } else if (lockType === 'mmc') {
                handleMetaMaskCosmos(props.deviceID, video);
            }
        }
    };

    const handleCosmostation = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, props.address, (error, result) => {
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const handleLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const signWithCosmosAccount = (deviceID, video) => {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const walletConnectSign = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        props.walletConnectSign(props.walletConnector, tx, props.address, (result) => {
            if (result) {
                props.addInteractiveVideoSession(video._id, {
                    accountAddress: props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // props.showMessage('Error while adding session');
                        return;
                    }

                    handleInteract(video);
                });
            }
        });
    };

    const handleMetaMaskLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        props.aminoSignTxMetaMask(tx, props.address, (result) => {
            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const handleMetaMaskCosmos = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        metaMaskSign(tx, props.address, (error, result) => {
            if (error) {
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const handleInteract = (video) => {
        props.history.push(`/interactive-videos/${video && video._id}`);
    };

    const registration = props.list && props.list.length && props.list.find((val) => val && val.akash_edu_config && val.akash_edu_config.is_admission);

    return (
        <>
            {Object.keys(props.nftSList).length && registerSuceessDialog && localStorage.getItem(`register_success_section_${props.address}`) !== 'false'
                ? <div className="register_success_section">
                    <div className="inner_container">
                        <div className="left_section">
                            <Button className="close_button" onClick={handleRegisterSuccessSection}>
                                <img alt="Close Icon" src={CloseIcon}/>
                            </Button>
                            <div className="image_field">
                                <img alt="Success Icon" src={SuccessIcon}/>
                            </div>
                            <p>{variables[props.lang].course_access_success}</p>
                        </div>
                        <div className="right_section">
                            <Button onClick={() => props.history.push('/courses')}>{variables[props.lang].learn_now}</Button>
                            <Button className="close_button" onClick={handleRegisterSuccessSection}>
                                <img alt="Close Icon" src={CloseIcon}/>
                            </Button>
                        </div>
                    </div>
                </div>
                : null}
            <div className="register_section">
                <div className="inner_section">
                    <div className="content_section">
                        <p>{variables[props.lang].registration_h1}</p>
                        <p><span>{variables[props.lang].akash_edu}</span>{variables[props.lang].registration_p1}</p>
                        {Object.keys(props.nftSList).length
                            ? <Button onClick={() => props.history.push('/courses')}>{variables[props.lang].learn_now}</Button>
                            : <Button onClick={() => handleVideo(registration)}>{variables[props.lang].register_now}</Button>}
                    </div>
                    <div className="image_section">
                        <img alt="logo" src={Logo}/>
                    </div>
                </div>
            </div>
        </>
    );
};

RegisterSection.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    aminoSignTxMetaMask: PropTypes.func.isRequired,
    courses: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    hideIMPInformationDialog: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    nftSList: PropTypes.object.isRequired,
    showConnectAccountDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showIMPInformationDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        address: state.account.address,
        deviceID: state.interactiveVideo.deviceID.id,
        size: state.interactiveVideo.windowSize,
        walletConnector: state.account.wallet.connection.walletConnector,
        list: state.navbar.videosList.value,
        courses: state.navbar.videosList.courses,
        nftSList: state.myAccount.userNFTs.result,
    };
};

const actionsToProps = {
    addInteractiveVideoSession,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
    showIMPInformationDialog,
    showMessage,
    showConnectAccountDialog,
    fetch: fetchCollectionMyNFTs,
    fetchDeviceID,
    walletConnectSign,
    showConnectDialog,
    aminoSignTxMetaMask,
};

export default withRouter(connect(stateToProps, actionsToProps)(RegisterSection));
