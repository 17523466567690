import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideIMPInformationDialog } from '../../../actions/interactiveVideo';
import { Button, IconButton, Checkbox, Dialog, FormControlLabel } from '@material-ui/core';
import React from 'react';
import variables from '../../../utils/variables';
import './index.css';
import { withRouter } from 'react-router-dom';
import backgroundPattern from '../../../assets/background_pattern_decorative.png';
import { ReactComponent as InfoCircle } from '../../../assets/info-circle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/closeIcon.svg';

const IMPInformationDialog = (props) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleInteract = () => {
        props.history.push(`/interactive-videos/${props.value._id}`);
        props.handleClose();
    };

    return (
        <Dialog
            aria-describedby="account-connect-dialog-description"
            aria-labelledby="account-connect-dialog-title"
            className="imp_info_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="imp_info_section">
                <div className="icon_section">
                    <img alt="background_pattern" src={backgroundPattern}/>
                    <InfoCircle/>
                    <IconButton onClick={props.handleClose} ><CloseIcon/></IconButton>
                </div>
                <div className="content1">
                    <p>{variables[props.lang].imp_info}</p>
                    <p>{variables[props.lang].complete}{props.value && props.value.akash_edu_config && props.value.akash_edu_config.course_name
                        ? <span>{` "${props.value.akash_edu_config.course_name}" `}</span> : null} {variables[props.lang].dialog_section3}</p>
                </div>
                <div className="content2">
                    <FormControlLabel
                        control={<Checkbox checked={checked} onChange={handleChange}/>}
                        label={variables[props.lang]['dialog_section6']}/>
                </div>
                <div className="content3">
                    <Button
                        className="take_quiz"
                        disabled={!checked}
                        onClick={handleInteract}>
                        {variables[props.lang]['take_quiz']}
                    </Button>
                </div>
                <p className="content4">{variables[props.lang]['dialog_section5']}</p>
            </div>
        </Dialog>
    );
};

IMPInformationDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.interactiveVideo.impInformationDialog.open,
        value: state.interactiveVideo.impInformationDialog.value,
    };
};

const actionToProps = {
    handleClose: hideIMPInformationDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(IMPInformationDialog));
