import React from 'react';
import * as PropTypes from 'prop-types';
import { setWindowResize } from '../../actions/interactiveVideo';
import { connect } from 'react-redux';

const Resize = (props) => {
    React.useEffect(() => {
        updateWidthAndHeight();

        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    const updateWidthAndHeight = () => {
        if (window.innerWidth < 426 && props.size !== 1) {
            props.setWindowResize(1);
        } else if (window.innerWidth > 426 && window.innerWidth < 1025 && props.size !== 2) {
            props.setWindowResize(2);
        } else if (window.innerWidth > 1025 && props.size !== 3) {
            props.setWindowResize(3);
        }
    };

    return null;
};

Resize.propTypes = {
    setWindowResize: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        size: state.interactiveVideo.windowSize,
    };
};

const actionsToProps = {
    setWindowResize,
};

export default connect(stateToProps, actionsToProps)(Resize);
