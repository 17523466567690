import {
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR,
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_SUCCESS,
    PARTICIPANT_FETCH_ERROR,
    PARTICIPANT_FETCH_IN_PROGRESS,
    PARTICIPANT_FETCH_SUCCESS,
    PARTICIPANTS_SEARCH_SET,
} from '../constants/participant';
import { combineReducers } from 'redux';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../constants/url';
import { DISCONNECT_SET } from '../constants/account';

const list = (state = {
    inProgress: false,
    data: {},
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            data: action.list,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const participant = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PARTICIPANT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PARTICIPANT_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.interactiveVideoId] = action.list;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case PARTICIPANT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const participantsSearch = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PARTICIPANTS_SEARCH_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    list,
    participant,
    participantsSearch,
});
