export const config = {
    API_URL: 'https://staging-api.omniflix.studio',
    RPC_URL: 'https://rpc.flixnet-4.omniflix.network',
    REST_URL: 'https://rest.flixnet-4.omniflix.network',
    DATA_LAYER: 'https://data-layer-f4.omniflix.studio',
    CHAIN_ID: 'flixnet-4',
    CHAIN_NAME: 'OmniFlix FlixNet-4',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    AVG_GAS_STEP: 0.010,
    COSMOSTAION: 'omniflix',
};

export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_TOTAL = 20;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
export const MARKETPLACE_URL = 'https://f4.omniflix.market';
export const IPFS_REFERENCE_PATH = 'ipfs://';

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
    META_MASK: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
};

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: 0.001,
                average: 0.0025,
                high: 0.025,
            },
        },
    ],
    coinType: 118,
    features: ['ibc-transfer'],
};
