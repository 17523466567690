export const list = {
    omniflix: {
        chainId: 'omniflixhub-1',
        chainName: 'OmniFlix Hub',
        coinDenom: 'FLIX',
        coinMinimalDenom: 'uflix',
        coinDecimals: 6,
        prefix: 'omniflix',
        rpc: 'https://rpc.omniflix.network',
        rest: 'https://rest.omniflix.network',
    },
    sentinel: {
        chainId: 'sentinelhub-2',
        chainName: 'Sentinel',
        coinDenom: 'DVPN',
        coinMinimalDenom: 'udvpn',
        coinDecimals: 6,
        prefix: 'sent',
        rpc: 'https://rpc-sentinel-ia.cosmosia.notional.ventures',
        rest: 'https://api-sentinel-ia.cosmosia.notional.ventures',
    },
    akash: {
        chainId: 'akashnet-2',
        chainName: 'Akash',
        coinDenom: 'AKT',
        coinMinimalDenom: 'uakt',
        coinDecimals: 6,
        prefix: 'akash',
        rpc: 'https://rpc.akashnet.omniflix.co',
        rest: 'https://api.akashnet.omniflix.co',
    },
    cosmos: {
        chainId: 'cosmoshub-4',
        chainName: 'Cosmos',
        coinDenom: 'ATOM',
        coinMinimalDenom: 'uatom',
        coinDecimals: 6,
        prefix: 'cosmos',
        rpc: 'https://rpc.cosmos.omniflix.co',
        rest: 'https://lcd-cosmoshub.keplr.app',
    },
    iris: {
        chainId: 'irishub-1',
        chainName: 'IRIS Hub',
        coinDenom: 'IRIS',
        coinMinimalDenom: 'uiris',
        coinDecimals: 6,
        prefix: 'iris',
        rpc: 'https://rpc-iris.keplr.app',
        rest: 'https://lcd-iris.keplr.app',
    },
    osmosis: {
        chainId: 'osmosis-1',
        chainName: 'Osmosis',
        coinDenom: 'OSMO',
        coinMinimalDenom: 'uosmo',
        coinDecimals: 6,
        prefix: 'osmo',
        rpc: 'https://rpc-osmosis-ia.cosmosia.notional.ventures',
        rest: 'https://api-osmosis-ia.cosmosia.notional.ventures',
    },
    juno: {
        chainId: 'juno-1',
        chainName: 'Juno Mainnet',
        coinDenom: 'JUNO',
        coinMinimalDenom: 'ujuno',
        coinDecimals: 6,
        prefix: 'juno',
        rpc: 'https://rpc.juno.omniflix.co',
        rest: 'https://api.juno.omniflix.co',
    },
};

export const codes = {
    flix: 'omniflix',
    c: 'cosmos',
    a: 'akash',
    s: 'sentinel',
    i: 'iris',
    o: 'osmosis',
    j: 'juno',
};

export const metaMaskCodes = {
    me: 'ethereum',
    mp: 'polygon',
    mb: 'binance',
    mokx: 'OKX',
};

export const signType = {
    flix: 'omniflix/MsgSign',
    a: 'akash/MsgSign',
    s: 'sentinel/MsgSign',
    c: 'cosmos/MsgSign',
};

export const networkImages = [
    'omniflix',
    'cosmos',
    'akash',
    'sent',
    'juno',
    'iaa',
    'osmo',
    '0x',
];
