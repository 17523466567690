import React from 'react';
import * as PropTypes from 'prop-types';
import NoData from '../../components/NoData';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import logo from '../../assets/logo.png';
import { Button } from '@material-ui/core';
// import { ReactComponent as InteractIcon } from '../../assets/home/interact.svg';
import './index.css';
import CircularProgress from '../../components/CircularProgress';
import variables from '../../utils/variables';
import { Tooltip } from '@mui/material';

const List = (props) => {
    const handleVideo = (id) => {
        props.history.push(`/course/${id}`);
    };

    return (
        <div className="list_container">
            <div className={props.courses && Object.keys(props.courses).length === 1
                ? 'list single_list' : (props.courses && Object.keys(props.courses).length ? 'list' : 'list no_data')}>
                {props.inProgress
                    ? <CircularProgress/>
                    : props.courses && Object.keys(props.courses).length
                        ? Object.keys(props.courses).map((key) => {
                            if (key && props.courses[key] && props.courses[key][0]) {
                                const item = props.courses[key][0];
                                return (
                                    <div key={key} className="course_card" onClick={() => handleVideo(key)}>
                                        <div className="image_section">
                                            <img
                                                alt="thumbnail"
                                                className="thumbnail"
                                                src={item.asset && item.asset.thumbnail && item.asset.thumbnail.horizontal
                                                    ? item.asset.thumbnail.horizontal : logo}/>
                                        </div>
                                        <div className="details_section">
                                            {/* <p className="module_name">{item && item.name}</p> */}
                                            {item && item.asset && item.asset.name &&
                                            <Tooltip title={item && item.asset && item.asset.name}>
                                                <p className="name">{item && item.asset && item.asset.name}</p>
                                            </Tooltip>}
                                            <Button
                                                className="action"
                                                onClick={() => handleVideo(key)}>{variables[props.lang].explore_course}</Button>
                                        </div>
                                    </div>
                                );
                            }
                        }) : <NoData/>}
            </div>
        </div>
    );
};

List.propTypes = {
    courses: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        address: state.account.address,
        courses: state.navbar.videosList.courses,
        inProgress: state.navbar.videosList.inProgress,
    };
};

export default withRouter(connect(stateToProps)(List));
