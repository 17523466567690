import React from 'react';
import Controls from './ControlsFullScreen';
import ReactDOM from 'react-dom';
import videojs from 'video.js';

const VjsComponent = videojs.getComponent('Component');

class ControlsVjs extends VjsComponent {
    constructor (player, options) {
        super(player, options);

        // Bind the current class context to the mountReactComponent method
        this.mountReactComponent = this.mountReactComponent.bind(this);

        // When player is ready, call method to mount the React component
        player.ready(() => this.mountReactComponent(this, options));

        // Remove the React root when this component is destroyed
        this.on('dispose', () => ReactDOM.unmountComponentAtNode(this.el()));
    }

    // This method renders the ExampleReactComponent into the DOM element of
    // the Video.js component, `this.el()`.
    mountReactComponent (player, options) {
        ReactDOM.render(
            <Controls options={options} vjsBridgeComponent={player}/>,
            this.el(),
        );
    }
}

// Make sure to register the Video.js component so Video.js knows it exists
videojs.registerComponent('controlsVjs', ControlsVjs);

export default ControlsVjs;
