import React from 'react';
import './index.css';
import PlayButton from './PlayButton';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import VolumeButton from '../Header/VolumeButton';
import BackButton from './BackButton';
import ForwardButton from './ForwardButton';
import ClassNames from 'classnames';

const Controls = (props) => {
    return (
        <div className="player_controls">
            <div className={ClassNames('left_content', props.hideSeek ? 'hide_seek' : '')}>
                {props.hideSeek
                    ? null
                    : <BackButton player={props.player}/>}
                <PlayButton fullVideo={props.fullVideo} interactive={props.interactive} player={props.player}/>
                {props.hideSeek
                    ? null
                    : <ForwardButton player={props.player}/>}
            </div>
            <VolumeButton player={props.player}/>
        </div>
    );
};

Controls.propTypes = {
    fullVideo: PropTypes.bool,
    hideSeek: PropTypes.bool,
    interactive: PropTypes.bool,
    player: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
    };
};

export default connect(stateToProps)(Controls);
