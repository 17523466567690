import React from 'react';
import Header from './Header';
import IMPInformationDialog from './IMPInformationDialog';
import TokenGatedContentDialog from './TokenGatedContentDialog';
import Certification from '../Certification';
import About from './About';
import List from '../Courses/List';
// import RegisterVideo from './List';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import RegisterSection from './RegisterSection';

const VideosList = (props) => {
    return (
        <div className="home_page">
            <RegisterSection/>
            <Header/>
            {/* <RegisterVideo/> */}
            <About />
            <div className="courses_section">
                <div className="section1">
                    <p>{variables[props.lang].courses}</p>
                    <span>{variables[props.lang]['courses_sub_text']}</span>
                    <List/>
                </div>
            </div>
            <Certification />
            <IMPInformationDialog/>
            <TokenGatedContentDialog/>
        </div>
    );
};

VideosList.propTypes = {
    lang: PropTypes.string.isRequired,
    nftSList: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        nftSList: state.myAccount.userNFTs.result,
    };
};

export default connect(stateToProps)(VideosList);
