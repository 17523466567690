import { combineReducers } from 'redux';
import {
    CLEAR_DATA,
    CURRENT_INTERACTION_SET,
    DEVICE_ID_FETCH_ERROR,
    DEVICE_ID_FETCH_IN_PROGRESS,
    DEVICE_ID_FETCH_SUCCESS,
    IMP_INFORMATION_DIALOG_HIDE,
    IMP_INFORMATION_DIALOG_SHOW,
    INTERACTION_TIME_HIDE,
    INTERACTION_TIME_SHOW,
    INTERACTIVE_PLAYER_SET,
    INTERACTIVE_SAVE_SET,
    INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS,
    INTERACTIVE_VIDEO_FETCH_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_ADD_ERROR,
    INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS,
    INTERACTIVE_VIDEO_URL_FETCH_SUCCESS,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    SEARCH_FOR_ASSET_SET,
    TOKEN_GATED_CONTENT_DIALOG_HIDE,
    TOKEN_GATED_CONTENT_DIALOG_SHOW,
    WINDOW_RESIZE_SET,
    CLAIM_CERTIFICATE_DIALOG_HIDE,
    CLAIM_CERTIFICATE_DIALOG_SHOW,
    SET_CLAIM_CERTIFICATE_LOADING,
    QUIZ_DIALOG_SHOW,
    QUIZ_DIALOG_HIDE,
} from '../constants/interactiveVideo';
import { DEFAULT_LIMIT, DEFAULT_SEARCH_KEY, DEFAULT_SKIP } from '../constants/url';
import { ASSET_WATCH_URL_FETCH_SUCCESS } from '../constants/myAccount';

const list = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH_KEY,
    total: null,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const url = (state = '', action) => {
    if (action.type === INTERACTIVE_VIDEO_URL_FETCH_SUCCESS) {
        return action.value;
    } else if (action.type === ASSET_WATCH_URL_FETCH_SUCCESS) {
        return action.value;
    }

    return state;
};

const deviceID = (state = {
    id: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case DEVICE_ID_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DEVICE_ID_FETCH_SUCCESS:
        return {
            id: action.value,
            inProgress: false,
        };
    case DEVICE_ID_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const inProgress = (state = false, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return true;
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return false;
    default:
        return state;
    }
};

const _ = (state = [], action) => {
    if (action.type === INTERACTIVE_VIDEO_FETCH_SUCCESS) {
        return action.list;
    }

    return state;
};

const player = (state = null, action) => {
    if (action.type === INTERACTIVE_PLAYER_SET) {
        return action.value;
    }

    return state;
};

export const currentInteraction = (state = 0, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_SESSION_ADD_ERROR:
        return -1; // adding -1 so that we won't showing the interaction further.
    case CURRENT_INTERACTION_SET:
        return action.value;
    default:
        return state;
    }
};

const answers = (state = {}, action) => {
    switch (action.type) {
    case INTERACTIVE_SAVE_SET:
        return action.value;
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
    case INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS:
    case CLEAR_DATA:
        return {};
    default:
        return state;
    }
};

const session = (state = {
    data: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS:
        return {
            data: action.value,
            inProgress: false,
        };
    case INTERACTIVE_VIDEO_SESSION_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export const participant = (state = {}, action) => {
    if (action.type === INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS) {
        return action.value;
    }

    return state;
};

export const sessionComplete = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS:
        return {
            inProgress: false,
            value: action.value && action.value.participant,
        };
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
    case CLEAR_DATA:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const searchAsset = (state = '', action) => {
    if (action.type === SEARCH_FOR_ASSET_SET) {
        return action.value;
    }

    return state;
};

const interactionTime = (state = false, action) => {
    switch (action.type) {
    case INTERACTION_TIME_SHOW:
        return true;
    case CLEAR_DATA:
    case INTERACTION_TIME_HIDE:
        return false;
    default:
        return state;
    }
};

const windowSize = (state = 3, action) => {
    if (action.type === WINDOW_RESIZE_SET) {
        return action.value;
    }

    return state;
};

const impInformationDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case IMP_INFORMATION_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case IMP_INFORMATION_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const tokenGatedContentDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TOKEN_GATED_CONTENT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case TOKEN_GATED_CONTENT_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const claimCertificateDialog = (state = {
    open: false,
    percentage: '',
    requiredPercentage: '',
    loadingText: 'Validating your score',
    certificateLoading: true,
}, action) => {
    switch (action.type) {
    case CLAIM_CERTIFICATE_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            percentage: action.percentage,
            requiredPercentage: action.requiredPercentage,
        };
    case CLAIM_CERTIFICATE_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    case SET_CLAIM_CERTIFICATE_LOADING:
        return {
            ...state,
            certificateLoading: action.value,
            loadingText: action.text,
        };
    default:
        return state;
    }
};

const quizDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case QUIZ_DIALOG_SHOW:
        return {
            open: true,
        };
    case QUIZ_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    list,
    inProgress,
    deviceID,
    url,
    _,
    player,
    currentInteraction,
    answers,
    session,
    participant,
    sessionComplete,
    searchAsset,
    interactionTime,
    windowSize,
    impInformationDialog,
    tokenGatedContentDialog,
    claimCertificateDialog,
    quizDialog,
});
