import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideTokenGatedContentDialog } from '../../../actions/interactiveVideo';
import { Dialog } from '@material-ui/core';
import React from 'react';
import variables from '../../../utils/variables';
import './index.css';
import { IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/closeIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/new_info_Icon.svg';
import List from '../List';
import LoadingIcon from '../../../assets/loading.png';

const TokenGatedContentDialog = (props) => {
    return (
        <Dialog
            aria-describedby="account-connect-dialog-description"
            aria-labelledby="account-connect-dialog-title"
            className="token_gated_dialog"
            open={props.open}
            onClose={props.handleClose}>
            {!props.inProgress && (props.collectionMyNFTs && !props.collectionMyNFTs.length)
                ? <div className="access_denied_section">
                    <div className="content1">
                        {variables[props.lang]['access_denied']}
                        <IconButton onClick={props.handleClose}><CloseIcon/></IconButton>
                    </div>
                    <div className="content2">
                        {variables[props.lang]['no_course_nft_found']}
                    </div>
                    <div className="content3" onClick={props.handleClose}>
                        <div className="course_nft_warning">
                            <InfoIcon/>
                            <p>
                                {variables[props.lang].get_the}
                                <span>{variables[props.lang].course_access_nft}</span>
                                {variables[props.lang].interacting_below_video}
                            </p>
                        </div>
                        <List/>
                    </div>
                </div>
                : <div className="token_gated_section">
                    {/* <h3> */}
                    {/*    <TokenGated/> */}
                    {/*    {variables[props.lang]['token_gated_content']} */}
                    {/* </h3> */}
                    <div className="content1">
                        {variables[props.lang]['verifying_nft']}
                    </div>
                    <img alt="loading" src={LoadingIcon}/>
                </div>}
        </Dialog>
    );
};

TokenGatedContentDialog.propTypes = {
    collectionMyNFTs: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.interactiveVideo.tokenGatedContentDialog.open,
        collectionMyNFTs: state.gatedAccess.collectionMyNFTs.result,
        inProgress: state.gatedAccess.collectionMyNFTs.inProgress,
    };
};

const actionToProps = {
    handleClose: hideTokenGatedContentDialog,
};

export default connect(stateToProps, actionToProps)(TokenGatedContentDialog);
