import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as CosmoIcon } from '../../../assets/logos/cosmo.svg';
import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { addInteractiveVideoSession, setAccountAddress } from '../../../actions/interactiveVideo';
import { hideConnectDialog } from '../../../actions/navbar';
import { showMessage } from '../../../actions/snackbar';
import { setPublicKey } from '../../../actions/account';
import { connect } from 'react-redux';
import { initializeCosmoStation } from '../../../helper';

const CosmostationButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const login = () => {
        setInProgress(true);
        initializeCosmoStation((error, account) => {
            setInProgress(false);
            if (error) {
                localStorage.removeItem('akt_edu_address');
                props.showMessage(error);

                return;
            }

            props.setAccountAddress(account.address);
            props.handleClose();
            localStorage.setItem('akt_edu_address', account.address);
            localStorage.setItem('akt_edu_connector', 'cs');
        });
    };

    return (
        <Button onClick={login}>
            <CosmoIcon/>
            {inProgress ? variables[props.lang].connecting + '...' : variables[props.lang].cosmostation}
        </Button>
    );
};

CosmostationButton.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setPublicKey: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.bool.isRequired,
    deviceId: PropTypes.string,
    player: PropTypes.object,
    videoId: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        deviceId: state.interactiveVideo.deviceID.id,
        lang: state.language,
        open: state.navbar.connectDialog,
        sign: state.account.connectDialog.sign,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    addInteractiveVideoSession,
    handleClose: hideConnectDialog,
    showMessage,
    setAccountAddress,
    setPublicKey,
};

export default connect(stateToProps, actionToProps)(CosmostationButton);
