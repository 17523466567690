import React from 'react';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { setAccountAddress } from '../../actions/interactiveVideo';
import { setClearUserNFTs } from '../../actions/myAccount';
import { withRouter } from 'react-router';

const DisconnectButton = (props) => {
    const handleClick = () => {
        props.history.push('/');
        localStorage.removeItem('akt_edu_connector');
        localStorage.removeItem('akt_edu_address');
        localStorage.removeItem('akt_edu_wallet_connect');
        localStorage.removeItem('akt_edu_nc');
        props.setAccountAddress('');
        props.setClearUserNFTs();
        if (props.walletConnector && props.walletConnector._connected) {
            props.walletConnector && props.walletConnector.killSession();
        }
    };

    return (
        <Button
            className="disconnect"
            variant="outlined"
            onClick={handleClick}>
            {variables[props.lang].disconnect}
        </Button>
    );
};

DisconnectButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setClearUserNFTs: PropTypes.func.isRequired,
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        walletConnector: state.account.wallet.connection.walletConnector,
    };
};

const actionToProps = {
    setAccountAddress,
    setClearUserNFTs,
};

export default withRouter(connect(stateToProps, actionToProps)(DisconnectButton));
