import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BackButton from '../../../components/BackButton';
import CountDownTimer from '../Timer';
import variables from '../../../utils/variables';

const Header = (props) => {
    const isAdmin = props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_admission;

    return (
        <div className="player_header">
            {/* <div className="left_content"> */}
            <BackButton interactive={props.interactive} isAdmin={isAdmin} value={props.value}/>
            {isAdmin
                ? null
                : props.show
                    ? <>
                        <CountDownTimer/>
                        {/* <p className="quiz">Interaction Time</p> */}
                        {/* <p className="time">00:30 Sec</p> */}
                    </>
                    : <p>{variables[props.lang].quiz_video}</p>}
            {/* : <p className="quiz">{props.name}</p>} */}
            {/* </div> */}
        </div>
    );
};

Header.propTypes = {
    lang: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    interactive: PropTypes.bool,
    // name: PropTypes.string,
    player: PropTypes.object,
    value: PropTypes.string,
    video: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        show: state.interactiveVideo.interactionTime,
        lang: state.language,
        // name: state.interactiveVideo._.asset.name,
    };
};

export default connect(stateToProps)(Header);
