import { Button } from '@material-ui/core';
import Kepler from '../../../assets/kepler.png';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showNetworkDialog } from '../../../actions/navbar';

const ConnectKeplrButton = (props) => {
    const handleShowNetworks = () => {
        props.showNetworkDialog();
        props.handleClose();
    };

    return (
        <Button
            className="keplr_button"
            onClick={handleShowNetworks}>
            <img alt="kepler icon" src={Kepler}/>
            <p>Connect with Keplr</p>
        </Button>
    );
};

ConnectKeplrButton.propTypes = {
    handleClose: PropTypes.func.isRequired,
    showNetworkDialog: PropTypes.func.isRequired,
};

const actionsToProps = {
    showNetworkDialog,
};

export default withRouter(connect(null, actionsToProps)(ConnectKeplrButton));
