export const CONNECT_ACCOUNT_DIALOG_SHOW = 'CONNECT_ACCOUNT_DIALOG_SHOW';
export const CONNECT_ACCOUNT_DIALOG_HIDE = 'CONNECT_ACCOUNT_DIALOG_HIDE';

export const NETWORKS_DIALOG_SHOW = 'NETWORKS_DIALOG_SHOW';
export const NETWORKS_DIALOG_HIDE = 'NETWORKS_DIALOG_HIDE';

export const SHOW_SIDE_BAR_SET = 'SHOW_SIDE_BAR_SET';
export const HIDE_SIDE_BAR_SET = 'HIDE_SIDE_BAR_SET';

export const META_MASK_NETWORKS_DIALOG_SHOW = 'META_MASK_NETWORKS_DIALOG_SHOW';
export const META_MASK_NETWORKS_DIALOG_HIDE = 'META_MASK_NETWORKS_DIALOG_HIDE';

export const CONNECT_DIALOG_SHOW = 'CONNECT_DIALOG_SHOW';
export const CONNECT_DIALOG_HIDE = 'CONNECT_DIALOG_HIDE';

export const FETCH_VIDEOS_LIST_SUCCESS = 'FETCH_VIDEOS_LIST_SUCCESS';
export const FETCH_VIDEOS_LIST_IN_PROGRESS = 'FETCH_VIDEOS_LIST_IN_PROGRESS';
export const FETCH_VIDEOS_LIST_ERROR = 'FETCH_VIDEOS_LIST_ERROR';
