import {
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR,
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_SUCCESS,
    PARTICIPANT_FETCH_ERROR,
    PARTICIPANT_FETCH_IN_PROGRESS,
    PARTICIPANT_FETCH_SUCCESS,
    PARTICIPANTS_SEARCH_SET,
} from '../constants/participant';
import axios from 'axios';
import { getInteractiveVideoParticipantURL } from '../constants/url';

const fetchInteractiveVideoParticipantsInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_IN_PROGRESS,
    };
};

const fetchInteractiveVideoParticipantsSuccess = (list, count, skip, limit, searchKey, sortBy, order) => {
    return {
        type: INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_SUCCESS,
        list: list,
        count,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchInteractiveVideoParticipantsError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR,
        message,
    };
};

export const getInteractiveVideoParticipants = (interactiveVideoId, skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchInteractiveVideoParticipantsInProgress());

    const URL = getInteractiveVideoParticipantURL(interactiveVideoId, skip, limit, searchKey, sortBy, order, null);

    axios.get(URL, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        const count = res.data && res.data.result && res.data.result.count;
        dispatch(fetchInteractiveVideoParticipantsSuccess(res.data && res.data.result, count, skip, limit, searchKey, sortBy, order));
    }).catch((error) => {
        dispatch(fetchInteractiveVideoParticipantsError(error.response ? error.response.data.message : error.message));
    });
};

const fetchParticipantInProgress = () => {
    return {
        type: PARTICIPANT_FETCH_IN_PROGRESS,
    };
};

const fetchParticipantSuccess = (list, interactiveVideoId) => {
    return {
        type: PARTICIPANT_FETCH_SUCCESS,
        list: list,
        interactiveVideoId,
    };
};

const fetchParticipantError = (message) => {
    return {
        type: PARTICIPANT_FETCH_ERROR,
        message,
    };
};

export const fetchParticipant = (interactiveVideoId, address, cb) => (dispatch) => {
    dispatch(fetchParticipantInProgress());

    const URL = getInteractiveVideoParticipantURL(interactiveVideoId, null, null, null, null, null, address);

    axios.get(URL, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchParticipantSuccess(res.data && res.data.result && res.data.result.list, interactiveVideoId));
        cb(res.data && res.data.result && res.data.result.list);
    }).catch((error) => {
        dispatch(fetchParticipantError(error.response ? error.response.data.message : error.message));
        cb(null);
    });
};

export const setParticipantsSearch = (value) => {
    return {
        type: PARTICIPANTS_SEARCH_SET,
        value,
    };
};
