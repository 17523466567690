import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './index.css';
import { sendTx } from '../../../helper';
import ToAddressTextField from './ToAddressTextField';
import TokensToSendTextField from './TokensToSendTextField';
import { showMessage } from '../../../actions/snackbar';
import { codes } from '../../../config/network';

const SendTokens = (props) => {
    const [inProgress, setInProgress] = useState(false);
    const [hash, setHash] = useState('');
    const handleSend = () => {
        setInProgress(true);
        if (!props.address) {
            props.showMessage('No address found');
            setInProgress(false);
            return;
        }
        if (!props.toAddress) {
            props.showMessage('Receiver address required');
            setInProgress(false);
            return;
        }
        if (!props.tokensToSend) {
            props.showMessage('Please enter amount to send');
            setInProgress(false);
            return;
        }

        const networkCode = localStorage.getItem('akt_edu_nc');
        const network = codes[networkCode];
        if (network) {
            buyWithCosmosAccount(network);
        } else {
            buyWithEthereumAccount();
        }
    };

    const buyWithEthereumAccount = () => {
        if (!props.contractInstance) {
            props.showMessage('Contract not found, please reconnect');
            setInProgress(false);
            return;
        }

        props.contractInstance && props.contractInstance.methods
            .transfer(props.address, (props.tokensToSend * (10 ** 16)).toLocaleString('fullwide', { useGrouping: false }))
            .send({
                from: props.address,
                gasLimit: 43279,
                gasPrice: props.web3Instance.utils.toWei(
                    (1800).toString(), 'gwei'),

            })
            .then((res) => {
                props.showMessage('success');
                setHash(res && res.transactionHash);
                setInProgress(false);
            })
            .catch((error) => {
                props.showMessage(error && error.message);
                setInProgress(false);
            });
    };

    const buyWithCosmosAccount = (network) => {
        sendTx(props.address,
            props.toAddress, props.tokensToSend, '', network, (error) => {
                setInProgress(false);
                if (error) {
                    props.showMessage(error);
                    return;
                }

                props.showMessage('Success! Your transaction has been confirmed');
            });
    };

    return (
        <div>
            <p className="title">Enter details to send tokens</p>
            <form className="form">
                <ToAddressTextField/>
                <div className="tokens_row">
                    <TokensToSendTextField/>
                </div>
            </form>
            <Button
                className="send_button"
                disabled={inProgress}
                onClick={handleSend}>
                Buy
            </Button>
            {hash ? <div> TransactionHash: {hash} </div> : null}
        </div>
    );
};

SendTokens.propTypes = {
    showMessage: PropTypes.func.isRequired,
    toAddress: PropTypes.string.isRequired,
    tokensToSend: PropTypes.string.isRequired,
    address: PropTypes.string,
    contractInstance: PropTypes.any,
    web3Instance: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        toAddress: state.account.toAddress,
        tokensToSend: state.account.tokensToSend,
        contractInstance: state.account.contractInstance,
        web3Instance: state.account.web3Instance,
    };
};

const actionToProps = {
    showMessage,
};

export default connect(stateToProps, actionToProps)(SendTokens);
