import React from 'react';
import * as PropTypes from 'prop-types';
import ClassNames from 'classnames';
import NoData from '../../components/NoData';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ImageOnLoad from '../../components/ImageOnLoad/BackgroundImage';
import { getAssetType } from '../../utils/strings';
import { Tooltip } from '@mui/material';
import VideoOnLoad from '../../components/ImageOnLoad/VideoOnLoad';
import { MARKETPLACE_URL } from '../../config';

const NFTsList = (props) => {
    const handleMouseOver = (event) => {
        const element = event.currentTarget.querySelector('video');

        if (element) {
            element.play();
        }
    };

    const handleMouseOut = (event) => {
        const element = event.currentTarget.querySelector('video');

        if (element) {
            element.pause();
        }
    };

    const handleAccount = (id) => {
        window.open(MARKETPLACE_URL + '/nft/' + id);
    };

    return (
        <>
            {props.nftSList && Object.keys(props.nftSList).length
                ? Object.keys(props.nftSList).map((key) => {
                    const nftSList = props.nftSList[key];
                    if (nftSList && nftSList.length) {
                        return nftSList.map((item, index) => {
                            const mediaType = item && item.media_type;

                            return (
                                <div
                                    key={index}
                                    className={ClassNames('list_page')}
                                    onClick={(e) => handleAccount(item && item.id)}
                                    onMouseOut={handleMouseOut}
                                    onMouseOver={handleMouseOver}>
                                    {mediaType &&
                                    getAssetType(mediaType) === 'video'
                                        ? <div
                                            className={ClassNames('list_section2 inline_video_section', item && item.nsfw ? 'blur' : '')}>
                                            <VideoOnLoad
                                                preview={item && item.preview_uri}
                                                src={item && item.media_uri}
                                                type={mediaType}/>
                                        </div>
                                        : mediaType && getAssetType(mediaType) === 'image'
                                            ? <ImageOnLoad
                                                cdn={item && item.cloudflare_cdn && item.cloudflare_cdn.variants}
                                                className={ClassNames('list_section2', item && item.nsfw ? 'blur' : '')}
                                                preview={item && item.preview_uri}
                                                src={item && item.media_uri}/>
                                            : <ImageOnLoad
                                                className={ClassNames('list_section2', item && item.nsfw ? 'blur' : '')}
                                                src={item && item.preview_uri}/>}
                                    <div className="list_section3">
                                        <div className="list_section3_div">
                                            <Tooltip
                                                title={(item.denom && item.denom.name) + ' | ' + (item.denom && item.denom.symbol)}>
                                                <span>
                                                    {item.denom && item.denom.name}
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <Tooltip title={item && item.name}>
                                            <span>
                                                {item && item.name}
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        });
                    }

                    return null;
                }) : <NoData text="No NFTs found"/>}
        </>
    );
};

NFTsList.propTypes = {
    address: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    nftSList: PropTypes.object.isRequired,
    nftSTotal: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        address: state.account.address,
        nftSList: state.myAccount.userNFTs.result,
        nftSTotal: state.myAccount.userNFTs.count,
    };
};

export default withRouter(connect(stateToProps)(NFTsList));
