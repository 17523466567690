import { combineReducers } from 'redux';
import {
    COLLECTION_MY_NFT_S_FETCH_ERROR,
    COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS,
    COLLECTION_MY_NFT_S_FETCH_SUCCESS,
} from '../constants/gatedAccess';

const collectionMyNFTs = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTION_MY_NFT_S_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    }
    case COLLECTION_MY_NFT_S_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    collectionMyNFTs,
});
