import {
    CONNECT_ACCOUNT_DIALOG_HIDE,
    CONNECT_ACCOUNT_DIALOG_SHOW,
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    FETCH_VIDEOS_LIST_ERROR,
    FETCH_VIDEOS_LIST_IN_PROGRESS,
    FETCH_VIDEOS_LIST_SUCCESS,
    HIDE_SIDE_BAR_SET,
    META_MASK_NETWORKS_DIALOG_HIDE,
    META_MASK_NETWORKS_DIALOG_SHOW,
    NETWORKS_DIALOG_HIDE,
    NETWORKS_DIALOG_SHOW,
    SHOW_SIDE_BAR_SET,
} from '../constants/navbar';
import Axios from 'axios';
import { fetchVideosList } from '../constants/url';
import { uniqFunction } from '../utils/arrays';

export const showConnectDialog = (sign) => {
    return {
        type: CONNECT_DIALOG_SHOW,
        sign,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_HIDE,
    };
};

export const showConnectAccountDialog = (sign) => {
    return {
        type: CONNECT_ACCOUNT_DIALOG_SHOW,
        sign,
    };
};

export const hideConnectAccountDialog = () => {
    return {
        type: CONNECT_ACCOUNT_DIALOG_HIDE,
    };
};

export const showNetworkDialog = () => {
    return {
        type: NETWORKS_DIALOG_SHOW,
    };
};

export const hideNetworkDialog = () => {
    return {
        type: NETWORKS_DIALOG_HIDE,
    };
};

export const showSideBar = () => {
    return {
        type: SHOW_SIDE_BAR_SET,
    };
};

export const hideSideBar = () => {
    return {
        type: HIDE_SIDE_BAR_SET,
    };
};

export const showMetaMaskNetworkDialog = () => {
    return {
        type: META_MASK_NETWORKS_DIALOG_SHOW,
    };
};

export const hideMetaMaskNetworkDialog = () => {
    return {
        type: META_MASK_NETWORKS_DIALOG_HIDE,
    };
};

export const fetchVideosListInProgress = () => {
    return {
        type: FETCH_VIDEOS_LIST_IN_PROGRESS,
    };
};

export const fetchVideoListSuccess = (value, courses, denoms) => {
    return {
        type: FETCH_VIDEOS_LIST_SUCCESS,
        value,
        courses,
        denoms,
    };
};

export const fetchVideoListError = (message) => {
    return {
        type: FETCH_VIDEOS_LIST_ERROR,
        message,
    };
};

export const videosList = (cb) => (dispatch) => {
    dispatch(fetchVideosListInProgress());

    Axios.get(fetchVideosList, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            let list = res.data && res.data.result;
            list = list && list.filter((val) => val && val.akash_edu_config);

            const courses = {};
            const denoms = [];
            if (list && list.length) {
                list.map((val) => {
                    if (val && val.akash_edu_config && val.akash_edu_config.course_id && courses[val.akash_edu_config.course_id]) {
                        courses[val.akash_edu_config.course_id].push(val);
                    } else if (val && val.akash_edu_config && val.akash_edu_config.course_id) {
                        const array = [];
                        array.push(val);
                        courses[val.akash_edu_config.course_id] = array;
                    }

                    if (val && val.akash_edu_config && val.akash_edu_config.denom_id) {
                        denoms.push(val.akash_edu_config.denom_id);
                    }
                });
            }
            dispatch(fetchVideoListSuccess(list, courses, uniqFunction(denoms)));
            if (cb) {
                cb(list, uniqFunction(denoms));
            }
        })
        .catch((error) => {
            dispatch(fetchVideoListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
