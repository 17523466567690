import {
    INTERACTIVE_VIDEO_FETCH_ERROR,
    INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_FETCH_SUCCESS,
} from '../constants/interactiveVideo';
import { getCourseDetailsURL } from '../constants/url';
import axios from 'axios';

const fetchCourseDetailsInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    };
};

const fetchCourseDetailsSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_SUCCESS,
        value,
    };
};

const fetchCourseDetailsError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_FETCH_ERROR,
        message,
    };
};

export const getCourseDetails = (id) => (dispatch) => {
    dispatch(fetchCourseDetailsInProgress());
    const url = getCourseDetailsURL(id);

    axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchCourseDetailsSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchCourseDetailsError(error.response ? error.response.data.message : error.message));
    });
};
