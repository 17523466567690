import { AppBar, Tab, Tabs } from '@material-ui/core';
import variables from '../../utils/variables';
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { setMyAccountTab } from '../../actions/myAccount';

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const MyAccountTabs = (props) => {
    const claimableNftsCount = props.address && props.claimableNftSList && props.participant && props.participant.length;
    const myNFTsCount = props.address && props.myNftsCount && props.myNftsCount;

    return (
        <AppBar className="my_account_tabs" position="static">
            <Tabs aria-label="simple tabs example" className="tabs_section" value={props.value}>
                <Tab
                    className={'tab ' + (props.value === 'my_nfts' ? 'active_tab' : '')}
                    label={
                        <>
                            {variables[props.lang]['my_nfts']}
                            {myNFTsCount && props.nftSList && Object.keys(props.nftSList).length ? ` (${myNFTsCount})` : ''}
                        </>
                    }
                    value="my_nfts"
                    onClick={() => props.onChange('my_nfts')}
                    {...a11yProps(0)} />
                <Tab
                    className={'tab ' + (props.value === 'claimable' ? 'active_tab' : '')}
                    label={
                        <>
                            {variables[props.lang].claimable}
                            {claimableNftsCount ? ` (${claimableNftsCount})` : ''}
                        </>
                    }
                    value="claimable"
                    onClick={() => props.onChange('claimable')}
                    {...a11yProps(1)} />
            </Tabs>
        </AppBar>
    );
};

MyAccountTabs.propTypes = {
    address: PropTypes.string.isRequired,
    claimableNftSList: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    myNftsCount: PropTypes.number.isRequired,
    nftSList: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.myAccount.myAccountTab,
        claimableNftSList: state.myAccount.projectsList.value,
        myNftsCount: state.myAccount.userNFTs.count,
        nftSList: state.myAccount.userNFTs.result,
        address: state.account.address,
        participant: state.participant.participant.value,
    };
};

const actionsToProps = {
    onChange: setMyAccountTab,
};

export default connect(stateToProps, actionsToProps)(MyAccountTabs);
