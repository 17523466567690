import {
    DEVICE_ID_FETCH_ERROR,
    INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR,
    INTERACTIVE_VIDEO_FETCH_ERROR,
    INTERACTIVE_VIDEO_SESSION_ADD_ERROR,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR,
    INTERACTIVE_VIDEO_URL_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
} from '../constants/interactiveVideo';
import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR } from '../constants/participant';
import { CONNECT_KEPLR_ACCOUNT_ERROR } from '../constants/wallet';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
    case INTERACTIVE_VIDEO_FETCH_ERROR:
    case INTERACTIVE_VIDEO_URL_FETCH_ERROR:
    case DEVICE_ID_FETCH_ERROR:
    case INTERACTIVE_VIDEO_SESSION_ADD_ERROR:
    case INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR:
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR:
    case INTERACTIVE_VIDEO_PARTICIPANTS_FETCH_ERROR:
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case MESSAGE_SHOW:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
