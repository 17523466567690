import { config } from '../config';

const API_URL = 'https://dev-api-akash-edu.omniflix.studio';
const CLAIM_URL = 'https://dev-api-akash-edu.layerzerox.com';
const DATA_LAYER = config.DATA_LAYER;
export const REST_URL = config.REST_URL;
export const RPC_URL = config.RPC_URL;

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SEARCH_KEY = '';
export const DEFAULT_SORT_BY = '';
export const DEFAULT_ORDER = null;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;

export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;
export const urlFetchAccount = (address) => `${REST_URL}/cosmos/auth/v1beta1/accounts/${address}`;
export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const getInteractiveVideosURL = (search, skip, limit, createdBy, organizationId) => {
    const params = [];

    if (search) {
        params.push(`search=${search}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (createdBy) {
        params.push(`createdBy=${createdBy}`);
    }
    if (organizationId) {
        params.push(`organizationId=${organizationId}`);
    }

    return `${API_URL}/akash-edu/interactive-videos?${params.join('&')}&total=true`;
};

export const getInteractiveVideoShowURL = (id, deviceId) => {
    const params = [];

    if (deviceId) {
        params.push(`deviceId=${deviceId}`);
    }

    return `${API_URL}/akash-edu/interactive-videos/${id}/get-url?${params.join('&')}`;
};

export const getWatchVideoURL = (id, deviceId) => {
    const params = [];

    if (deviceId) {
        params.push(`deviceId=${deviceId}`);
    }

    return `${API_URL}/organizations/5de5fa095240fc38a4a15dde/akash-edu-assets/${id}/watch-url?${params.join('&')}`;
};

export const getInteractiveVideoURL = (id) => `${API_URL}/akash-edu/interactive-videos/${id}`;

export const addInteractiveVideoSessionURL = (id) => `${API_URL}/akash-edu/interactive-videos/${id}/sessions`;

export const saveInteractiveVideoAnswerURL = (interactiveVideoId, sessionId) =>
    `${API_URL}/public/interactive-videos/${interactiveVideoId}/sessions/${sessionId}/answers`;

export const completeInteractiveVideoSessionURL = (interactiveVideoId, sessionId) =>
    `${API_URL}/akash-edu/interactive-videos/${interactiveVideoId}/sessions/${sessionId}/complete`;

export const getInteractiveVideoParticipantURL = (interactiveVideoId, skip, limit, searchKey, sortBy, order, address) => {
    const params = ['total=true'];

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey && searchKey.length > 0) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy) {
        params.push(`sortOn=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    } else {
        params.push('sortOn=created_at');
    }
    if (address) {
        params.push(`accountAddress=${address}`);
    }

    return `${API_URL}/public/interactive-videos/${interactiveVideoId}/participants?${params.join('&')}`;
};
export const urlCollectionMyNfts = (id, owner) => {
    const params = ['denomId=' + id, 'owner=' + owner, 'sortBy=updated_at', 'order=desc', 'ipInfringement=false', 'limit=1'];

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const urlMyNFTs = (owner, denom, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (denom && denom.length) {
        denom.map((val) => {
            params.push(`denomIds[]=${val}`);
        });
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/nfts?owner=${owner}&${params.join('&')}`;
};

export const urlFetchProjectsList = (address) => {
    const params = [];
    if (address) {
        params.push(`address=${address}`);
    }

    return `${CLAIM_URL}/projects?${params.join('&')}`;
};
export const urlFetchIVProjectsList = (address, interactiveVideoID) => {
    const params = [];
    if (address) {
        params.push(`address=${address}`);
    }
    if (interactiveVideoID) {
        params.push(`interactiveVideoId=${interactiveVideoID}`);
    }

    return `${CLAIM_URL}/projects?${params.join('&')}`;
};
export const urlMintNFT = (projectID) => `${CLAIM_URL}/projects/${projectID}/mint-requests`;
export const urlFetchMintRequest = (address, projectID) => `${CLAIM_URL}/${address}/projects/${projectID}/mint-requests`;
export const urlFetchMintQueue = (address, projectID) => `${CLAIM_URL}/${address}/projects/${projectID}/mint-queue`;
export const urlNFT = (nftID) => `${DATA_LAYER}/nfts/${nftID}`;
export const urlEligibleCheck = (interactiveVideoId, participantId) => `${API_URL}/akash-edu/interactive-videos/${interactiveVideoId}/participants/${participantId}/is-eligible-for-certificate`;

export const urlCheckNFTClaim = (denom, address) => {
    const params = ['statuses[]=BURNED', 'statuses[]=CREATED'];
    if (denom) {
        params.push(`denomId=${denom}`);
    }
    if (address) {
        params.push(`owner=${address}`);
    }

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const fetchVideosList = `${API_URL}/akash-edu/interactive-videos`;

// Course Details
export const getCourseDetailsURL = (id) => {
    // const params = [];
    //
    // if (deviceId) {
    //     params.push(`deviceId=${deviceId}`);
    // }

    return `${API_URL}/courses/${id}`;
};
