import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import NoDataIcon from '../../assets/no_data.svg';

const NoData = (props) => {
    return (
        <div className="no_data">
            <img alt="No Data" src={NoDataIcon}/>
            {props.text
                ? <p>{props.text}</p>
                : <p>No Data</p>}
        </div>
    );
};

NoData.propTypes = {
    text: PropTypes.string,
};

export default NoData;
