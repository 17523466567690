import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserNFTs } from '../../actions/myAccount';
// import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import Header from './Header';
import CircularProgress from '../../components/CircularProgress';
import NoData from '../../components/NoData';
import List from './List';
import Claimable from './Claimable';

class MyNFTs extends React.Component {
    constructor (props) {
        super(props);
        this.myRef = React.createRef();

        // this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount () {
        const address = localStorage.getItem('akt_edu_address');
        if (this.props.address === '' && address === '') {
            this.props.history.push('/');
        }
        // if (this.props.address && this.props.denoms && this.props.denoms.length) {
        //     this.props.fetchUserNFTs(this.props.address, this.props.denoms, DEFAULT_SKIP, DEFAULT_LIMIT);
        // }

        // const scrollDiv = document.getElementById('scroll-bar');
        // scrollDiv.addEventListener('scroll', this.handleScroll);
    }

    // componentDidUpdate (pp, ps, ss) {
    //     if ((pp.address) !== (this.props.address) && this.props.denoms) {
    //         this.props.fetchUserNFTs(this.props.address, this.props.denoms, DEFAULT_SKIP, DEFAULT_LIMIT);
    //     }
    //     if (pp.denoms !== this.props.denoms && this.props.address) {
    //         this.props.fetchUserNFTs(this.props.address, this.props.denoms, DEFAULT_SKIP, DEFAULT_LIMIT);
    //     }
    // }

    componentWillUnmount () {
        // const scrollDiv = document.getElementById('scroll-bar');
        // scrollDiv.removeEventListener('scroll', this.handleScroll);
    }

    // handleScroll (e) {
    //     const scroll = e.target.scrollTop;
    //     const scrollHeight = e.target.scrollHeight;
    //     const height = e.target.clientHeight;
    //
    //     if ((this.props.nftSList.length < this.props.nftSTotal) &&
    //         ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
    //         this.props.address && !(this.props.nftSInProgress)) {
    //         this.props.fetchUserNFTs(this.props.address, config.GATED_ACCESS, this.props.nftSSkip + DEFAULT_LIMIT, this.props.nftSLimit);
    //         this.props.fetchUserNFTs(this.props.address, config.CERTIFICATE_DENOM, this.props.nftSSkip + DEFAULT_LIMIT, this.props.nftSLimit);
    //     }
    // }

    render () {
        return (
            <div className="my_nfts">
                <Header/>
                <div className="container">
                    {this.props.tabValue === 'my_nfts'
                        ? <div
                            className="owner_nfts lists">
                            {this.props.nftSList && (Object.keys(this.props.nftSList).length === 0) &&
                            this.props.nftSInProgress
                                ? <CircularProgress/>
                                : (this.props.nftSList && Object.keys(this.props.nftSList).length)
                                    ? <List/>
                                    : <NoData text="No NFTs found"/>}
                            {(this.props.nftSList && Object.keys(this.props.nftSList).length) && this.props.nftSInProgress
                                ? <CircularProgress type="nft-card"/> : null}
                        </div>
                        : this.props.tabValue === 'claimable'
                            ? <Claimable/>
                            : null}
                </div>
            </div>
        );
    }
}

MyNFTs.propTypes = {
    address: PropTypes.string.isRequired,
    denoms: PropTypes.array.isRequired,
    fetchUserNFTs: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    nftSInProgress: PropTypes.bool.isRequired,
    nftSLimit: PropTypes.number.isRequired,
    nftSList: PropTypes.object.isRequired,
    nftSSkip: PropTypes.number.isRequired,
    nftSTotal: PropTypes.number.isRequired,
    tabValue: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        lang: state.language,
        nftSList: state.myAccount.userNFTs.result,
        nftSInProgress: state.myAccount.userNFTs.inProgress,
        nftSTotal: state.myAccount.userNFTs.count,
        nftSSkip: state.myAccount.userNFTs.skip,
        nftSLimit: state.myAccount.userNFTs.limit,
        tabValue: state.myAccount.myAccountTab,
        denoms: state.navbar.videosList.denoms,
    };
};

const actionsToProps = {
    fetchUserNFTs,
};

export default withRouter(connect(stateToProps, actionsToProps)(MyNFTs));
