import { combineReducers } from 'redux';
import interactiveVideo from './interactiveVideo';
import snackbar from './snackbar';
import account from './account';
import language from './language';
import participant from './participant';
import navbar from './navbar';
import payment from './payment';
import gatedAccess from './gatedAccess';
import myAccount from './myAccount';
import courses from './courses';
import home from './home';

export default combineReducers({
    interactiveVideo,
    snackbar,
    account,
    language,
    participant,
    navbar,
    payment,
    gatedAccess,
    myAccount,
    courses,
    home,
});
