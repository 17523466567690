import * as PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../../components/TextField/InputProps';

const AnswerTextField = (props) => {
    const [focus, setFocus] = React.useState(false);

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        if (!props.value) {
            setFocus(false);
        }
    };

    const onChange = (value) => {
        props.onChange(value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onSubmit();
        }
    };

    return (
        <TextField
            className="description_text_field"
            id={props.id + '_text_field'}
            multiline={true}
            name={props.id}
            placeholder={focus ? '' : 'Enter Answer'}
            value={props.value}
            variant="outlined"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}/>
    );
};

AnswerTextField.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default AnswerTextField;
