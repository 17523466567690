import React from 'react';
import { IconButton } from '@material-ui/core';
import Icon from '../../../components/Icon';
import * as PropTypes from 'prop-types';
// import { config } from '../../../config';
// import { aminoSignTx, cosmoStationSign } from '../../../helper';

const PlayButton = (props) => {
    const handlePlayToggle = () => {
        props.player.play();
        // const id = props.options && props.options.match && props.options.match.params.id;
        // const video = props.options && props.options.list && props.options.list.length && props.options.list.find((val) => val._id === id);
        // if (props.options && props.options.collectionMyNFTs && !props.options.collectionMyNFTs.length &&
        //     video && video.akash_edu_config && video.akash_edu_config.denom_id) {
        //     if (!props.player.currentTime()) {
        //         handleInteractive(video);
        //     }
        // } else if (props.options && props.options.session && !props.options.session._id) {
        //     addSession(video);
        // } else {
        //     props.player.play();
        // }
    };

    const handlePauseToggle = () => {
        props.player.pause();
    };

    // const handleInteractive = (video) => {
    //     const address = localStorage.getItem('akt_edu_address');
    //     if (!address) {
    //         // props.showConnectAccountDialog(true);
    //         props.options.showMessage('Connect Account');
    //         return;
    //     }
    //
    //     props.options.showTokenGatedContentDialog();
    //     if (video.akash_edu_config && video.akash_edu_config.denom_id) {
    //         props.options.fetch(video.akash_edu_config.denom_id, address, (result) => {
    //             if (result && result.length) {
    //                 props.options.hideTokenGatedContentDialog();
    //                 addSession(video, address);
    //             }
    //         });
    //     }
    // };
    //
    // const addSession = (video, address) => {
    //     if (!props.options.deviceID) {
    //         props.options.fetchDeviceID((error, deviceID) => {
    //             if (error) {
    //                 return;
    //             }
    //
    //             const lockType = localStorage.getItem('akt_edu_connector');
    //             if (lockType === 'k') {
    //                 signWithCosmosAccount(deviceID, video, address);
    //             } else if (lockType === 'wc') {
    //                 walletConnectSign(deviceID, video, address);
    //             } else if (lockType === 'cs') {
    //                 handleCosmostation(deviceID, video, address);
    //             }
    //         });
    //     } else {
    //         const lockType = localStorage.getItem('akt_edu_connector');
    //         if (lockType === 'k') {
    //             signWithCosmosAccount(props.options.deviceID, video, address);
    //         } else if (lockType === 'wc') {
    //             walletConnectSign(props.options.deviceID, video, address);
    //         } else if (lockType === 'cs') {
    //             handleCosmostation(props.options.deviceID, video, address);
    //         }
    //     }
    // };
    //
    // const handleCosmostation = (deviceID, video, address) => {
    //     const tx = {
    //         msg: {
    //             type: 'omniflix/MsgSign',
    //             value: {
    //                 address: address,
    //             },
    //         },
    //         fee: {
    //             amount: [{
    //                 denom: config.COIN_MINIMAL_DENOM,
    //                 amount: '0',
    //             }],
    //             gas: '1',
    //         },
    //         memo: video._id,
    //     };
    //
    //     cosmoStationSign(tx, address, (error, result) => {
    //         if (error) {
    //             props.options.showMessage(error);
    //             return;
    //         }
    //
    //         props.options.addInteractiveVideoSession(video._id, {
    //             accountAddress: address,
    //             deviceFingerprint: deviceID,
    //             sign: {
    //                 pub_key: result && result.pub_key,
    //                 signature: result && result.signature,
    //             },
    //         }, (error) => {
    //             if (error) {
    //                 // props.options.showMessage('Error while adding session');
    //                 return;
    //             }
    //
    //             props.player.play();
    //         });
    //     });
    // };
    //
    // const signWithCosmosAccount = (deviceID, video, address) => {
    //     if (window.keplr) {
    //         window.keplr.defaultOptions = {
    //             sign: {
    //                 preferNoSetFee: true,
    //                 preferNoSetMemo: true,
    //             },
    //         };
    //     }
    //
    //     const tx = {
    //         msg: {
    //             type: 'omniflix/MsgSign',
    //             value: {
    //                 address: address,
    //             },
    //         },
    //         fee: {
    //             amount: [{
    //                 denom: config.COIN_MINIMAL_DENOM,
    //                 amount: '0',
    //             }],
    //             gas: '1',
    //         },
    //         memo: video._id,
    //     };
    //
    //     aminoSignTx(tx, address, (error, result) => {
    //         if (window.keplr) {
    //             window.keplr.defaultOptions = {};
    //         }
    //         if (error) {
    //             props.options.showMessage(error);
    //             return;
    //         }
    //
    //         props.options.addInteractiveVideoSession(video._id, {
    //             accountAddress: address,
    //             deviceFingerprint: deviceID,
    //             sign: result && result.signature,
    //         }, (error) => {
    //             if (error) {
    //                 // props.options.showMessage('Error while adding session');
    //                 return;
    //             }
    //
    //             props.player.play();
    //         });
    //     });
    // };
    //
    // const walletConnectSign = (deviceID, video, address) => {
    //     const tx = {
    //         msg: {
    //             type: 'omniflix/MsgSign',
    //             value: {
    //                 address: address,
    //             },
    //         },
    //         fee: {
    //             amount: [{
    //                 denom: config.COIN_MINIMAL_DENOM,
    //                 amount: '0',
    //             }],
    //             gas: '1',
    //         },
    //         preferNoSetFee: true,
    //         memo: video._id,
    //     };
    //
    //     props.options.walletConnectSign(props.options.walletConnector, tx, address, (result) => {
    //         if (result) {
    //             props.options.addInteractiveVideoSession(video._id, {
    //                 accountAddress: address,
    //                 deviceFingerprint: deviceID,
    //                 sign: result && result.signature,
    //             }, (error) => {
    //                 if (error) {
    //                     // props.options.showMessage('Error while adding session');
    //                     return;
    //                 }
    //
    //                 props.player.play();
    //             });
    //         }
    //     });
    // };

    return (
        <>
            <IconButton className="play_button" onClick={handlePlayToggle}>
                <Icon className="play" icon="play"/>
            </IconButton>
            <IconButton className="pause_button" onClick={handlePauseToggle}>
                <Icon className="pause" icon="pause"/>
            </IconButton>
        </>
    );
};

PlayButton.propTypes = {
    options: PropTypes.object.isRequired,
    player: PropTypes.object,
};

export default PlayButton;
