import { combineReducers } from 'redux';
import {
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    HIDE_SIDE_BAR_SET,
    META_MASK_NETWORKS_DIALOG_HIDE,
    META_MASK_NETWORKS_DIALOG_SHOW,
    SHOW_SIDE_BAR_SET,
    FETCH_VIDEOS_LIST_ERROR,
    FETCH_VIDEOS_LIST_IN_PROGRESS,
    FETCH_VIDEOS_LIST_SUCCESS,
} from '../constants/navbar';

const show = (state = false, action) => {
    switch (action.type) {
    case SHOW_SIDE_BAR_SET:
        return true;
    case HIDE_SIDE_BAR_SET:
        return false;
    default:
        return state;
    }
};

const metaMaskNetworkDialog = (state = false, action) => {
    switch (action.type) {
    case META_MASK_NETWORKS_DIALOG_SHOW:
        return true;
    case META_MASK_NETWORKS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_DIALOG_SHOW:
        return true;
    case CONNECT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const videosList = (state = {
    inProgress: false,
    value: [],
    courses: {},
    denoms: [],
}, action) => {
    switch (action.type) {
    case FETCH_VIDEOS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_VIDEOS_LIST_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
            courses: action.courses,
            denoms: action.denoms,
        };
    case FETCH_VIDEOS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    show,
    metaMaskNetworkDialog,
    connectDialog,
    videosList,
});
