import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import './index.css';
import section1 from '../../../assets/home/aboutSection/image1.png';
import section2 from '../../../assets/home/aboutSection/section2.svg';
import section3 from '../../../assets/home/aboutSection/section3.svg';
import variables from '../../../utils/variables';

const About = (props) => {
    const [selectedSection, setSelectedSection] = useState(1);

    const handleSectionClick = (section) => {
        setSelectedSection(section);
    };

    const getImage = () => {
        switch (selectedSection) {
        case 1:
            return section1;
        case 2:
            return section2;
        case 3:
            return section3;
        default:
            return section1;
        }
    };

    return (
        <div className="about_section">
            <div className="inner_about">
                <h2>{variables[props.lang].about_akash_edu}</h2>
                <div>
                    <div className="content">
                        <div
                            className={`left about_section1 ${selectedSection === 1 ? 'active' : ''}`}
                            onClick={() => handleSectionClick(1)}>
                            <div className="mobile_image_section">
                                <img alt="about" src={section1}/>
                            </div>
                            <h1>{variables[props.lang].akash_h1}</h1>
                            <p>{variables[props.lang].akash_p1}</p>
                        </div>
                        <div
                            className={`left about_section2 ${selectedSection === 2 ? 'active' : ''}`}
                            onClick={() => handleSectionClick(2)}>
                            <div className="mobile_image_section">
                                <img alt="about" src={section2}/>
                            </div>
                            <h1>{variables[props.lang].akash_h2}</h1>
                            <p>{variables[props.lang].akash_p2}</p>
                        </div>
                        <div
                            className={`left about_section3 ${selectedSection === 3 ? 'active' : ''}`}
                            onClick={() => handleSectionClick(3)}>
                            <div className="mobile_image_section">
                                <img alt="about" src={section3}/>
                            </div>
                            <h1>{variables[props.lang].akash_h3}</h1>
                            <p>{variables[props.lang].akash_p3}</p>
                        </div>
                    </div>
                    <div className="image_section">
                        <img alt="about" src={getImage()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

About.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(About);
