import Axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';

const calculateDeviceFingerprint = (cb) => {
    Fingerprint2.get({
        fonts: { extendedJsFonts: true },
        excludes: {
            userAgent: true,
            adBlock: true,
            enumerateDevices: true,
        },
    }, (components) => {
        const values = components.map((component) => {
            return component.value;
        });
        let fingerprint = Fingerprint2.x64hash128(values.join(''), 1);

        fetchIPInfo((error, info) => {
            if (error) {
                cb(null, fingerprint);
            } else {
                fingerprint = Fingerprint2.x64hash128(`${fingerprint}${info}`, 1);
                cb(null, fingerprint);
            }
        });
    });
};

const fetchIPInfo = (cb) => {
    Axios.get('https://extreme-ip-lookup.com/json')
        .then((ip) => {
            cb(null, ip.data);
        })
        .catch(cb);
};

export default calculateDeviceFingerprint;
