import { getWeb3Instance } from './web3';

import { ABI, address } from './conntract';

export const getContractInstance = (cb) => {
    getWeb3Instance((error, web3Instance) => {
        if (error) {
            cb(error);
        }

        if (web3Instance) {
            const contractInstance = new web3Instance.eth.Contract(ABI, address);
            cb(null, contractInstance);
        } else {
            const errorMessage = 'Error Occurred';
            cb(errorMessage);
        }
    });
};
