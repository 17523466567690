export const FETCH_USER_NFT_S_IN_PROGRESS = 'FETCH_USER_NFT_S_IN_PROGRESS';
export const FETCH_USER_NFT_S_ERROR = 'FETCH_USER_NFT_S_ERROR';
export const FETCH_USER_NFT_S_SUCCESS = 'FETCH_USER_NFT_S_SUCCESS';

export const CLEAR_USER_NFT_S_SET = 'CLEAR_USER_NFT_S_SET';

export const ASSET_WATCH_URL_FETCH_IN_PROGRESS = 'ASSET_WATCH_URL_FETCH_IN_PROGRESS';
export const ASSET_WATCH_URL_FETCH_SUCCESS = 'ASSET_WATCH_URL_FETCH_SUCCESS';
export const ASSET_WATCH_URL_FETCH_ERROR = 'ASSET_WATCH_URL_FETCH_ERROR';

export const MY_ACCOUNT_TAB_SET = 'MY_ACCOUNT_TAB_SET';
export const CLAIM_DIALOG_SHOW = 'CLAIM_DIALOG_SHOW';
export const CLAIM_DIALOG_HIDE = 'CLAIM_DIALOG_HIDE';
export const USER_NAME_VALUE_SET = 'USER_NAME_VALUE_SET';

export const PROJECTS_LIST_FETCH_IN_PROGRESS = 'PROJECTS_LIST_FETCH_IN_PROGRESS';
export const PROJECTS_LIST_FETCH_SUCCESS = 'PROJECTS_LIST_FETCH_SUCCESS';
export const PROJECTS_LIST_FETCH_ERROR = 'PROJECTS_LIST_FETCH_ERROR';

export const IV_PROJECTS_LIST_FETCH_IN_PROGRESS = 'IV_PROJECTS_LIST_FETCH_IN_PROGRESS';
export const IV_PROJECTS_LIST_FETCH_SUCCESS = 'IV_PROJECTS_LIST_FETCH_SUCCESS';
export const IV_PROJECTS_LIST_FETCH_ERROR = 'IV_PROJECTS_LIST_FETCH_ERROR';

export const MINT_NFT_IN_PROGRESS = 'MINT_NFT_IN_PROGRESS';
export const MINT_NFT_SUCCESS = 'MINT_NFT_SUCCESS';
export const MINT_NFT_ERROR = 'MINT_NFT_ERROR';

export const MINT_REQUEST_FETCH_IN_PROGRESS = 'MINT_REQUEST_FETCH_IN_PROGRESS';
export const MINT_REQUEST_FETCH_SUCCESS = 'MINT_REQUEST_FETCH_SUCCESS';
export const MINT_REQUEST_FETCH_ERROR = 'MINT_REQUEST_FETCH_ERROR';

export const MINT_QUEUE_FETCH_IN_PROGRESS = 'MINT_QUEUE_FETCH_IN_PROGRESS';
export const MINT_QUEUE_FETCH_SUCCESS = 'MINT_QUEUE_FETCH_SUCCESS';
export const MINT_QUEUE_FETCH_ERROR = 'MINT_QUEUE_FETCH_ERROR';

export const NFT_FETCH_IN_PROGRESS = 'NFT_FETCH_IN_PROGRESS';
export const NFT_FETCH_SUCCESS = 'NFT_FETCH_SUCCESS';
export const NFT_FETCH_ERROR = 'NFT_FETCH_ERROR';

export const ELIGIBLE_CHECK_IN_PROGRESS = 'ELIGIBLE_CHECK_IN_PROGRESS';
export const ELIGIBLE_CHECK_SUCCESS = 'ELIGIBLE_CHECK_SUCCESS';
export const ELIGIBLE_CHECK_ERROR = 'ELIGIBLE_CHECK_ERROR';

export const NFT_CLAIM_STATUS_CHECK_IN_PROGRESS = 'NFT_CLAIM_STATUS_CHECK_IN_PROGRESS';
export const NFT_CLAIM_STATUS_CHECK_SUCCESS = 'NFT_CLAIM_STATUS_CHECK_SUCCESS';
export const NFT_CLAIM_STATUS_CHECK_ERROR = 'NFT_CLAIM_STATUS_CHECK_ERROR';

export const CLAIM_STATUS_SET = 'CLAIM_STATUS_SET';
