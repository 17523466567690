import React from 'react';
import './index.css';

const MyAssets = () => {
    return (
        <div className="my_assets">
            <div>
                Coming Soon
            </div>
        </div>
    );
};

export default MyAssets;
