import React, { useEffect } from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIcon from '../../../assets/loading.png';
import { setClaimCertificateLoading } from '../../../actions/interactiveVideo';

const Loading = (props) => {
    const { mintInProgress, text } = props;

    useEffect(() => {
        if (!mintInProgress && text === 'Validating your score') {
            const timer = setTimeout(() => {
                props.setClaimCertificateLoading(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [mintInProgress, text]);

    return (
        <div className="loading">
            {props.LoadingText
                ? <p>{props.LoadingText}</p>
                : <p>{props.text}</p>}
            <img alt="loading" src={LoadingIcon}/>
        </div>
    );
};

Loading.propTypes = {
    mintInProgress: PropTypes.bool.isRequired,
    setClaimCertificateLoading: PropTypes.func.isRequired,
    LoadingText: PropTypes.string,
    text: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        text: state.interactiveVideo.claimCertificateDialog.loadingText,
        mintInProgress: state.myAccount.nftMint.inProgress,
    };
};

const actionToProps = {
    setClaimCertificateLoading,
};

export default connect(stateToProps, actionToProps)(Loading);
