import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { withRouter } from 'react-router';
import { fetchProjectsList, fetchUserNFTs } from '../../actions/myAccount';
import { videosList } from '../../actions/navbar';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';

class NavTabs extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: '',
        };
    }

    componentDidMount () {
        const route = this.props.location.pathname && this.props.location.pathname.split('/') &&
            this.props.location.pathname.split('/')[1];

        if (this.state.value !== route && (route === '' || route === 'courses')) {
            this.setState({
                value: route,
            });
        }

        if (this.props.list && !this.props.list.length && !this.props.inProgress) {
            this.props.videosList();
        }
        if (this.props.projectsList && !this.props.projectsList.length && !this.props.projectsListInProgress) {
            this.props.fetchProjectsList(this.props.address, (result) => {
                const denom = [];
                if (result && result.length) {
                    result.map((val) => {
                        if (val && val.denom && val.denom.id) {
                            denom.push(val.denom.id);
                        }
                    });

                    if (denom && denom.length && this.props.address) {
                        this.props.fetchUserNFTs(this.props.address, denom, DEFAULT_SKIP, DEFAULT_LIMIT);
                    }
                }
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.location.pathname !== this.props.location.pathname) {
            const value = this.props.location.pathname.split('/')[1];

            if (value !== this.state.value && (value === '' || value === 'account')) {
                this.setState({
                    value: value,
                });
            }
        }
        if ((pp.address) !== (this.props.address) && this.props.address && this.props.projectsList && this.props.projectsList.length) {
            const denom = [];
            this.props.projectsList.map((val) => {
                if (val && val.denom && val.denom.id) {
                    denom.push(val.denom.id);
                }
            });
            this.props.fetchUserNFTs(this.props.address, denom, DEFAULT_SKIP, DEFAULT_LIMIT);
        }
        if (pp.projectsList !== this.props.projectsList && this.props.projectsList.length && this.props.address) {
            const denom = [];
            this.props.projectsList.map((val) => {
                if (val && val.denom && val.denom.id) {
                    denom.push(val.denom.id);
                }
            });
            this.props.fetchUserNFTs(this.props.address, denom, DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }

    handleChange (newValue) {
        // if (this.props.address) {
        //     if (newValue === 'account' && this.props.nftSList && !this.props.nftSList.length && !this.props.nftSInProgress) {
        //         this.props.fetchUserNFTs(this.props.address, DEFAULT_SKIP, DEFAULT_LIMIT);
        //     }
        // }
        this.props.history.push('/' + newValue);
        this.setState({
            value: newValue,
        });
    }

    render () {
        const a11yProps = (index) => {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        };

        return (
            <AppBar className="horizontal_tabs" position="static">
                <Tabs aria-label="simple tabs example" className="tabs_content" value={this.state.value}>
                    <Tab
                        className={'tab ' + (this.state.value === '' ? 'active_tab' : '')}
                        label={variables[this.props.lang].home}
                        value=""
                        onClick={() => this.handleChange('')}
                        {...a11yProps(0)} />
                    <Tab
                        className={'tab ' + (this.state.value === 'courses' ? 'active_tab' : '')}
                        label={variables[this.props.lang].courses}
                        value="courses"
                        onClick={() => this.handleChange('courses')}
                        {...a11yProps(1)} />
                </Tabs>
            </AppBar>
        );
    }
}

NavTabs.propTypes = {
    address: PropTypes.string.isRequired,
    denoms: PropTypes.array.isRequired,
    fetchProjectsList: PropTypes.func.isRequired,
    fetchUserNFTs: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    nftSInProgress: PropTypes.bool.isRequired,
    nftSLimit: PropTypes.number.isRequired,
    // nftSList: PropTypes.array.isRequired,
    nftSSkip: PropTypes.number.isRequired,
    nftSTotal: PropTypes.number.isRequired,
    projectsList: PropTypes.array.isRequired,
    projectsListInProgress: PropTypes.bool.isRequired,
    videosList: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        lang: state.language,
        // nftSList: state.myAccount.userNFTs.result,
        nftSInProgress: state.myAccount.userNFTs.inProgress,
        nftSTotal: state.myAccount.userNFTs.count,
        nftSSkip: state.myAccount.userNFTs.skip,
        nftSLimit: state.myAccount.userNFTs.limit,
        list: state.navbar.videosList.value,
        inProgress: state.navbar.videosList.inProgress,
        denoms: state.navbar.videosList.denoms,
        projectsListInProgress: state.myAccount.projectsList.inProgress,
        projectsList: state.myAccount.projectsList.value,
    };
};

const actionsToProps = {
    fetchUserNFTs,
    videosList,
    fetchProjectsList,
};

export default withRouter(connect(stateToProps, actionsToProps)(NavTabs));
