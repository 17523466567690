import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RedditIcon from '../../../assets/social/new_reddit.png';
import TelegramIcon from '../../../assets/social/new_telegram.png';
import XIcon from '../../../assets/social/new_x.png';
import { IconButton, Tooltip, Button } from '@mui/material';
import { withRouter } from 'react-router';
import { hideClaimCertificateDialog } from '../../../actions/interactiveVideo';
import ClassNames from 'classnames';
import ImageOnLoad from '../../../components/ImageOnLoad/BackgroundImage';
import NoData from '../../../components/NoData';
import { MARKETPLACE_URL } from '../../../config';
import variables from '../../../utils/variables';
import { mediaReference } from '../../../utils/ipfs';
import thumbnail from '../../../assets/dummy/course_thumb.png';

const Certificate = (props) => {
    const handleShare = (value) => {
        if (props.onftId) {
            const pageUrl = encodeURIComponent(MARKETPLACE_URL + '/nft/' + props.onftId);
            const shareText = 'Check out my Course Completion NFT on AkashEDU powered by OmniFlix ⚛️%0A%0A';
            const targetUrl = value === 'Reddit'
                ? `https://www.reddit.com/submit?title=${shareText}&url=${pageUrl}`
                : value === 'X'
                    ? `https://twitter.com/intent/tweet?text=${shareText}&url=${pageUrl}`
                    : value === 'Telegram'
                        ? `https://t.me/share/url?text=${shareText}&url=${pageUrl}`
                        : undefined;

            window.open(targetUrl, '_blank');
        }
    };

    const handleExplore = () => {
        props.history.push('/courses');
        props.handleClose();
        props.setShowCertificate(false);
    };

    const handleRedirect = () => {
        if (props.onftId) {
            window.open(MARKETPLACE_URL + '/nft/' + props.onftId);
        }
    };

    const item = props.mintedNFT && props.mintedNFT.project;
    const inProgress = props.mintInProgress;

    return (
        <div className="certificate">
            {item && !inProgress
                ? <>
                    <div
                        className={ClassNames('list_page')}
                        onClick={handleRedirect}>
                        <ImageOnLoad
                            className={ClassNames('list_section2', item && item.nsfw ? 'blur' : '')}
                            preview={mediaReference(item && item.nft_meta && item.nft_meta.preview_uri) || thumbnail}
                            src={mediaReference(item && item.nft_meta && item.nft_meta.media_uri)}
                        />
                        <div className="list_section3">
                            <div className="list_section3_div">
                                <Tooltip
                                    title={(item.denom && item.denom.name) + ' | ' + (item.denom && item.denom.symbol)}>
                                    <span>
                                        {item.denom && item.denom.name}
                                    </span>
                                </Tooltip>
                            </div>
                            <Tooltip title={item && item.name}>
                                <span>
                                    {item && item.name}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="share_it">
                        <p>{variables[props.lang].share_it}</p>
                        <IconButton onClick={() => handleShare('X')}><img alt="twitter" src={XIcon}/></IconButton>
                        <IconButton onClick={() => handleShare('Telegram')}><img alt="telegram" src={TelegramIcon}/></IconButton>
                        <IconButton onClick={() => handleShare('Reddit')}><img alt="reddit" src={RedditIcon}/></IconButton>
                    </div>
                    <Button className="action" onClick={handleExplore}>{variables[props.lang].explore_more_courses}</Button>
                </>
                : <NoData/>}
        </div>
    );
};

Certificate.propTypes = {
    address: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    mintInProgress: PropTypes.bool.isRequired,
    mintedNFT: PropTypes.object.isRequired,
    onftId: PropTypes.string,
    setShowCertificate: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        mintedNFT: state.myAccount.nftMint.value,
        mintInProgress: state.myAccount.nftMint.inProgress,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideClaimCertificateDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(Certificate));
