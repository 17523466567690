import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { hideClaimCertificateDialog, setClaimCertificateLoading } from '../../../actions/interactiveVideo';
import Loading from './Loading';
import Chart from './Chart';
import Certificate from './Certificate';
import {
    fetchMintQueue,
    fetchMintRequest,
    fetchProjectsList,
    fetchUserNFTs,
    mintNFT,
    setClaimStatus,
    setMyAccountTab,
    showClaimDialog,
} from '../../../actions/myAccount';
import { showMessage } from '../../../actions/snackbar';
import DirectClaim from './DirectClaim';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const timerObj = {};

const ClaimCertificateDialog = (props) => {
    const [showCertificate, setShowCertificate] = React.useState(false);
    const [directCertificate, setDirectCertificate] = React.useState(false);
    const [onftId, setOnftId] = React.useState(null);
    const projectId = props.projectIdList && props.projectIdList.length && props.videoId &&
        props.projectIdList.find((item) => item.interactive_video_id === props.videoId);

    const handleClaim = (value, projectId) => {
        const projectID = projectId;
        const address = props.address;
        const interactiveVideoId = value;
        let participant = props.participant && props.participant[interactiveVideoId];
        participant = participant && participant.length && participant.find((val) => val && val.interactive_video &&
            val.interactive_video._id && (val.interactive_video._id === interactiveVideoId));

        const data = {
            address: address,
            quantity: 1,
            participantId: participant && participant._id,
        };

        props.mintNFT(projectID, data, (result) => {
            if (result) {
                setTimeout(() => {
                    if (timerObj.queueTimer) {
                        clearInterval(this.state.timerObj.queueTimer);
                    }

                    timerObj.queueTimer = setInterval(() => {
                        props.fetchMintQueue(address, projectID, (result) => {
                            if (result && result.length === 0) {
                                clearInterval(timerObj.queueTimer);
                                const mintRequestTime = setInterval(() => {
                                    props.fetchMintRequest(address, projectID, (result) => {
                                        if (result && result.length && result[0] && result[0].status &&
                                            result[0].status === 'COMPLETED') {
                                            if (result[0].nfts && result[0].nfts[0]) {
                                                setOnftId(result[0].nfts[0]);
                                            }
                                            if (props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_admission) {
                                                setDirectCertificate(true);
                                            } else {
                                                setShowCertificate(true);
                                            }
                                            props.setClaimCertificateLoading(false);
                                            clearInterval(mintRequestTime);
                                            props.showMessage('Minted Successfully', 'success');
                                            if (props.address) {
                                                props.fetchProjectsList(props.address, (result) => {
                                                    const denom = [];
                                                    if (result && result.length) {
                                                        result.map((val) => {
                                                            if (val && val.denom && val.denom.id) {
                                                                denom.push(val.denom.id);
                                                            }
                                                        });

                                                        if (denom && denom.length) {
                                                            props.fetchUserNFTs(props.address, denom, DEFAULT_SKIP, DEFAULT_LIMIT);
                                                        }
                                                    }
                                                });
                                            }
                                            this.setState({
                                                inProgress: false,
                                            });
                                            // props.showClaimDialog({}, '');
                                            props.setClaimStatus(true, false, '');
                                        } else if (result && result.length && result[0] && result[0].status &&
                                            result[0].status === 'FAILED') {
                                            if (props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_admission) {
                                                setDirectCertificate(false);
                                            } else {
                                                setShowCertificate(false);
                                            }
                                            props.setClaimCertificateLoading(false);
                                            clearInterval(mintRequestTime);
                                            props.showMessage('Mint job Failed', 'failed');
                                            props.handleClose();
                                        }
                                    });
                                }, 2000);
                            }
                        });
                    }, 2000);
                }, 2000);
            } else {
                // props.showClaimDialog({}, '');
                this.setState({
                    inProgress: false,
                });
                // props.setClaimStatus(false, true, '');
            }
        });
    };

    return (
        <Dialog className="claim_certificate_dialog" open={props.open} onClose={props.handleClose}>
            {props.isLoading
                ? props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_admission
                    ? <Loading LoadingText={'Getting your access ready'}/>
                    : <Loading/>
                : showCertificate
                    ? <Certificate onftId={onftId} setShowCertificate={setShowCertificate}/>
                    : props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_admission
                        ? <DirectClaim
                            handleClaim={handleClaim}
                            projectId={projectId}
                            setShowCertificate={setShowCertificate}
                            success={directCertificate}
                            videoId={props.videoId}/>
                        : <Chart
                            handleClaim={handleClaim}
                            projectId={projectId && projectId._id}
                            setShowCertificate={setShowCertificate}
                            video={props.video}
                            videoId={props.videoId}/>}
        </Dialog>
    );
};

ClaimCertificateDialog.propTypes = {
    address: PropTypes.string.isRequired,
    denoms: PropTypes.array.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchMintRequest: PropTypes.func.isRequired,
    fetchProjectsList: PropTypes.func.isRequired,
    fetchUserNFTs: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    mintNFT: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    participant: PropTypes.object.isRequired,
    percentage: PropTypes.string.isRequired,
    projectIdList: PropTypes.array.isRequired,
    requiredPercentage: PropTypes.string.isRequired,
    setClaimCertificateLoading: PropTypes.func.isRequired,
    setClaimStatus: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    videoId: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        open: state.interactiveVideo.claimCertificateDialog.open,
        isLoading: state.interactiveVideo.claimCertificateDialog.certificateLoading,
        participant: state.participant.participant.value,
        projectIdList: state.myAccount.ivProjectsList.value,
        denoms: state.navbar.videosList.denoms,
    };
};

const actionToProps = {
    handleClose: hideClaimCertificateDialog,
    fetchMintQueue,
    fetchMintRequest,
    setMyAccountTab,
    mintNFT,
    showMessage,
    showClaimDialog,
    setClaimStatus,
    setClaimCertificateLoading,
    fetchUserNFTs,
    fetchProjectsList,
};

export default connect(stateToProps, actionToProps)(ClaimCertificateDialog);
