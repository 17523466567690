import { list } from './config/network';
import { RPC_URL } from './constants/url';
import { SigningStargateClient } from '@cosmjs/stargate';
import { makeSignDoc } from '@cosmjs/amino';
import { chainConfig, config, walletExtensions } from './config';
import { cosmos, InstallError } from '@cosmostation/extension-client';
import { getOfflineSigner } from '@cosmostation/cosmos-client';
import { makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino/build/signdoc';
import { CosmjsOfflineSigner } from '@leapwallet/cosmos-snap-provider';

const { SigningCosmosClient } = require('@cosmjs/launchpad');

const chainId = config.CHAIN_ID;

const getChainConfig = (chainKey) => {
    const network = list[chainKey];

    return {
        chainId: network && network.chainId,
        chainName: network && network.chainName,
        rpc: network && network.rpc,
        rest: network && network.rest,
        stakeCurrency: {
            coinDenom: network && network.coinDenom,
            coinMinimalDenom: network && network.coinMinimalDenom,
            coinDecimals: network && network.coinDecimals,
        },
        bip44: {
            coinType: 118,
        },
        bech32Config: {
            bech32PrefixAccAddr: `${network && network.prefix}`,
            bech32PrefixAccPub: `${network && network.prefix}pub`,
            bech32PrefixValAddr: `${network && network.prefix}valoper`,
            bech32PrefixValPub: `${network && network.prefix}valoperpub`,
            bech32PrefixConsAddr: `${network && network.prefix}valcons`,
            bech32PrefixConsPub: `${network && network.prefix}valconspub`,
        },
        currencies: [
            {
                coinDenom: network && network.coinDenom,
                coinMinimalDenom: network && network.coinMinimalDenom,
                coinDecimals: network && network.coinDecimals,
            },
        ],
        feeCurrencies: [
            {
                coinDenom: network && network.coinDenom,
                coinMinimalDenom: network && network.coinMinimalDenom,
                coinDecimals: network && network.coinDecimals,
            },
        ],
        coinType: 118,
        gasPriceStep: {
            low: 0.001,
            average: 0.0025,
            high: 0.025,
        },
    };
};

export const initializeChain = (cb) => {
    (async () => {
        if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
            const error = 'Please install keplr extension';
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                window.open('keplrwallet://wcV1');
            } else if (/Android/i.test(navigator.userAgent)) {
                window.open('intent://wcV1#Intent;package=com.chainapsis.keplr;scheme=keplrwallet;end;');
            } else {
                window.open(walletExtensions.KEPLR);
            }
            cb(error);
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(chainConfig);
                    const offlineSigner = window.getOfflineSignerOnlyAmino(config.CHAIN_ID);
                    const accounts = await offlineSigner.getAccounts();

                    cb(null, accounts[0]);
                } catch (error) {
                    cb(error && error.message);
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                cb(versionError);
            }
        }
    })();
};

export const getConnectedAccounts = (cb) => {
    (async () => {
        if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
            const error = 'Please install keplr extension';
            cb(error);
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(getChainConfig('omniflix'));
                    const offlineSigner = window.getOfflineSignerOnlyAmino('omniflixhub-1');
                    const accounts = await offlineSigner.getAccounts();

                    const offlineSignerCosmos = window.getOfflineSignerOnlyAmino('cosmoshub-4');
                    const cosmosAccounts = await offlineSignerCosmos.getAccounts();

                    const offlineSignerSecret = window.getOfflineSignerOnlyAmino('secret-2');
                    const secretAccounts = await offlineSignerSecret.getAccounts();

                    cb(null, [accounts[0] && accounts[0].address,
                        cosmosAccounts[0] && cosmosAccounts[0].address,
                        secretAccounts[0] && secretAccounts[0].address],
                    );
                } catch (error) {
                    cb(error && error.message);
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                cb(versionError);
            }
        }
    })();
};

export const sendTx = (sender, recipient, amount, memo, chainKey, cb) => {
    const network = list[chainKey];

    amount = parseFloat(amount);
    if (isNaN(amount)) {
        const error = 'Invalid amount';
        cb(error);

        return false;
    }

    amount *= 1000000;
    amount = Math.floor(amount);

    (async () => {
        await window.keplr && window.keplr.enable(network && network.chainId);
        const offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(network && network.chainId);

        const accounts = await offlineSigner.getAccounts();

        const cosmJS = new SigningCosmosClient(
            network && network.rest,
            accounts[0].address,
            offlineSigner,
        );

        if (sender !== accounts[0].address) {
            const error = 'Sender account is not active in Keplr';
            cb(error);

            return false;
        }

        cosmJS.sendTokens(recipient, [
            {
                denom: 'uflix',
                amount: amount.toString(),
            },
        ], memo).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

export const signTx = (tx, address, chainKey, cb) => {
    (async () => {
        await window.keplr && window.keplr.enable(config.CHAIN_ID);
        const offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(config.CHAIN_ID);

        const cosmJS = new SigningCosmosClient(
            config.REST_URL,
            address,
            offlineSigner,
        );
        cosmJS.sign(tx.msg, tx.fee, tx.memo).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

export const aminoSignTx = (tx, address, cb) => {
    (async () => {
        await window.keplr && window.keplr.enable(config.CHAIN_ID);
        const offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(config.CHAIN_ID);

        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );

        const account = {};
        try {
            const {
                accountNumber,
                sequence,
            } = await client.getSequence(address);
            account.accountNumber = accountNumber;
            account.sequence = sequence;
        } catch (e) {
            account.accountNumber = 0;
            account.sequence = 0;
        }

        const signDoc = makeSignDoc(
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            config.CHAIN_ID,
            tx.memo,
            account.accountNumber,
            account.sequence,
        );

        offlineSigner.signAmino(address, signDoc).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

export const aminoSignTxLeap = (tx, address, cb) => {
    (async () => {
        await window.leap && window.leap.enable(config.CHAIN_ID);
        const offlineSigner = window.leap.getOfflineSignerOnlyAmino && window.leap.getOfflineSignerOnlyAmino(config.CHAIN_ID);

        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );

        const account = {};
        try {
            const {
                accountNumber,
                sequence,
            } = await client.getSequence(address);
            account.accountNumber = accountNumber;
            account.sequence = sequence;
        } catch (e) {
            account.accountNumber = 0;
            account.sequence = 0;
        }

        const signDoc = makeSignDoc(
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            config.CHAIN_ID,
            tx.memo,
            account.accountNumber,
            account.sequence,
        );

        offlineSigner.signAmino(address, signDoc).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

// Cosmostation
export const initializeCosmoStation = (cb) => {
    (async () => {
        try {
            const provider = await cosmos();
            const account = await provider.requestAccount(config.COSMOSTAION);
            cb(null, account);
        } catch (error) {
            if (error instanceof InstallError) {
                const error = 'Download the Cosmostation Extension';
                cb(error);
            } else if (error.code === 4001) {
                const error = 'user rejected request';
                cb(error);
            } else {
                cb(error.message);
            }
        }
    })();
};

export const cosmoStationSign = (tx, address, cb) => {
    (async () => {
        const offlineSigner = await getOfflineSigner(chainId);
        const client = await SigningStargateClient.connectWithSigner(
            RPC_URL,
            offlineSigner,
        );
        const provider = await cosmos();

        const account = {};
        try {
            const {
                accountNumber,
                sequence,
            } = await client.getSequence(address);
            account.accountNumber = accountNumber;
            account.sequence = sequence;
        } catch (e) {
            account.accountNumber = 0;
            account.sequence = 0;
        }

        const signDoc = makeSignDoc(
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            config.CHAIN_ID,
            tx.memo,
            account.accountNumber,
            account.sequence,
        );

        provider.signAmino(
            config.CHAIN_ID,
            signDoc,
        ).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

export const initializeMetaMaskCosmos = (cb) => {
    (async () => {
        if (!window.ethereum) {
            const error = 'Download the MetaMask Extension';
            cb(error);
        }

        if (window.ethereum) {
            const result = await window.ethereum.request({ method: 'wallet_getSnaps' });
            const installed = Object.keys(result).includes('npm:@cosmsnap/snap');

            // Install Snap
            if (!installed) {
                window.ethereum.request({
                    method: 'wallet_requestSnaps',
                    params: {
                        'npm:@cosmsnap/snap': {
                            version: '^0.1.0',
                        },
                    },
                }).then((result) => {
                    metamaskInitialize(cb);
                }).catch((error) => {
                    cb((error && error.message) || error);
                });
            }

            metamaskInitialize(cb);
        } else {
            return null;
        }
    })();
};

const metamaskInitialize = (cb) => {
    (async () => {
        try {
            const initialized = await window.ethereum.request({
                method: 'wallet_invokeSnap',
                params: {
                    snapId: 'npm:@cosmsnap/snap',
                    request: {
                        method: 'initialized',
                    },
                },
            });

            if (initialized && initialized.data && !initialized.data.initialized) {
                // Initialize the Snap with default chains
                window.ethereum.request({
                    method: 'wallet_invokeSnap',
                    params: {
                        snapId: 'npm:@cosmsnap/snap',
                        request: {
                            method: 'initialize',
                        },
                    },
                }).then((result) => {
                    window.ethereum.request({
                        method: 'wallet_invokeSnap',
                        params: {
                            snapId: 'npm:@cosmsnap/snap',
                            request: {
                                method: 'getChainAddress',
                                params: {
                                    chain_id: chainId,
                                },
                            },
                        },
                    }).then((result) => {
                        if (result && result.data) {
                            cb(null, result && result.data);
                        }
                    }).catch((error) => {
                        cb((error && error.message) || error);
                    });
                }).catch((error) => {
                    cb((error && error.message) || error);
                });

                return;
            }

            window.ethereum.request({
                method: 'wallet_invokeSnap',
                params: {
                    snapId: 'npm:@cosmsnap/snap',
                    request: {
                        method: 'getChainAddress',
                        params: {
                            chain_id: chainId,
                        },
                    },
                },
            }).then((result) => {
                if (result && result.data) {
                    cb(null, result && result.data);
                }
            }).catch((error) => {
                cb((error && error.message) || error);
            });
        } catch (error) {
            cb((error && error.message) || error);
        }
    })();
};

export const metaMaskSign = (tx, address, cb) => {
    (async () => {
        await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
        const offlineSigner = new CosmjsOfflineSigner(config.CHAIN_ID);
        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            window.ethereum.request({
                method: 'wallet_invokeSnap',
                params: {
                    snapId: 'npm:@cosmsnap/snap',
                    request: {
                        method: 'signAmino',
                        params: {
                            chain_id: chainId,
                            sign_doc: signDoc,
                            signer: address,
                        },
                    },
                },
            }).then((result) => {
                if (result && result.data) {
                    cb(null, result && result.data);
                }
            }).catch((error) => {
                if (error && error.message) {
                    cb(error && error.message);
                }
            });
        } catch (error) {
            if (error && error.message) {
                cb(error && error.message);
            }
        }
    })();
};
