import React from 'react';

export const floatDecimals = (count, decimals = 2) => {
    if (parseInt(count) === count) {
        return count;
    }

    return parseFloat(count).toFixed(decimals);
};

export const formatDuration = (duration) => {
    const parts = duration.toString().split('.');

    if (parts.length === 2) {
        const distance = parts[0] * 1000;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const time = {
            days,
            hours,
            minutes,
            seconds,
        };

        return (
            <>
                {time && time.days ? time.days + 'd : ' : ''}
                {time && time.hours ? time.hours + 'h : '
                    : time && time.days && !time.hours
                        ? '00h : '
                        : ''}
                {time && time.minutes ? time.minutes + 'm '
                    : time && time.hours && !time.minutes
                        ? '00m '
                        : ''}
                {time && time.days > 0
                    ? null
                    : time && time.minutes && time.seconds
                        ? `: ${time.seconds > 9 ? time.seconds : `0${time.seconds}`}s `
                        : time && time.minutes && !time.seconds
                            ? ':00s '
                            : time && !time.minutes && time.seconds
                                ? `00h : ${time.seconds > 9 ? time.seconds : `0${time.seconds}`}s `
                                : '00s '}
            </>
        );
    }

    const distance = duration * 1000;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const time = {
        days,
        hours,
        minutes,
        seconds,
    };

    return (
        <>
            {time && time.days ? time.days + 'd : ' : ''}
            {time && time.hours ? time.hours + 'h : '
                : time && time.days && !time.hours
                    ? '00h : '
                    : ''}
            {time && time.minutes ? time.minutes + 'M '
                : time && time.hours && !time.minutes
                    ? '00m '
                    : ''}
            {time && time.days > 0
                ? null
                : time && time.minutes && time.seconds
                    ? `: ${time.seconds > 10 ? time.seconds : `0${time.seconds}`}s `
                    : time && time.minutes && !time.seconds
                        ? ': 00s '
                        : time && !time.minutes && time.seconds
                            ? `00h : ${time.seconds > 10 ? time.seconds : `0${time.seconds}`}s `
                            : '00s '}
        </>
    );
};
