import React from 'react';
import './index.css';
import variables from '../../utils/variables';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import courseIcon from '../../assets/home/new_course.png';
import quizIcon from '../../assets/home/new_quiz.png';
import nftIcon from '../../assets/home/new_nft.png';
import completionIcon from '../../assets/home/new_completion.png';

const Certification = (props) => {
    const list = [{
        name: variables[props.lang].enroll_p1,
        image: courseIcon,
    }, {
        name: variables[props.lang].enroll_p2,
        image: quizIcon,
    }, {
        name: variables[props.lang].enroll_p3,
        image: completionIcon,
    }, {
        name: variables[props.lang].enroll_p4,
        image: nftIcon,
    }];
    return (
        <div className="certification_background">
            <div className="certification">
                <h2>{variables[props.lang]['enroll_today']}</h2>
                <div className="steps_list">
                    {list.map((item, index) => {
                        return (
                            <div key={index} className="step_data">
                                <img alt={item.name} src={item.image}/>
                                <p>{item.name}</p>
                            </div>
                        );
                    })}
                </div>
                <span className="border"/>
            </div>
        </div>
    );
};

Certification.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Certification);
