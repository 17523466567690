import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { hideConnectAccountDialog } from '../../../actions/navbar';
import ConnectKeplrButton from './ConnectKeplrButton';
import MetaMaskButton from './MetaMaskButton';

const ConnectDialog = (props) => {
    const handleClose = () => {
        if (props.videoId && props.sign) {
            return;
        }

        props.handleClose();
    };

    return (
        <Dialog
            aria-describedby="account-connect-dialog-description"
            aria-labelledby="account-connect-dialog-title"
            className="account_dialog"
            open={props.open}
            onClose={handleClose}>
            <DialogContent className="content">
                <p className="heading">
                    Connect your account with any of the Wallet
                </p>
                <ConnectKeplrButton handleClose={props.handleClose} videoId={props.videoId}/>
                <p className="heading">OR</p>
                <MetaMaskButton handleClose={props.handleClose} videoId={props.videoId}/>
                <p className="info">
                    New User? Download from below link
                    and add to your chrome extension
                </p>
            </DialogContent>
            <DialogActions className="button_div">
                <a
                    className="keplr_link"
                    href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
                    rel="noopener noreferrer"
                    target="_blank">
                    Download Keplr
                </a>
                <span className="border_break"/>
                <a
                    className="meta_mask_link"
                    href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                    rel="noopener noreferrer"
                    target="_blank">
                    Download Metamask
                </a>
            </DialogActions>
        </Dialog>
    );
};

ConnectDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    sign: PropTypes.bool.isRequired,
    videoId: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.account.connectDialog.open,
        sign: state.account.connectDialog.sign,
    };
};

const actionToProps = {
    handleClose: hideConnectAccountDialog,
};

export default connect(stateToProps, actionToProps)(ConnectDialog);
