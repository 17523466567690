import ethereum from '../assets/networks/ethereum.svg';
import polygon from '../assets/networks/polygon.svg';
import { toHex } from './encrypt';
import binance from '../assets/networks/binance.svg';
import okx from '../assets/networks/okx.png';

export const networksList = [{
    img: ethereum,
    name: 'Ethereum Mainnet',
    key: 'ethereum',
    code: 'me',
}, {
    img: polygon,
    name: 'Polygon',
    key: 'polygon',
    code: 'mp',
    params: {
        chainId: toHex(137),
        chainName: 'Matic Mainnet',
        nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: [
            'https://rpc-mainnet.matic.network',
            'wss://ws-mainnet.matic.network',
        ],
        blockExplorerUrls: null,
    },
}, {
    img: binance,
    name: 'Binance Smart Chain',
    key: 'binance',
    code: 'mb',
    params: {
        chainId: toHex(56),
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
            name: 'Binance Chain Native Token',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: [
            'https://bsc-dataseed1.binance.org',
            'https://bsc-dataseed2.binance.org',
            'https://bsc-dataseed3.binance.org',
            'https://bsc-dataseed4.binance.org',
            'https://bsc-dataseed1.defibit.io',
            'https://bsc-dataseed2.defibit.io',
            'https://bsc-dataseed3.defibit.io',
            'https://bsc-dataseed4.defibit.io',
            'https://bsc-dataseed1.ninicoin.io',
            'https://bsc-dataseed2.ninicoin.io',
            'https://bsc-dataseed3.ninicoin.io',
            'https://bsc-dataseed4.ninicoin.io',
            'wss://bsc-ws-node.nariox.org',
        ],
        blockExplorerUrls: ['https://bscscan.com'],
    },
}, {
    img: okx,
    name: 'OKX Chain',
    key: 'okx',
    code: 'mokx',
    params: {
        chainId: toHex(66),
        chainName: 'OKXChain Mainnet',
        nativeCurrency: {
            name: 'OKXChain',
            symbol: 'OKT',
            decimals: 18,
        },
        rpcUrls: [
            'https://exchainrpc.okex.org',
        ],
        blockExplorerUrls: ['https://www.oklink.com/en/okc'],
    },
}];
