import React, { useLayoutEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import InteractiveVideos from './containers/InteractiveVideos';
import InteractiveVideo from './containers/InteractiveVideo';
import NavBar from './containers/NavBar';
import MyAssets from './containers/MyAssets';
import Accounts from './containers/Accounts';
import SendTokens from './containers/Accounts/SendTokenDialog';
import Sign from './containers/Accounts/Sign';
import VideosList from './containers/VideosList';
import CourseVideo from './containers/VideoPlayer';
import MyNFTs from './containers/MyAccount';
import Courses from './containers/Courses';
import SingleCourse from './containers/SingleCourse';
import Footer from './containers/VideosList/Footer';
import PageNotFound from './components/PageNotFound';

const routes = [{
    path: '/interactive-videos',
    component: InteractiveVideos,
}, {
    path: '/interactive-videos/:id',
    component: InteractiveVideo,
}, {
    path: '/assets',
    component: MyAssets,
}, {
    path: '/riddlethon',
    component: Accounts,
}, {
    path: '/buy',
    component: SendTokens,
}, {
    path: '/sign',
    component: Sign,
}, {
    path: '/',
    component: VideosList,
}, {
    path: '/videos/:id',
    component: CourseVideo,
}, {
    path: '/account',
    component: MyNFTs,
}, {
    path: '/courses',
    component: Courses,
}, {
    path: '/course/:id',
    component: SingleCourse,
}];

const Router = () => {
    const location = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        const scrollDiv = document.getElementById('scroll-bar');
        scrollDiv.scrollTop = 0;
    }, [location.pathname]);

    return (
        <div className="main_content">
            <NavBar/>
            <div className="content_div scroll_bar" id="scroll-bar">
                <Switch>
                    {routes.map((route) =>
                        <Route
                            key={route.path}
                            exact
                            component={route.component}
                            path={route.path}/>,
                    )}
                    <Route component={PageNotFound} />
                </Switch>
                <Footer/>
            </div>
        </div>
    );
};

export default Router;
