import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import { hideConnectDialog } from '../../../actions/navbar';
import variables from '../../../utils/variables';
import KeplrIcon from '../../../assets/logos/keplr.png';
import { aminoSignTx, initializeChain } from '../../../helper';
import { arrayToBase64 } from '../../../utils/encrypt';
import { config, walletExtensions } from '../../../config';
import { showMessage } from '../../../actions/snackbar';
import { setPublicKey } from '../../../actions/account';
import { addInteractiveVideoSession, setAccountAddress } from '../../../actions/interactiveVideo';
// import WalletConnectButton from './WalletConnectButton';
import CosmostationButton from './CosmostationButton';
import LeapWallet from './LeapWallet';
import downloadIcon from '../../../assets/logos/download.svg';
import MetaMaskLeapSnaps from './MetaMaskLeapSnaps';
import MetaMaskCosmosSnaps from './MetaMaskCosmosSnaps';

const ConnectAccountDialog = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initKeplr = () => {
        setInProgress(true);
        initializeChain((error, account) => {
            setInProgress(false);
            if (error) {
                props.showMessage(error);
                return;
            }

            const address = account.address;

            localStorage.setItem('akt_edu_connector', 'k');
            localStorage.setItem('akt_edu_nc', config.PREFIX);
            if (account.pubkey) {
                props.setPublicKey(arrayToBase64(account.pubkey));
            }
            props.setAccountAddress(address);
            localStorage.setItem('akt_edu_address', address);
            props.handleClose();

            if (props.videoId && props.sign) {
                if (window.keplr) {
                    window.keplr.defaultOptions = {
                        sign: {
                            preferNoSetFee: true,
                            preferNoSetMemo: true,
                        },
                    };
                }

                const tx = {
                    msg: {
                        type: 'omniflix/MsgSign',
                        value: {
                            address: address,
                        },
                    },
                    fee: {
                        amount: [{
                            denom: config.COIN_MINIMAL_DENOM,
                            amount: '0',
                        }],
                        gas: '1',
                    },
                    memo: props.videoId,
                };

                aminoSignTx(tx, address, (error, result) => {
                    if (window.keplr) {
                        window.keplr.defaultOptions = {};
                    }
                    if (error) {
                        props.showMessage(error);
                        return;
                    }

                    props.addInteractiveVideoSession(props.videoId, {
                        accountAddress: address,
                        deviceFingerprint: props.deviceId,
                        sign: result && result.signature,
                    }, (error) => {
                        if (error) {
                            // props.showMessage('Error while adding session');
                            return;
                        }

                        props.player.play();
                    });
                });
            }
        });
    };

    const handleDownloadWallet = (event, wallet) => {
        event.stopPropagation();
        if (wallet === 'keplr') {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                // Take the user to a different screen here.
                window.open('keplrwallet://wcV1');
            } else if (/Android/i.test(navigator.userAgent)) {
                window.open('intent://wcV1#Intent;package=com.chainapsis.keplr;scheme=keplrwallet;end;');
            } else {
                window.open(walletExtensions.KEPLR);
            }
        } else if (wallet === 'cosmostation') {
            window.open(walletExtensions.COSMO_STATION);
        } else if (wallet === 'leap') {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.open('https://leapcosmoswallet.page.link/fiLDQBCHaF7wYijx8');
            } else {
                window.open(walletExtensions.LEAP);
            }
        } else if (wallet === 'metamask') {
            window.open(walletExtensions.META_MASK);
        }
    };

    return (
        <Dialog
            aria-describedby="account-connect-dialog-description"
            aria-labelledby="account-connect-dialog-title"
            className="connect_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="connect_section">
                <h2>{variables[props.lang]['connect_your_wallet']}</h2>
                <p>{variables[props.lang]['connect_wallet_content']}</p>
                <div className="cosmo_section">
                    <MetaMaskLeapSnaps/>
                    <Button onClick={(e) => handleDownloadWallet(e, 'metamask')}>
                        <img alt="Download Icon" src={downloadIcon}/>
                    </Button>
                </div>
                <div className="cosmo_section">
                    <MetaMaskCosmosSnaps/>
                    <Button onClick={(e) => handleDownloadWallet(e, 'metamask')}>
                        <img alt="Download Icon" src={downloadIcon}/>
                    </Button>
                </div>
                <div className="keplr_section">
                    <Button onClick={initKeplr}>
                        <img alt="KeplrIcon" src={KeplrIcon}/>
                        {inProgress
                            ? 'Connecting...'
                            : variables[props.lang].keplr}
                    </Button>
                    <Button onClick={(e) => handleDownloadWallet(e, 'keplr')}>
                        <img alt="Download Icon" src={downloadIcon}/>
                    </Button>
                </div>
                <div className="cosmo_section">
                    <CosmostationButton/>
                    <Button onClick={(e) => handleDownloadWallet(e, 'cosmostation')}>
                        <img alt="Download Icon" src={downloadIcon}/>
                    </Button>
                </div>
                <div className="leap_section">
                    <LeapWallet/>
                    <Button onClick={(e) => handleDownloadWallet(e, 'leap')}>
                        <img alt="Download Icon" src={downloadIcon}/>
                    </Button>
                </div>
                {/* <div className="meta_mask_section"> */}
                {/*    <MetaMaskLeapSnaps/> */}
                {/*    <Button onClick={() => window.open('')}> */}
                {/*        <img alt="Download Icon" src={downloadIcon}/> */}
                {/*    </Button> */}
                {/* </div> */}
                {/* <div className="meta_mask_section"> */}
                {/*    <MetaMaskCosmosSnaps/> */}
                {/*    <Button onClick={() => window.open('')}> */}
                {/*        <img alt="Download Icon" src={downloadIcon}/> */}
                {/*    </Button> */}
                {/* </div> */}
                {/* <div className="wallet_connect"> */}
                {/*    <WalletConnectButton/> */}
                {/*    /!* <img alt="Download Icon" src={downloadIcon}/> *!/ */}
                {/* </div> */}
            </div>
        </Dialog>
    );
};

ConnectAccountDialog.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setPublicKey: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.bool.isRequired,
    deviceId: PropTypes.string,
    player: PropTypes.object,
    videoId: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        deviceId: state.interactiveVideo.deviceID.id,
        lang: state.language,
        open: state.navbar.connectDialog,
        sign: state.account.connectDialog.sign,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    addInteractiveVideoSession,
    handleClose: hideConnectDialog,
    showMessage,
    setAccountAddress,
    setPublicKey,
};

export default connect(stateToProps, actionToProps)(ConnectAccountDialog);
