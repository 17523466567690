import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
// import variables from '../../../utils/variables';
import sectionIcon from '../../../assets/header_banner.png';
import List from '../List';
import ShareDialog from '../ShareDialog';

const Header = (props) => {
    return (
        <div className="header">
            <div className="inner_header">
                <img alt="info" src={sectionIcon} />
                {/* <div className="content1"> */}
                {/*    <span>{variables[props.lang]['head_content1']}</span><br/> */}
                {/*    <span className="d_color">{variables[props.lang]['akash_edu']}</span><br/> */}
                {/*    <span>{variables[props.lang]['head_content2']}</span> */}
                {/*    <span className="d_color">{variables[props.lang].nfts}</span> */}
                {/*    <span>{variables[props.lang]['head_content3']}</span> */}
                {/* </div> */}
                <List />
            </div>
            <ShareDialog/>
        </div>
    );
};

Header.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default withRouter(connect(stateToProps)(Header));
