import React from 'react';
import { IconButton } from '@material-ui/core';
import Icon from '../../../components/Icon';
import * as PropTypes from 'prop-types';
import {
    addInteractiveVideoSession,
    fetchDeviceID,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
} from '../../../actions/interactiveVideo';
import { showMessage } from '../../../actions/snackbar';
import { fetchCollectionMyNFTs } from '../../../actions/gatedAccess';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '../../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign } from '../../../helper';
import { walletConnectSign } from '../../../actions/account/wallet';

const PlayButton = (props) => {
    const handlePlayToggle = () => {
        const id = props.match && props.match.params.id;
        const video = props.list && props.list.length && props.list.find((val) => val._id === id);
        if (props.interactive && props.collectionMyNFTs && !props.collectionMyNFTs.length &&
            video && video.akash_edu_config && video.akash_edu_config.denom_id) {
            if (!props.player.currentTime()) {
                handleInteractive(video);
            }
        } else if (props.session && !props.session._id) {
            addSession(video);
        } else {
            props.player.play();
        }
    };

    const handlePauseToggle = () => {
        props.player.pause();
    };

    const handleInteractive = (video) => {
        if (!props.address) {
            // props.showConnectAccountDialog(true);
            props.showMessage('Connect Account');
            return;
        }

        props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            props.fetch(video.akash_edu_config.denom_id, props.address, (result) => {
                if (result && result.length) {
                    props.hideTokenGatedContentDialog();
                    addSession(video);
                }
            });
        }
    };

    const addSession = (video) => {
        if (!props.deviceID) {
            props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    handleLeap(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                signWithCosmosAccount(props.deviceID, video);
            } else if (lockType === 'wc') {
                walletConnectSign(props.deviceID, video);
            } else if (lockType === 'cs') {
                handleCosmostation(props.deviceID, video);
            } else if (lockType === 'leap') {
                handleLeap(props.deviceID, video);
            }
        }
    };

    const handleCosmostation = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, props.address, (error, result) => {
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.player.play();
            });
        });
    };

    const handleLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.player.play();
            });
        });
    };

    const signWithCosmosAccount = (deviceID, video) => {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                props.player.play();
            });
        });
    };

    const walletConnectSign = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        props.walletConnectSign(props.walletConnector, tx, props.address, (result) => {
            if (result) {
                props.addInteractiveVideoSession(video._id, {
                    accountAddress: props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // props.showMessage('Error while adding session');
                        return;
                    }

                    props.player.play();
                });
            }
        });
    };

    return (
        <>
            <IconButton className="play_button" onClick={handlePlayToggle}>
                <Icon className="play" icon="play"/>
            </IconButton>
            <IconButton className="pause_button" onClick={handlePauseToggle}>
                <Icon className="pause" icon="pause"/>
            </IconButton>
        </>
    );
};

PlayButton.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    collectionMyNFTs: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    showMessage: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    interactive: PropTypes.bool,
    player: PropTypes.object,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        list: state.navbar.videosList.value,
        collectionMyNFTs: state.gatedAccess.collectionMyNFTs.result,
        deviceID: state.interactiveVideo.deviceID.id,
        walletConnector: state.account.wallet.connection.walletConnector,
        session: state.interactiveVideo.session.data,
    };
};

const actionsToProps = {
    addInteractiveVideoSession,
    showMessage,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
    fetch: fetchCollectionMyNFTs,
    fetchDeviceID,
    walletConnectSign,
};

export default withRouter(connect(stateToProps, actionsToProps)(PlayButton));
