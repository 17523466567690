import React from 'react';
import { Button } from '@material-ui/core';
import metamask from '../../../assets/metamask.png';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showMetaMaskNetworkDialog } from '../../../actions/navbar';

const MetaMaskButton = (props) => {
    const handleClick = () => {
        props.show();
        props.handleClose();
    };

    return (
        <Button className="meta_mask" onClick={handleClick}>
            <img alt="meta mask" src={metamask}/>
            Connect with Metamask
        </Button>
    );
};

MetaMaskButton.propTypes = {
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
};

const actionToProps = {
    show: showMetaMaskNetworkDialog,
};

export default connect(null, actionToProps)(MetaMaskButton);
