import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import TextType from './TextType';
import MCQType from './MCQType';

const Preview = (props) => {
    return (
        <div
            className="preview"
            id={`interaction_buttons-${props.index}`}>
            <p className="heading_text">
                {props.value.question && props.value.question.title}
            </p>
            {props.value.question.type === 'MCQ_SA'
                ? <MCQType index={props.index} value={props.value}/>
                : props.value.question.type === 'POLL'
                    ? <MCQType value={props.value}/>
                    : props.value.question.type === 'TEXT_INPUT'
                        ? <TextType value={props.value}/>
                        : null}
        </div>
    );
};

Preview.propTypes = {
    currentInteraction: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    player: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
    };
};

export default connect(stateToProps)(Preview);
