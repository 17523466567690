import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
import Section1 from './Section1';
import variables from '../../../utils/variables';
import { ReactComponent as Twitter } from '../../../assets/social/twitter.svg';
import { ReactComponent as Discord } from '../../../assets/social/discord.svg';
import { ReactComponent as Reddit } from '../../../assets/social/reddit.svg';
import { ReactComponent as Youtube } from '../../../assets/social/youtube.svg';
import { ReactComponent as Telegram } from '../../../assets/social/telegram.svg';
import { ReactComponent as LinkedIn } from '../../../assets/social/linkedin.svg';
import { ReactComponent as OmniflixLogo } from '../../../assets/omniFlix.svg';
import { ReactComponent as AktLogo } from '../../../assets/akt_logo.svg';

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="inner_section">
                <Section1/>
                <div className="section2">
                    <h2>{variables[props.lang].developers}</h2>
                    <div className="content1">
                        <p onClick={() => window.open('https://akash.network/')}>
                            {variables[props.lang].website}</p>
                        <p onClick={() => window.open('https://docs.akash.network/')}>
                            {variables[props.lang].docs}</p>
                        <p onClick={() => window.open('https://github.com/ovrclk')}>
                            {variables[props.lang].github}</p>
                        <p onClick={() => window.open('https://ipfs.io/ipfs/QmVwsi5kTrg7UcUEGi5UfdheVLBWoHjze2pHy4tLqYvLYv')}>
                            {variables[props.lang].whitepaper}</p>
                        {/* <p onClick={() => window.open('https://akash.network/connect')}> */}
                        {/*     {variables[props.lang].connect}</p> */}
                    </div>
                </div>
                <div className="section3">
                    <h2>{variables[props.lang].socials}</h2>
                    <div className="content1">
                        <p onClick={() => window.open('https://twitter.com/akashnet_')}>
                            <Twitter/>
                            {variables[props.lang].twitter}
                        </p>
                        <p onClick={() => window.open('https://discord.com/invite/akash')}>
                            <Discord/>
                            {variables[props.lang].discord}
                        </p>
                        <p onClick={() => window.open('https://www.reddit.com/r/akashnetwork/')}>
                            <Reddit/>
                            {variables[props.lang].reddit}
                        </p>
                        <p onClick={() => window.open('https://www.youtube.com/akashnetwork/?themeRefresh=1')}>
                            <Youtube/>
                            {variables[props.lang].youtube}
                        </p>
                        <p onClick={() => window.open('https://t.me/AkashNW')}>
                            <Telegram/>
                            {variables[props.lang].telegram}
                        </p>
                        <p onClick={() => window.open('https://www.linkedin.com/company/akash-network/')}>
                            <LinkedIn/>
                            {variables[props.lang].linkedIn}
                        </p>
                    </div>
                </div>
                <div className="section4">
                    <div className="content1">
                        <p>{variables[props.lang]['powered_by']}</p>
                        <OmniflixLogo onClick={() => window.open('https://omniflix.network')}/>
                    </div>
                    <div className="content2">
                        <p>{variables[props.lang]['in_association_with']}</p>
                        <AktLogo onClick={() => window.open('https://akash.network')}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default withRouter(connect(stateToProps)(Footer));
