import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    completeInteractiveVideoSession,
    hideInteractionTime,
    saveInteractiveVideoAnswer,
    setCurrentInteraction,
    setInteractiveSave,
    setQuestionType,
} from '../../../actions/interactiveVideo';
import AnswerTextField from './AnswerTextField';
import { Button } from '@material-ui/core';
import { removeInteraction } from '../../../utils/interaction';
import { showMessage } from '../../../actions/snackbar';
import { fetchParticipant, getInteractiveVideoParticipants } from '../../../actions/participant';
import { checkNFTClaimStatus, eligibleCheck, fetchIVProjectsList, fetchNFT } from '../../../actions/myAccount';

const TextType = (props) => {
    const handleChange = (value) => {
        const questionId = props.value && props.value.question && props.value.question._id;
        const obj = {
            ...props.answers,
            [questionId]: value,
        };

        props.onChange(obj);
    };

    const handleSave = () => {
        if (!(props.session && props.session._id)) {
            props.showMessage('Session does not exists');
            return;
        }

        const questionId = props.value && props.value.question && props.value.question._id;
        if (questionId) {
            const selectedAnswers = Object.values(props.answers);

            if (!selectedAnswers[0]) {
                props.showMessage('Did not got the answer value');
                return;
            }

            props.saveAnswer(props.session && props.session.interactive_video,
                props.session && props.session._id,
                {
                    questionId,
                    answers: selectedAnswers,
                    // as we have only single input answer, we are sending directly.
                }, (error) => {
                    if (error) {
                        return;
                    }

                    if (removeInteraction(props.currentInteraction)) {
                        props.setCurrentInteraction(props.currentInteraction + 1);
                    } else {
                        props.showMessage('Failed to remove interaction');
                    }

                    if (props.currentInteraction >= props.interactions.length - 1) {
                        endCurrentSession();
                    }
                    props.player.play();
                });
        }

        props.hideInteractionTime();
    };

    const endCurrentSession = () => {
        props.endSession(props.session && props.session.interactive_video,
            props.session && props.session._id, {
                accountAddress: props.address,
                // signature,
            }, (error) => {
                if (error) {
                    return;
                }
                props.getParticipants(props.session && props.session.interactive_video);
                props.fetchIVProjectsList(props.address, props.session && props.session.interactive_video, (result) => {
                    if (result && result.length) {
                        result.map((value) => {
                            if (value && value.nfts && value.nfts.length) {
                                props.fetchNFT(value.nfts);
                                if (props.address && value && value.denom && value.denom.id) {
                                    props.checkNFTClaimStatus(value.denom.id, props.address);
                                }
                            }

                            props.fetchParticipant(value.interactive_video_id, props.address, (val) => {
                                if (val && val.length && val[0] && val[0]._id) {
                                    props.eligibleCheck(value.interactive_video_id, val[0]._id);
                                }
                            });

                            return null;
                        });
                    }
                });
            });
    };

    const questionId = props.value && props.value.question && props.value.question._id;
    const disable = (props.answers && props.answers[questionId]) === '' ||
        !(props.answers && props.answers[questionId]);

    return (
        <div className="options_section">
            <form
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <AnswerTextField
                    id={questionId}
                    value={props.answers && props.answers[questionId]}
                    onChange={handleChange}
                    onSubmit={handleSave}/>
                <div className="submit_div">
                    <Button
                        className="submit_button"
                        disabled={disable}
                        onClick={handleSave}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

TextType.propTypes = {
    checkNFTClaimStatus: PropTypes.func.isRequired,
    currentInteraction: PropTypes.number.isRequired,
    eligibleCheck: PropTypes.func.isRequired,
    endSession: PropTypes.func.isRequired,
    fetchIVProjectsList: PropTypes.func.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    fetchParticipant: PropTypes.func.isRequired,
    getParticipants: PropTypes.func.isRequired,
    hideInteractionTime: PropTypes.func.isRequired,
    saveAnswer: PropTypes.func.isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    address: PropTypes.string,
    answers: PropTypes.object,
    interactions: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.number.isRequired,
            time_limit: PropTypes.number.isRequired,
            answers: PropTypes.array,
        }),
    ),
    player: PropTypes.object,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        answers: state.interactiveVideo.answers,
        session: state.interactiveVideo.session.data,
        currentInteraction: state.interactiveVideo.currentInteraction,
        address: state.account.address,
        interactions: state.interactiveVideo._.interactions,
    };
};

const actionToProps = {
    onChange: setInteractiveSave,
    setQuestionType,
    hideInteractionTime,
    endSession: completeInteractiveVideoSession,
    saveAnswer: saveInteractiveVideoAnswer,
    showMessage,
    setCurrentInteraction,
    getParticipants: getInteractiveVideoParticipants,
    fetchNFT,
    fetchParticipant,
    fetchIVProjectsList,
    checkNFTClaimStatus,
    eligibleCheck,
};

export default connect(stateToProps, actionToProps)(TextType);
