export const randomNoRepeats = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
};

export const uniqFunction = (array) => {
    return array.reduce(function (a, b) {
        if (a.indexOf(b) < 0) {
            a.push(b);
        }
        return a;
    }, []);
};
