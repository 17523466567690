import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signTx } from '../../../helper';
import ToAddressTextField from '../SendTokenDialog/ToAddressTextField';
import { showMessage } from '../../../actions/snackbar';
import { codes } from '../../../config/network';

const SendTokens = (props) => {
    const [inProgress, setInProgress] = useState(false);
    const [signature, setSignature] = useState('');

    const signMessage = () => {
        setInProgress(true);
        if (!props.address) {
            props.showMessage('No address found');
            setInProgress(false);
            return;
        }
        if (!props.toAddress) {
            props.showMessage('Input data required');
            setInProgress(false);
            return;
        }

        const networkCode = localStorage.getItem('akt_edu_nc');
        const network = codes[networkCode];
        if (network) {
            signWithCosmosAccount(network);
        } else {
            signWithEthereumAccount();
        }
    };

    const signWithEthereumAccount = () => {
        props.web3Instance && props.web3Instance.eth.personal.sign(
            props.toAddress,
            props.address,
        ).then((signature) => {
            setSignature(signature);
        }).catch((error) => props.showMessage(error));
    };

    const signWithCosmosAccount = (network) => {
        const tx = {
            msg: [{
                type: 'MsgSign',
                value: {
                    creator: props.address,
                    data: props.toAddress,
                },
            },
            ],
            fee: {
                amount: [],
                gas: '200000',
            },
            memo: props.toAddress,
        };

        signTx(tx, props.address, network, (error, result) => {
            setInProgress(false);
            if (error) {
                props.showMessage(error);
                return;
            }
            setSignature(result && result.signatures[0] && result.signatures[0].signature);
            props.showMessage('success');
        });
    };

    return (
        <div>
            <p className="title">Enter the data to sign</p>
            <form className="form">
                <ToAddressTextField label="input"/>
            </form>
            <Button
                className="send_button"
                disabled={inProgress}
                onClick={signMessage}>
                    Sign
            </Button>
            {signature ? <div>signature: {signature}</div> : null}
        </div>
    );
}
;

SendTokens.propTypes =
    {
        showMessage: PropTypes.func.isRequired,
        toAddress:
        PropTypes.string.isRequired,
        address: PropTypes.string,
        web3Instance: PropTypes.any,
    }
;

const stateToProps = (state) => {
    return {
        address: state.account.address,
        toAddress: state.account.toAddress,
        web3Instance: state.account.web3Instance,
    };
}
;

const actionToProps =
    {
        showMessage,
    }
;

export default connect(stateToProps, actionToProps)(SendTokens);
