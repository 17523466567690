import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog, Zoom } from '@mui/material';
import { hideQuizDialog } from '../../actions/interactiveVideo';
import Certificate from '../../assets/interactiveVideo/certificate.png';
import variables from '../../utils/variables';
import { ReactComponent as InteractIcon } from '../../assets/home/interact.svg';

const QuizDialog = (props) => {
    const handleClick = () => {
        props.handleOpen(props.quizDetails);
        props.handleClose();
    };

    return (
        <Dialog TransitionComponent={Zoom} className="quiz_dialog" open={props.open} onClose={props.handleClose}>
            <div className="quiz_info_section">
                <img alt="Certificate_image" src={Certificate}/>
                <p>{variables[props.lang].lets_take_quiz}</p>
                <div className="bottom_section">
                    <span>{props.quizDetails.asset && props.quizDetails.asset.module}</span>
                    <p>{props.quizDetails.asset && props.quizDetails.asset.name}</p>
                    <Button onClick={handleClick}>
                        <InteractIcon/>
                        {variables[props.lang].interact_and_earn}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

QuizDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    quizDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.interactiveVideo.quizDialog.open,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideQuizDialog,
};

export default connect(stateToProps, actionToProps)(QuizDialog);
