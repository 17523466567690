import React from 'react';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    fetchMintQueue,
    fetchMintRequest,
    mintNFT,
    setClaimStatus,
    setMyAccountTab,
    showClaimDialog,
} from '../../actions/myAccount';
import { showMessage } from '../../actions/snackbar';
import { showClaimCertificateDialog } from '../../actions/interactiveVideo';
import variables from '../../utils/variables';

const ClaimNFTButton = (props) => {
    // const handleClick = () => {
    //     // props.history.push('/account');
    //     // props.setMyAccountTab('claimable');
    // };
    const percentage = ((props.sessionComplete && props.sessionComplete.correct_answers) /
        (props.sessionComplete && props.sessionComplete.total_questions)) * 100;
    const requiredPercentage = props.video && props.video.akash_edu_config && props.video.akash_edu_config.percentage_required &&
        props.video.akash_edu_config.percentage_required * 100;

    let noData = true;
    if (props.list && props.list.length) {
        props.list.map((item, index) => {
            const denomID = item && item.denom && item.denom.id;
            const nftID = item && item.nfts && item.nfts.length && item.nfts;

            let nftDetails = null;
            if (nftID) {
                nftDetails = props.nfts && props.nfts[nftID];
            }

            if ((props.address && nftDetails &&
                    nftDetails.owner && (nftDetails.owner !== props.address)) ||
                (nftDetails && nftDetails.status && nftDetails.status === 'BURNED')) {
                return;
            }

            if (denomID && props.claimStatus && props.claimStatus[denomID] &&
                props.claimStatus[denomID].length && props.claimStatus[denomID]) {
                return;
            }

            if (item && item.interactive_video_id && props.participant &&
                props.participant[item.interactive_video_id] && !props.participant[item.interactive_video_id].length) {
                return;
            }

            noData = false;
        });
    }

    if (props.sessionComplete && props.sessionComplete._id && !noData) {
        props.player.pause();
        props.showClaimCertificateDialog(percentage, requiredPercentage);
    }

    return (
        noData
            ? null
            : percentage && requiredPercentage && (percentage > requiredPercentage || percentage === requiredPercentage) &&
            props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_claimable
                ? <Button className="claim_nft_button" onClick={() => props.showClaimCertificateDialog(percentage, requiredPercentage)}>
                    {variables[props.lang].claim_nft}
                </Button>
                : percentage && !requiredPercentage && props.video && props.video.akash_edu_config && props.video.akash_edu_config.is_claimable
                    ? <Button className="claim_nft_button" onClick={() => props.showClaimCertificateDialog(percentage, requiredPercentage)}>
                        {variables[props.lang].claim_nft}
                    </Button>
                    : null
    );
};

ClaimNFTButton.propTypes = {
    claimStatus: PropTypes.object.isRequired,
    eligibility: PropTypes.object.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchMintRequest: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    mintNFT: PropTypes.func.isRequired,
    nfts: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    sessionComplete: PropTypes.object.isRequired,
    setClaimStatus: PropTypes.func.isRequired,
    setMyAccountTab: PropTypes.func.isRequired,
    showClaimCertificateDialog: PropTypes.func.isRequired,
    showClaimDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    videoId: PropTypes.string.isRequired,
    address: PropTypes.string,
    player: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        sessionComplete: state.interactiveVideo.sessionComplete.value,
        inProgress: state.interactiveVideo.sessionComplete.inProgress,
        eligibility: state.myAccount.eligibility.value,
        list: state.myAccount.ivProjectsList.value,
        nfts: state.myAccount.nfts.value,
        claimStatus: state.myAccount.claimStatus.value,
        participant: state.participant.participant.value,
        player: state.interactiveVideo.player,
        lang: state.language,
    };
};

const actionToProps = {
    fetchMintQueue,
    fetchMintRequest,
    setMyAccountTab,
    mintNFT,
    showMessage,
    showClaimDialog,
    setClaimStatus,
    showClaimCertificateDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(ClaimNFTButton));
