import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { showConnectAccountDialog, showConnectDialog } from '../../actions/navbar';
import {
    hideTokenGatedContentDialog,
    setAccountAddress,
    showTokenGatedContentDialog,
} from '../../actions/interactiveVideo';
import { showMessage } from '../../actions/snackbar';
import { initializeChain, initializeCosmoStation, initializeMetaMaskCosmos } from '../../helper';
import { initializeMetaMask } from '../../actions/account/metaMask';
import CopyButton from '../../components/CopyButton';
import DisconnectButton from './DisconnectButton';
import omniflix from '../../assets/networks/omniflix.png';
import { connect, useDispatch } from 'react-redux';
import { setContractInstance, setPublicKey, setWeb3Instance } from '../../actions/account';
import { arrayToBase64 } from '../../utils/encrypt';
import { withRouter } from 'react-router-dom';
// import { videosList } from '../../utils/videosList';
import { fetchCollectionMyNFTs } from '../../actions/gatedAccess';
import { initializeChainLeap, walletConnect } from '../../actions/account/wallet';
import { setClearUserNFTs, setMyAccountTab } from '../../actions/myAccount';
import { getWrapAddress } from '../../utils/strings';
import variables from '../../utils/variables';
import { ReactComponent as DownArrow } from '../../assets/down_arrow.svg';

const ConnectButton = (props) => {
    const { address } = props;
    const dispatch = useDispatch();

    const handleRedirect = () => {
        props.history.push('/account');
        dispatch(setMyAccountTab('my_nfts'));
    };

    const handleTabOpen = () => {
        props.history.push('/account');
        dispatch(setMyAccountTab('claimable'));
    };

    useEffect(() => {
        const lockType = localStorage.getItem('akt_edu_connector');

        if (lockType === 'k') {
            setTimeout(() => {
                connectKeplr();
            }, 600);
        } else if (lockType === 'wc') {
            handleWalletConnect();
        } else if (lockType === 'cs') {
            handleCosmostation();
        } else if (lockType === 'leap') {
            initializeLeap();
        } else if (lockType === 'mml') {
            handleMetaMaskLeap();
        } else if (lockType === 'mmc') {
            handleMetaMaskCosmos();
        } else {
            const route = props.location && props.location.pathname && props.location.pathname.split('/');
            if (route && route.length > 2 && route[1] === 'videos') {
                props.showMessage('Connect Account');
            }
        }
    }, [address]);

    useEffect(() => {
        let cosmostationEvent = null;
        window.addEventListener('keplr_keystorechange', () => {
            if (localStorage.getItem('akt_edu_address') || props.address !== '') {
                props.setClearUserNFTs();
                props.history.push('/');
                localStorage.removeItem('akt_edu_address');
                connectKeplr();
            }
        });

        window.onload = () => {
            if (window.cosmostation && window.cosmostation.cosmos) {
                cosmostationEvent = window.cosmostation.cosmos.on('accountChanged', () => {
                    if (localStorage.getItem('akt_edu_address') || props.address !== '') {
                        props.history.push('/');
                        localStorage.removeItem('akt_edu_address');
                        handleCosmostation();
                    }
                });
            }
        };

        return () => {
            window.removeEventListener('keplr_keystorechange', connectKeplr);
            if (cosmostationEvent) {
                window && window.cosmostation && window.cosmostation.cosmos &&
                window.cosmostation.cosmos.off(cosmostationEvent);
            }
        };
    }, []);

    const handleCosmostation = () => {
        initializeCosmoStation((error, account) => {
            if (error) {
                localStorage.removeItem('akt_edu_address');
                props.showMessage(error);

                return;
            }

            if (account.address) {
                props.setAccountAddress(account.address);
                handleNFTs(account.address);
                localStorage.setItem('akt_edu_address', account.address);
            }
        });
    };

    const initializeLeap = () => {
        props.initializeChainLeap((address) => {
            if (!address) {
                window.onload = () => initializeLeap();
                return;
            }

            props.setAccountAddress(address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_address', address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_connector', 'leap');
        });
    };

    const handleWalletConnect = () => {
        props.walletConnect(null, (connector, account) => {
            if (account && account.length === 0) {
                connector.killSession();
                localStorage.removeItem('akt_edu_connector');

                return;
            }

            const address = account && account.length && account[0] && account[0].bech32Address;
            if (address) {
                props.setAccountAddress(address);
                handleNFTs(address);
                localStorage.setItem('akt_edu_address', address);
            }
        });
    };

    const connectKeplr = () => {
        // waiting till window loads, as we are checking keplr from window object.
        initializeChain((error, account) => {
            if (account === undefined || !account) {
                window.onload = () => connectKeplr();
                return;
            }

            if (error) {
                props.showMessage(error);
                localStorage.removeItem('akt_edu_connector');
                return;
            }

            if (account.pubkey) {
                props.setPublicKey(arrayToBase64(account.pubkey));
            }
            if (account.address) {
                props.setAccountAddress(account.address);
                handleNFTs(account.address);
                localStorage.setItem('akt_edu_address', account.address);
            }
        });
    };

    const handleMetaMaskLeap = () => {
        props.initializeMetaMask((address, retry) => {
            if (retry) {
                window.onload = () => handleMetaMaskLeap();
                return;
            }

            props.setAccountAddress(address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_address', address && address.length && address[0] && address[0].address);
            localStorage.setItem('akt_edu_connector', 'mml');
        });
    };

    const handleMetaMaskCosmos = () => {
        initializeMetaMaskCosmos((error, result) => {
            if (error) {
                window.onload = () => handleMetaMaskCosmos();
                return;
            }

            props.setAccountAddress(result && result.address && result.address.address);
            localStorage.setItem('akt_edu_address', (result && result.address && result.address.address));
            localStorage.setItem('akt_edu_connector', 'mmc');
        });
    };

    const handleNFTs = (address) => {
        // const route = props.location && props.location.pathname && props.location.pathname.split('/');
        // if (route && route.length > 2 && route[1] === 'videos') {
        //     const video = videosList && videosList.find((val) => val._id === route[2]);
        //
        //     props.showTokenGatedContentDialog();
        //     if (video && video.denom_id) {
        //         props.fetchCollectionMyNFTs(video.denom_id, address, (result) => {
        //             if (result && result.length) {
        //                 props.hideTokenGatedContentDialog();
        //             } else {
        //                 props.history.push('/');
        //             }
        //         });
        //     }
        // }
    };

    const handleShow = () => {
        if (props.address) {
            return;
        }

        props.showConnectDialog(false);
    };

    return (
        <>
            {props.address
                ? <div className="absolute_container">
                    <div className="connect_button_container">
                        <div className="connect_button">
                            <img alt="omniflix" className="network_image" src={omniflix}/>
                            <div className="hash_text" title={props.address}>
                                {getWrapAddress(props.address, 10)}
                            </div>
                            <CopyButton data={props.address}/>
                            <DownArrow/>
                        </div>
                        <div className="popover_section">
                            <Button
                                className="account_button"
                                onClick={handleRedirect}>{variables[props.lang].my_nfts}</Button>
                            <Button
                                className="account_button"
                                onClick={handleTabOpen}>{variables[props.lang].claimable}</Button>
                            <DisconnectButton/>
                        </div>
                    </div>
                </div>
                : <Button
                    className="connect_button_container connect_button not_connected"
                    variant="outlined"
                    onClick={handleShow}>
                    {variables[props.lang].connect_your_account}
                </Button>}
        </>
    );
};

ConnectButton.propTypes = {
    fetchCollectionMyNFTs: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    initializeChainLeap: PropTypes.func.isRequired,
    initializeMetaMask: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setClearUserNFTs: PropTypes.func.isRequired,
    setContractInstance: PropTypes.func.isRequired,
    setMyAccountTab: PropTypes.func.isRequired,
    setPublicKey: PropTypes.func.isRequired,
    setWeb3Instance: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    walletConnect: PropTypes.func.isRequired,
    address: PropTypes.string,
    contractInstance: PropTypes.any,
    web3Instance: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        web3Instance: state.account.web3Instance,
        contractInstance: state.account.contractInstance,
        lang: state.language,
    };
};

const actionToProps = {
    hideTokenGatedContentDialog,
    fetchCollectionMyNFTs,
    show: showConnectAccountDialog,
    showConnectDialog,
    showTokenGatedContentDialog,
    setAccountAddress,
    showMessage,
    setWeb3Instance,
    setContractInstance,
    setPublicKey,
    walletConnect,
    initializeChainLeap,
    setClearUserNFTs,
    setMyAccountTab,
    initializeMetaMask,
};

export default withRouter(connect(stateToProps, actionToProps)(ConnectButton));
