import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Dialog, DialogContent } from '@material-ui/core';
import { hideNetworkDialog } from '../../../actions/navbar';
import variables from '../../../utils/variables';
import omniflix from '../../../assets/networks/omniflix.png';
import akash from '../../../assets/networks/akash.png';
import sentinel from '../../../assets/networks/sentinel.png';
import cosmos from '../../../assets/networks/cosmos.png';
import { aminoSignTx, initializeChain } from '../../../helper';
import iris from '../../../assets/networks/iris.png';
import osmosis from '../../../assets/networks/osmosis.png';
import juno from '../../../assets/networks/juno.svg';
import { showMessage } from '../../../actions/snackbar';
import { addInteractiveVideoSession, setAccountAddress } from '../../../actions/interactiveVideo';
import { list } from '../../../config/network';
import { setPublicKey } from '../../../actions/account';
import { arrayToBase64 } from '../../../utils/encrypt';

const networksList = [{
    img: omniflix,
    name: 'OmniFlix',
    key: 'omniflix',
    code: 'flix',
}, {
    img: akash,
    name: 'Akash Network',
    key: 'akash',
    code: 'a',
}, {
    img: cosmos,
    name: 'Cosmos',
    key: 'cosmos',
    code: 'c',
}, {
    img: sentinel,
    name: 'Sentinel',
    key: 'sentinel',
    code: 's',
}, {
    img: iris,
    name: 'Iris',
    key: 'iris',
    code: 'i',
}, {
    img: osmosis,
    name: 'Osmosis',
    key: 'osmosis',
    code: 'o',
}, {
    img: juno,
    name: 'Juno',
    key: 'juno',
    code: 'j',
}];

const NetworkDialog = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initKeplr = (network) => {
        setInProgress(true);
        initializeChain(network.key, (error, account) => {
            setInProgress(false);
            if (error) {
                props.showMessage(error);
                return;
            }

            const address = account.address;

            localStorage.setItem('akt_edu_connector', 'k');
            localStorage.setItem('akt_edu_nc', network.code);
            if (account.pubkey) {
                props.setPublicKey(arrayToBase64(account.pubkey));
            }
            props.setAccountAddress(address);
            props.handleClose();

            if (props.videoId && props.sign) {
                if (window.keplr) {
                    window.keplr.defaultOptions = {
                        sign: {
                            preferNoSetFee: true,
                            preferNoSetMemo: true,
                        },
                    };
                }

                const tx = {
                    msg: {
                        type: 'omniflix/MsgSign',
                        value: {
                            address: address,
                        },
                    },
                    fee: {
                        amount: [{
                            denom: list[network.key].coinMinimalDenom,
                            amount: '0',
                        }],
                        gas: '1',
                    },
                    memo: props.videoId,
                };

                aminoSignTx(tx, address, (error, result) => {
                    if (window.keplr) {
                        window.keplr.defaultOptions = {};
                    }
                    if (error) {
                        props.showMessage(error);
                        return;
                    }

                    props.addInteractiveVideoSession(props.videoId, {
                        accountAddress: address,
                        deviceFingerprint: props.deviceId,
                        sign: result && result.signature,
                    }, (error) => {
                        if (error) {
                            // props.showMessage('Error while adding session');
                            return;
                        }

                        props.player.play();
                    });
                });
            }
        });
    };

    const handleClose = () => {
        if (props.videoId && props.sign) {
            return;
        }

        props.handleClose();
    };

    return (
        <Dialog
            aria-describedby="network-dialog-description"
            aria-labelledby="network-dialog-title"
            className="network_dialog"
            open={props.open}
            onClose={handleClose}>
            <DialogContent className="content">
                <p className="heading">
                    {variables[props.lang]['select_network']}
                </p>
                <div className="networks_list">
                    {
                        inProgress
                            ? <div>Connecting..</div>
                            : networksList && networksList.length &&
                            networksList.map((network, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="network"
                                        onClick={() => initKeplr(network)}>
                                        <div className="image">
                                            <img alt={network.name} src={network.img}/>
                                        </div>
                                        <p className="name">{network.name}</p>
                                    </div>
                                );
                            })}
                </div>
            </DialogContent>
        </Dialog>
    );
};

NetworkDialog.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setPublicKey: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.bool.isRequired,
    deviceId: PropTypes.string,
    player: PropTypes.object,
    videoId: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        deviceId: state.interactiveVideo.deviceID.id,
        lang: state.language,
        open: state.account.networkDialog,
        sign: state.account.connectDialog.sign,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    handleClose: hideNetworkDialog,
    showMessage,
    setAccountAddress,
    addInteractiveVideoSession,
    setPublicKey,
};

export default connect(stateToProps, actionToProps)(NetworkDialog);
