import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    completeInteractiveVideoSession,
    hideInteractionTime,
    saveInteractiveVideoAnswer,
    setCurrentInteraction,
    setInteractiveSave,
    setQuestionType,
} from '../../../actions/interactiveVideo';
import ClassName from 'classnames';
import { fetchParticipant, getInteractiveVideoParticipants } from '../../../actions/participant';
import { removeInteraction } from '../../../utils/interaction';
import { showMessage } from '../../../actions/snackbar';
import { checkNFTClaimStatus, eligibleCheck, fetchIVProjectsList, fetchNFT } from '../../../actions/myAccount';

const MCQType = (props) => {
    const handleChange = (value, id) => {
        const obj = {
            ...props.answers,
        };

        if (props.answers && props.answers[value._id]) {
            delete obj[id];
        } else {
            obj[id] = value;
        }

        if (disable && disable.length === 1) {
            const key = disable[0]._id;
            delete obj[key];
        }

        props.onChange(obj);
    };

    const endCurrentSession = () => {
        props.endSession(props.session && props.session.interactive_video,
            props.session && props.session._id, {
                accountAddress: props.address,
                // signature,
            }, (error) => {
                if (error) {
                    props.showMessage('Error while ending session');
                    return;
                }
                props.getParticipants(props.session && props.session.interactive_video);
                props.fetchIVProjectsList(props.address, props.session && props.session.interactive_video, (result) => {
                    if (result && result.length) {
                        result.map((value) => {
                            if (value && value.nfts && value.nfts.length) {
                                props.fetchNFT(value.nfts);
                                if (props.address && value && value.denom && value.denom.id) {
                                    props.checkNFTClaimStatus(value.denom.id, props.address);
                                }
                            }

                            props.fetchParticipant(value.interactive_video_id, props.address, (val) => {
                                if (val && val.length && val[0] && val[0]._id) {
                                    props.eligibleCheck(value.interactive_video_id, val[0]._id);
                                }
                            });

                            return null;
                        });
                    }
                });
            });
    };

    const handleSubmit = () => {
        if (!(props.session && props.session._id)) {
            props.showMessage('Session does not exists');
            return;
        }

        const questionId = props.value && props.value.question && props.value.question._id;
        if (questionId) {
            const selectedAnswers = Object.values(props.answers);

            if (!selectedAnswers[0]) {
                props.showMessage('Did not got the answer value');
                return;
            }

            if (selectedAnswers[0] && selectedAnswers[0].goto) {
                handleChecked(selectedAnswers[0].goto);
            }

            props.saveAnswer(props.session && props.session.interactive_video,
                props.session && props.session._id,
                {
                    questionId,
                    answers: selectedAnswers.map((answer) => answer.value),
                }, (error) => {
                    if (error) {
                        clearInteraction();
                        return;
                    }

                    clearInteraction();
                    if (props.currentInteraction >= props.interactions.length - 1) {
                        endCurrentSession();
                    }
                    props.player.play();
                });
        }
    };

    const clearInteraction = () => {
        if (removeInteraction(props.currentInteraction)) {
            props.setCurrentInteraction(props.currentInteraction + 1);
            props.hideInteractionTime();
        } else {
            props.showMessage('Failed to remove interaction');
        }
    };

    const handleChecked = (gotoValue) => {
        if (gotoValue) {
            props.player.currentTime((gotoValue) / 1000);
        }
    };

    const handleRedirect = (value) => {
        window.open(value, '_blank');
    };

    const disable = props.value && props.value.options &&
        props.value.options.length && props.value.options.filter((value) => props.answers[value._id]);

    return (
        <div className={props.value && props.value.options && props.value.options.length < 2 ? 'options_section single_option' : 'options_section'}>
            {props.value && props.value.options &&
                props.value.options.length &&
                props.value.options.map((option) => {
                    return (option.type === 'IMAGE'
                        ? <img
                            alt="image_option" height="100px"
                            src={option.value} width="100px"/>
                        : option.type === 'URL'
                            ? <Button
                                key={option._id}
                                className="options_button"
                                onClick={() => handleRedirect(option.value)}>
                                    click
                            </Button>
                            : <Button
                                key={option._id}
                                className={ClassName('options_button',
                                    props.answers && props.answers[option._id]
                                        ? 'active_option' : '')}
                                onClick={() => handleChange(option, option._id)}>
                                {option.value}
                            </Button>
                    );
                })}
            <div className="submit_div">
                <Button
                    className="submit_button"
                    disabled={disable.length === 0}
                    onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

MCQType.propTypes = {
    checkNFTClaimStatus: PropTypes.func.isRequired,
    currentInteraction: PropTypes.number.isRequired,
    eligibleCheck: PropTypes.func.isRequired,
    endSession: PropTypes.func.isRequired,
    fetchIVProjectsList: PropTypes.func.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    fetchParticipant: PropTypes.func.isRequired,
    getParticipants: PropTypes.func.isRequired,
    hideInteractionTime: PropTypes.func.isRequired,
    saveAnswer: PropTypes.func.isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    address: PropTypes.string,
    answers: PropTypes.object,
    index: PropTypes.number,
    interactions: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.number.isRequired,
            time_limit: PropTypes.number.isRequired,
            answers: PropTypes.array,
        }),
    ),
    player: PropTypes.object,
    session: PropTypes.shape({
        _id: PropTypes.string,
        interactive_video: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        answers: state.interactiveVideo.answers,
        session: state.interactiveVideo.session.data,
        currentInteraction: state.interactiveVideo.currentInteraction,
        address: state.account.address,
        interactions: state.interactiveVideo._.interactions,
    };
};

const actionToProps = {
    onChange: setInteractiveSave,
    setQuestionType,
    saveAnswer: saveInteractiveVideoAnswer,
    endSession: completeInteractiveVideoSession,
    getParticipants: getInteractiveVideoParticipants,
    setCurrentInteraction,
    showMessage,
    hideInteractionTime,
    fetchNFT,
    fetchParticipant,
    fetchIVProjectsList,
    checkNFTClaimStatus,
    eligibleCheck,
};

export default connect(stateToProps, actionToProps)(MCQType);
