import {
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS, CONNECT_KEPLR_ACCOUNT_SUCCESS,
    TX_SIGN_ERROR,
    TX_SIGN_IN_PROGRESS,
    TX_SIGN_SUCCESS,
    WALLET_CONNECT_ERROR,
    WALLET_CONNECT_IN_PROGRESS,
    WALLET_CONNECT_SUCCESS,
} from '../../constants/wallet';
import { makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino';
import Axios from 'axios';
import WalletConnect from '@walletconnect/client';
import { CosmostationWCModal } from '../../components/QRModal';
import { payloadId } from '@walletconnect/utils';
import { setDisconnect } from './index';
import { urlFetchAccount } from '../../constants/url';
import { chainConfig, chainId, config, walletExtensions } from '../../config';
import { KEPLR_ACCOUNT_KEYS_SET } from '../../constants/account';
import { SigningStargateClient } from '@cosmjs/stargate';
import { CosmjsOfflineSigner } from '@leapwallet/cosmos-snap-provider';

const walletConnectInProgress = () => {
    return {
        type: WALLET_CONNECT_IN_PROGRESS,
    };
};

const walletConnectSuccess = (value, account) => {
    return {
        type: WALLET_CONNECT_SUCCESS,
        value,
        account,
    };
};

const walletConnectError = (message) => {
    return {
        type: WALLET_CONNECT_ERROR,
        message,
        variant: 'error',
    };
};

export const walletConnect = (address, cb) => (dispatch) => {
    dispatch(walletConnectInProgress());
    (async () => {
        const connector = new WalletConnect({
            bridge: 'https://bridge.walletconnect.org',
            signingMethods: [
                'cosmostation_wc_accounts_v1',
                'cosmostation_wc_sign_tx_v1',
            ],
            qrcodeModal: new CosmostationWCModal(),
        });

        if (!connector.connected) {
            localStorage.removeItem('akt_edu_wallet_connect');
            localStorage.removeItem('akt_edu_address');
        }

        if (connector.connected && address) {
            await connector.killSession();
            localStorage.removeItem('akt_edu_wallet_connect');
            localStorage.removeItem('akt_edu_address');
        }

        if (connector.connected) {
            const account = (localStorage.getItem('akt_edu_wallet_connect') &&
                JSON.parse(localStorage.getItem('akt_edu_wallet_connect'))) || [];
            dispatch(walletConnectSuccess(connector, account));
            cb(connector, account);

            return;
        }

        await connector.createSession();

        connector.on('disconnect', (error, payload) => {
            if (error) {
                dispatch(walletConnectError(error));
                cb(null);

                return;
            }

            connector.killSession();
            setDisconnect();
            localStorage.removeItem('akt_edu_wallet_connect');
            localStorage.removeItem('akt_edu_address');
        });
        connector.on('connect', (error, payload) => {
            if (error) {
                dispatch(walletConnectError(error));
                cb(null);

                return;
            }

            dispatch(walletConnectAccount(connector, cb));
        });
    })();
};

const walletConnectAccount = (connector, cb) => (dispatch) => {
    connector.sendCustomRequest({
        id: payloadId(),
        jsonrpc: '2.0',
        method: 'cosmostation_wc_accounts_v1',
        params: [config.CHAIN_ID],
    }).then((accounts) => {
        dispatch(walletConnectSuccess(connector, accounts));
        cb(connector, accounts);
    }).catch((error) => {
        dispatch(walletConnectError(error.message));
        cb(null);
    });
};

const signTxInProgress = () => {
    return {
        type: TX_SIGN_IN_PROGRESS,
    };
};

const signTxSuccess = (value) => {
    return {
        type: TX_SIGN_SUCCESS,
        value,
    };
};

const signTxError = (message) => {
    return {
        type: TX_SIGN_ERROR,
        message,
        variant: 'error',
    };
};

export const walletConnectSign = (connector, tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());

    const url = urlFetchAccount(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((response) => {
            const accountNumber = response && response.data && response.data.account && response.data.account.account_number;
            const sequence = response && response.data && response.data.account && response.data.account.sequence;
            dispatch(handleWalletConnectSign(accountNumber, sequence, connector, tx, address, cb));
        })
        .catch(() => {
            const accountNumber = 0;
            const sequence = 0;
            dispatch(handleWalletConnectSign(accountNumber, sequence, connector, tx, address, cb));
        });
};

const handleWalletConnectSign = (accountNumber, sequence, connector, tx, address, cb) => (dispatch) => {
    const signDoc = AminoMakeSignDoc(
        tx.msgs ? tx.msgs : [tx.msg],
        tx.fee,
        config.CHAIN_ID,
        tx.memo,
        accountNumber,
        sequence,
    );

    const request = {
        id: payloadId(),
        jsonrpc: '2.0',
        method: 'cosmostation_wc_sign_tx_v1',
        params: [config.CHAIN_ID, address, signDoc],
    };

    connector.sendCustomRequest(request)
        .then((result) => {
            const res = result && result.length && result[0];
            if (res && res.code !== undefined && res.code !== 0) {
                dispatch(signTxError(res.log || res.rawLog));
            } else {
                dispatch(signTxSuccess(res));
                cb(res);
            }
        }).catch((error) => {
            dispatch(signTxError(error && error.message));
            cb(null);
        });
};

const connectKeplrAccountInProgress = () => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    };
};

const connectKeplrAccountSuccess = (value) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_SUCCESS,
        value,
    };
};

const connectKeplrAccountError = (message) => {
    return {
        type: CONNECT_KEPLR_ACCOUNT_ERROR,
        message,
    };
};

export const setKeplrAccountKeys = (value) => {
    return {
        type: KEPLR_ACCOUNT_KEYS_SET,
        value,
    };
};

export const initializeChain = (cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.keplr) {
            const error = 'Please install keplr extension';
            dispatch(connectKeplrAccountError(error));
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectKeplrAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                dispatch(connectKeplrAccountError(versionError));
            }
        }

        if (window.keplr) {
            window.keplr.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectKeplrAccountSuccess(accounts));
                    cb(accounts);
                }).catch((error) => {
                    dispatch(connectKeplrAccountError(error.toString()));
                });
            window.keplr && window.keplr.getKey(chainId)
                .then((res) => {
                    dispatch(setKeplrAccountKeys(res));
                }).catch(() => {

                });
        } else {
            return null;
        }
    })();
};

export const initializeChainLeap = (cb) => (dispatch) => {
    dispatch(connectKeplrAccountInProgress());
    (async () => {
        if (!window.leap.getOfflineSignerOnlyAmino || !window.leap) {
            const error = 'Please install leap extension';
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.open('https://leapcosmoswallet.page.link/fiLDQBCHaF7wYijx8');
            } else {
                window.open(walletExtensions.LEAP);
            }
            dispatch(connectKeplrAccountError(error));
        } else {
            if (window.leap.experimentalSuggestChain) {
                try {
                    await window.leap.experimentalSuggestChain(chainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectKeplrAccountError(chainError));
                }
            } else {
                const versionError = 'Please use the recent version of leap extension';
                dispatch(connectKeplrAccountError(versionError));
            }
        }

        if (window.leap) {
            window.leap.enable(chainId)
                .then(async () => {
                    const offlineSigner = window.leap.getOfflineSignerOnlyAmino(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectKeplrAccountSuccess(accounts));
                    cb(accounts);
                }).catch((error) => {
                    dispatch(connectKeplrAccountError(error.toString()));
                });
            window.leap && window.leap.getKey(chainId)
                .then((res) => {
                    dispatch(setKeplrAccountKeys(res));
                }).catch(() => {

                });
        } else {
            return null;
        }
    })();
};

export const aminoSignTxMetaMask = (tx, address, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        await window.ethereum && window.ethereum.enable(config.CHAIN_ID);
        const offlineSigner = new CosmjsOfflineSigner(config.CHAIN_ID);

        try {
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                offlineSigner,
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            offlineSigner.signAmino(address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
        }
    })();
};
