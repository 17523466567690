import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './index.css';
import DotsLoading from '../DotsLoading';
import { ReactComponent as Icon } from '../../assets/interactiveVideo/left_arrow.svg';
import { ReactComponent as InteractIcon } from '../../assets/interactiveVideo/interactIcon.svg';

const BackButton = (props) => {
    const handleBack = () => {
        props.history.goBack();
    };

    return (
        <div className={props.isAdmin ? 'back_button no_pill' : 'back_button'} onClick={handleBack}>
            <Icon className="back-arrow" icon="back-arrow"/>
            {props.interactive
                ? <InteractIcon/>
                : null}
            {props.inProgress
                ? <DotsLoading/>
                : <p className="title">{props.value}</p>}
        </div>
    );
};

BackButton.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool,
    interactive: PropTypes.bool,
    isAdmin: PropTypes.bool,
    value: PropTypes.string,
};

export default withRouter(BackButton);
