import React from 'react';
import * as PropTypes from 'prop-types';
import ClassNames from 'classnames';
// import thumbnail from '../../assets/thumbnail.webp';
import { mediaReference } from '../../utils/ipfs';

const VideoOnLoad = (props) => {
    const [poster, setPoster] = React.useState(null);
    const [videoPlayed, setVideoPlayed] = React.useState(false);

    React.useEffect(() => {
        setPoster(null);
        if (props.preview) {
            const url = mediaReference(props.preview);
            const image = new Image();
            image.onload = () => {
                setPoster(url);
            };
            image.onerror = () => {
                setPoster(props.preview);
            };
            image.src = url;
        }
    }, [props.preview]);

    const togglePlay = () => {
        if (!videoPlayed) {
            const video = document.getElementById('video-background');
            video.play();
            setVideoPlayed(true);
        }
    };

    if (poster) {
        return (
            <video
                playsInline className={ClassNames('inline_video', props.className)}
                controls={videoPlayed || props.videoPlayed ? 'controls' : null}
                id="video-background" poster={poster}
                preload="none" onClick={togglePlay}>
                <source src={props.src} type={props.type}/>
            </video>
        );
    }

    return (
        <video
            playsInline className={ClassNames('inline_video', props.className)}
            controls={videoPlayed || props.videoPlayed ? 'controls' : null}
            id="video-background" preload="none"
            onClick={togglePlay}>
            <source src={props.src} type={props.type}/>
        </video>
    );
};

VideoOnLoad.propTypes = {
    className: PropTypes.string,
    preview: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.string,
    videoPlayed: PropTypes.string,
};

export default VideoOnLoad;
