import {
    COLLECTION_MY_NFT_S_FETCH_ERROR,
    COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS,
    COLLECTION_MY_NFT_S_FETCH_SUCCESS,
} from '../constants/gatedAccess';
import { urlCollectionMyNfts } from '../constants/url';
import Axios from 'axios';

const fetchCollectionMyNFTsInProgress = () => {
    return {
        type: COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS,
    };
};

const fetchCollectionMyNFTsSuccess = (value) => {
    return {
        type: COLLECTION_MY_NFT_S_FETCH_SUCCESS,
        value,
    };
};

const fetchCollectionMyNFTsError = (message) => {
    return {
        type: COLLECTION_MY_NFT_S_FETCH_ERROR,
        message,
    };
};

export const fetchCollectionMyNFTs = (id, owner, cb) => (dispatch) => {
    dispatch(fetchCollectionMyNFTsInProgress());

    const url = urlCollectionMyNfts(id, owner);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            if (res && res.data && res.data.result && res.data.result) {
                dispatch(fetchCollectionMyNFTsSuccess(res.data && res.data.result.list));
                if (cb) {
                    cb(res.data && res.data.result.list);
                }
            }
        })
        .catch((error) => {
            dispatch(fetchCollectionMyNFTsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
