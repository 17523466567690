const variables = {
    en: {
        leaderboard: 'Leaderboard',
        participants: 'Participants',
        leaderboard_note: 'All participants of this campaign will be listed below',
        profile_name: 'Account',
        current_points: 'Interactions',
        ranking: 'Rank',
        home: 'Home',
        my_assets: 'My Assets',
        account: 'Account',
        disconnect: 'Disconnect',
        select_network: 'Select network to connect',

        // Header
        head_content1: 'Start your web3 career with ',
        head_content2: 'and earn ',
        head_content3: ' upon completion',
        nfts: 'NFTs',
        share: 'Share',
        or_copy_link: 'or copy link',

        // Footer
        akash_edu: 'Akash EDU',
        content1_p1: 'Dive into the world of decentralized cloud computing with ',
        content1_p2: ' your gateway to mastering the Akash Network ecosystem, designed for creators and innovators at every level.',

        // content1_p1: ' is an initiative designed to help developers, researchers, and other professionals learn about the ',
        // akash_network: 'Akash Network',
        // content1_p2: ' and its ecosystem.',
        content2_p1: 'By participating in Akash EDU, you can join a community of builders working to deploy on the Akash Network.',

        content3_p1: 'This initiative also utilizes NFTs on ',
        omniflix_network: 'OmniFlix Network',
        content3_p2: ' to recognize, document & immortalize the learning journey of builders.',

        developers: 'Developers',
        website: 'Website',
        docs: 'Docs',
        github: 'GitHub',
        whitepaper: 'Whitepaper',
        connect: 'Connect',

        socials: 'Socials',
        twitter: 'Twitter',
        discord: 'Discord',
        reddit: 'Reddit',
        youtube: 'YouTube',
        telegram: 'Telegram',
        linkedIn: 'LinkedIn',

        powered_by: 'powered by',
        deployed_on: 'deployed on',
        in_association_with: 'in association with',

        // Dialog
        imp_info: 'Important',
        complete: 'Complete the ',
        // dialog_section1: 'This interactive video is the quiz for the course ',
        dialog_section3: ' course before attempting this quiz. ',
        dialog_section4: 'You can only attempt the quiz once. ',
        dialog_section5: 'Don\'t refresh or close the browser.',
        dialog_section6: 'Yes, I’ve taken the course and would like to continue with the quiz.',
        go_to_course: 'Go to Course',
        take_quiz: 'Take Quiz',

        token_gated_content: 'Token Gated Content',
        you_dont_have_access: 'You do not have NFT to access this content',
        close_window: 'Close Window',
        verifying_nft: 'Verifying NFT',
        my_nfts: 'My NFTs',
        access_denied: 'Access denied',
        no_course_nft_found: 'No Course access NFT Found!',
        course_access_nft: 'Course Access NFT',
        get_the: 'Get the ',
        interacting_below_video: ' by interacting with below video',

        connect_account: 'Connect your account with any wallet',
        connect_your_wallet: 'Connect Your Wallet',
        connect_wallet_content: 'If you don\'t have a wallet. Create a wallet now by selecting a provider',
        connect_keplr: 'Connect with Keplr',
        download_keplr: 'Download Keplr',
        connect_cosmo: 'Connect with Cosmostation',
        download_cosmo: 'Download Cosmostation',
        connect_leap: 'Connect with Leap',
        download_leap: 'Download Leap',
        wallet_connect: 'Wallet Connect',
        connecting: 'Connecting',
        leap_wallet: 'Leap Wallet',
        keplr: 'Keplr',
        leap: 'Leap',
        cosmostation: 'Cosmostation',
        metamask: 'Metamask',

        certification: 'Certification',
        my_account: 'My Account',
        claimable: 'Claimable NFTs',
        claim: 'CLAIM',
        claim_now: 'Claim Now',
        course_completion_name: 'Would you like your Course Completion Certificate NFT to include your name?',
        yes: 'Yes',
        no: 'No',
        include_your_name: 'Would you like your certificate to include your name?',
        enter_your_name: 'Enter Your Name (case sensitive)',
        type_here: 'Type here',
        characters: 'characters',
        confirm: 'Confirm',
        successfully_claimed: 'You have successfully claimed your NFT',
        claiming_unsuccessful: 'NFT Claim unsuccessful',
        view: 'View',
        okay: 'Okay',
        processing: 'Processing',
        enroll_today: 'Enroll Today!',

        about_section1: 'Akash EDU is a free educational program designed to provide basic to advanced knowledge of the Akash Network Ecosystem.',
        about_section2: 'Its focus is to provide a comprehensive understanding of decentralized cloud computing and give you the tools needed to get involved in the world’s first Supercloud.',

        // Courses Page
        connect_your_account: 'Connect Your Account',
        courses: 'Courses',
        courses_sub_text: 'Akash EDU has been crafted to provide a personalized learning experience, allowing each student to proceed at their own pace. Our modular structure serves as the cornerstone, ensuring adaptability and enabling you to delve into topics on your own schedule. With new courses being added regularly, come back as often as you like. Happy learning!',
        explore_course: 'Explore Course',

        // Registration Section
        registration_h1: 'Unlock the Akash Network with Akash EDU',
        register_now: 'Register Now',
        watch_course: 'Watch course',
        view_courses: 'View Courses',
        learn_now: 'Learn Now',
        course_access_success: 'You\'ve successfully registered & claimed the Course Access NFT',
        registration_p1: ', a free, comprehensive guide to the forefront of decentralized cloud computing.',

        // About AkashEdu Section
        about_akash_edu: 'Building Blocks to Mastery: The Akash EDU Learning Path',
        akash_h1: 'Learning Block by Block',
        akash_p1: 'Starting small, we introduce courses one at a time, each delving into specific aspects of our ecosystem partners such as Praetor, Cloudmos, and Terraform. This approach ensures that you gain a thorough understanding of key components.',
        akash_h2: 'Building Skills',
        akash_p2: 'As part of our long-term vision, Akash EDU plans to expand its course offerings over time. The goal is to enable students to progress gradually, eventually becoming full-fledged decentralized cloud professionals. Our program aims to equip learners for success in the evolving landscape of decentralized cloud computing.',
        akash_h3: 'Earn Your Badge',
        akash_p3: 'Upon course completion, celebrate your success by claiming digital certificate NFT powered by OmniFlix Network, symbolizing your expertise and immortalizing your educational journey in the decentralized cloud computing domain.',

        // Certification or Enroll Today section
        enroll_p1: 'Interact and register for the course',
        enroll_p2: 'Get course access NFT',
        enroll_p3: 'Verify with course access NFT',
        enroll_p4: 'Interact with course and earn certificate',

        // Single Courses
        course_description: 'Course Description',
        read_less: 'Read Less',
        read_more: 'Read More',
        course_instructors: 'Course instructors',
        interactive_video: 'Interactive video',
        explainer_video: 'Explainer Video',
        quiz: 'Quiz',

        // Video Page and InteractiveVideo Page
        claim_certificate: 'Claim Certificate',
        lets_take_quiz: 'Take the quiz and Earn a Course Certificate NFT ',
        text_your_knowledge: 'Test your knowledge and secure your official Akash EDU certificate today.',
        interact_and_earn: 'Interact and earn certificate',

        // Claim Dialogs
        getting_certificate_ready: 'Getting your certificate ready',
        you_have_scored: 'You have scored',
        retry_quiz: 'Retry Quiz',
        nft_claimed_successfully: 'NFT Claimed Successfully!',
        share_it: 'Share it on',
        explore_more_courses: 'Explore more courses',
        claim_nft: 'Claim NFT',
        explore_courses: 'Explore courses',
        minting: 'Minting...',

        // mobile view navbar
        address: 'Address',

        // Page not Found
        page_not_found: 'Page not found!',
        go_back: 'Go back',
        take_me_home: 'Take me home',

        quiz_video: 'Quiz Video',
    },
};

export default variables;
